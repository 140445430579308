import { Observable } from 'rxjs';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';
import { PermissionForUsers } from './checkRoles';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/router";
import * as i3 from "../services/user.service";
export class CanActivateRouteGuard {
    constructor(cookieService, router, userService) {
        this.cookieService = cookieService;
        this.router = router;
        this.userService = userService;
        this.LocaleUrlMapping = {
            IN: {
                "MENU_TICKETS": "/ticket/view",
                "MENU_CREATE_TICKETS": "/ticket/heirarchy",
                "MENU_CONFIGURE_TICKETS": "/dynamic/form/create",
                "MENU_DASHBOARD": "/dashboard",
                "MENU_CHARGER": "/charger",
                "MENU_KEY_HANDOVER": "/keyhandover",
                "MENU_VEHICLES_HUB": "/vehicles",
                "MENU_DRIVERS": "/drivers/ALL_DRIVERS",
                "MENU_CASHBOOK": "/cash/ALL_TRANSACTIONS",
                "MENU_REPORT_HUB": "/reports/CASH_REPORT",
                "MENU_ATTENDANCE": "/attendance",
                "MENU_LIVE_ATTENDANCE": "/current_attendance",
                "MENU_SETTLEMENT": "/settlement/SETTLEMENT",
                "MENU_LIVE_MAP_HUB": "/live-map",
                "MENU_LEASE": "/lease",
                "MENU_NOTIFICATIONS": "/notification",
                "MENU_DRIVER_ISSUES": "/driver/issues/view",
                "MENU_ISSUES_TAT": "/issues/tat",
            },
            AE: {
                "MENU_TICKETS": "/ticket/view",
                "MENU_CREATE_TICKETS": "/ticket/heirarchy",
                "MENU_CONFIGURE_TICKETS": "/dynamic/form/create",
            }
        };
    }
    canActivate(next, state) {
        // this will be passed from the route config
        // on the data property
        //const expectedRole = route.data;
        const country = localStorage.getItem('country');
        const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
        if (!Headers.headers)
            Headers.addHeaders({ key: 'Authorization', value: 'Bearer ' + accessToken });
        const ssoid = localStorage.getItem('ssoId') || this.cookieService.get('ssoId');
        let userMenuRoles = {};
        let rolesURL = this.LocaleUrlMapping[country];
        //this.userService.getUserRole(ssoid)
        let lastRole;
        localStorage.setItem('ssoId', ssoid);
        if (ssoid) {
            this.userService.getUserRole(ssoid).subscribe((res) => {
                localStorage.setItem("userRoles", JSON.stringify(res.response));
                res.response.forEach(role => {
                    if (JSON.stringify(role).indexOf("MENU") !== -1) {
                        userMenuRoles[rolesURL[role]] = role;
                        lastRole = rolesURL[role];
                    }
                });
                if (!userMenuRoles[next['_routerState'].url] && !this.hasDetailsPageAccess(next['_routerState'].url, userMenuRoles)) {
                    this.router.navigateByUrl(lastRole);
                    return false;
                }
            });
        }
        return true;
    }
    /**
     *
     * @param url -> current page url
     * @param permissions -> permission object with url as key
     * @returns checks if user has ticket's page access, if yes it automatically provides
     * details page access. Since we have moved details page to a different domain
     * its crucial we check the access dynamically because of dynamic url params.
     */
    hasDetailsPageAccess(url, permissions) {
        const hasDriverAccess = PermissionForUsers.checkPermission('MENU_DRIVERS');
        const hasRiderAccess = Boolean(permissions['/rider']);
        const hasOpsAccess = Boolean(permissions['/ticket/view']);
        const result = (hasDriverAccess || hasRiderAccess || hasOpsAccess) && url.startsWith('/ticket/view');
        return result;
    }
}
CanActivateRouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanActivateRouteGuard_Factory() { return new CanActivateRouteGuard(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.UserService)); }, token: CanActivateRouteGuard, providedIn: "root" });
