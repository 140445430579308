import { HttpParams } from "@angular/common/http";
import { Headers } from "../models/api.headers";
import { Urls } from "../models/api.constants";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class IncidentHeirarchyService {
    constructor(_http, cookieService) {
        this._http = _http;
        this.cookieService = cookieService;
    }
    getHeirarchyRootForm(project, name) {
        return this._http.post(Urls.APP_URL + "/api/v1/form/get-active-form", { name, project }, { headers: Headers.headers });
    }
    getFormById(formId) {
        const url = "/api/v1/form/get-active-form-by-id?formId=";
        return this._http.get(Urls.APP_URL + url + formId, {
            headers: Headers.headers,
        });
    }
    submitTheForm(formData) {
        return this._http.post(Urls.APP_URL + "/api/v1/form/get-active-form", { name, project: "tms" }, { headers: Headers.headers });
        // return this._http.get(Urls.APP_URL + `/api/v1/heirarchy/fetch-form?formId=${formData}`);
    }
    getPaginatedDrivers(searchKey) {
        return this._http.get(Urls.HUB_URL +
            "/api/v1/drivers/from-all-hubs?page=0&size=100&searchTerm=" +
            searchKey, { headers: Headers.headers });
    }
    getIssues() {
        return this._http.get(Urls.HUB_URL + "/api/v1/issues", { headers: Headers.headers });
    }
    getSubIssusesList(zoneId, issueId) {
        const params = new HttpParams()
            .set("zoneId", zoneId.toString())
            .set("issueId", issueId.toString());
        return this._http.get(Urls.APP_URL + "/api/v1/sub-issue-by-id", {
            headers: Headers.headers,
            params,
        });
    }
}
IncidentHeirarchyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IncidentHeirarchyService_Factory() { return new IncidentHeirarchyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: IncidentHeirarchyService, providedIn: "root" });
