import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketListStateService {

  private ticketBehavior: BehaviorSubject<any> = new BehaviorSubject(null);
  ticketListState: Observable<any> = this.ticketBehavior.asObservable();

  constructor() { }

  set(data: any) {
    this.ticketBehavior.next(data);
  }
}
