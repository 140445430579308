import { Urls } from "../models/api.constants";
import { Headers } from "../models/api.headers";
import { GetWorkshopAndCranesByZoneResponseType, } from "../utils/types/sendByCrane.types";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class SendByCraneService {
    constructor(http, cookieService) {
        this.http = http;
        this.cookieService = cookieService;
        this.ApiV1Header = Urls.APP_URL + "/api/v1";
    }
    updateStage(ticket, additionalStageData = { stageActionData: [] }) {
        const username = localStorage.getItem("username");
        const ssoId = this.cookieService.get("ssoId");
        ticket.updatedBy = Number(ssoId);
        ticket.updatedByName = username;
        ticket.updatedAt = new Date();
        ticket.stageData = ticket.stageData.map((stage, index) => {
            if (index < ticket.stageData.length - 1)
                return stage;
            stage = Object.assign({}, stage, { actionTakenBy: Number(ssoId), actionTakenByName: username }, additionalStageData);
            return stage;
        });
        return ticket;
    }
    addStage(ticket, stageName, additionalStageData = { stageActionData: [] }) {
        const username = localStorage.getItem("username");
        const ssoId = this.cookieService.get("ssoId");
        ticket.actionType = 'STAGE_CHANGE';
        ticket.updatedBy = Number(ssoId);
        ticket.updatedByName = username;
        ticket.updatedAt = new Date();
        ticket.currentStage = stageName;
        const stage = Object.assign({ actionTakenBy: Number(ssoId), actionTakenByName: username, createdTime: new Date(), stageName: stageName }, additionalStageData);
        ticket.stageData.push(stage);
        return ticket;
    }
    popStage(ticket) {
        const username = localStorage.getItem("username");
        const ssoId = this.cookieService.get("ssoId");
        ticket.updatedBy = Number(ssoId);
        ticket.updatedByName = username;
        ticket.updatedAt = new Date();
        ticket.stageData.pop();
        const lastStage = ticket.stageData[ticket.stageData.length - 1];
        ticket.currentStage = lastStage.stageName;
        return ticket;
    }
    getWorkshopAndCranesByZone(zoneId) {
        return this.http.get(this.ApiV1Header + `/workshop-crane/${zoneId}`, { headers: Headers.headers });
    }
}
SendByCraneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendByCraneService_Factory() { return new SendByCraneService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: SendByCraneService, providedIn: "root" });
