import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Headers } from '../models/api.headers';
import { Urls } from '../models/api.constants';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  constructor(private http: HttpClient,private cookieService: CookieService) { }

  saveDynamicForm(finalizedForm): Observable<{}> {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/form/create-form', finalizedForm);
  }
  fetchCurrentlyActiveForm(project, category, name, isRoot?: boolean): Observable<any> {
    const query: any = {project, category, name};
    if (typeof isRoot === 'boolean') {
      query.isRoot = isRoot;
    }
    return this.http.post(Urls.APP_URL + '/api/v1/form/get-active-form', query);
  }
  updateDynamicForm(finalizedForm): Observable<{}> {
    return this.http.put<{}>(Urls.APP_URL + '/api/v1/form/update-replace', finalizedForm);
  }
  getFormById(formId) {
    const body = {};
    return this.http.get(Urls.APP_URL + '/api/v1/form/get-active-form-by-id?formId=' + formId,body);
  }

  fetchAllActiveFormsFromProject(project) {
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/form/list-form', { project, isActive: true });
  }

  fetchAllActiveFormsFromProjectAndCategory(projectName, category) { 
    let body = {
      'project': projectName,
      'category': category
    };
    return this.http.post<{}>(Urls.APP_URL + '/api/v1/form/list-form', body);
  }
  deactivateCurrentForm(formId) {
    return this.http.delete(Urls.APP_URL + '/api/v1/form/delete-form?formId=' + formId);
  }
}
