import { DynamicFormService } from "src/app/services/dynamic-form.service";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { TicketViewService } from "src/app/services/ticket-view.service";
import { Response } from "src/app/models/api.dto";
import { MenuItem, MessageService } from "primeng/api";
import { StageViewService } from "src/app/services/stage-view.service";
import { CookieService } from "ngx-cookie-service";
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionForUsers } from "../../../guards/checkRoles";
import { LocalService } from "src/app/services/local.service";
import { SendByCraneService } from "src/app/services/send-by-crane.service";
import { IncidentHeirarchyService } from "src/app/services/incident-heirarchy.service";
import { ApiResponse } from "src/app/utils/types/global";
import { HubService } from "src/app/services/hub.service";

@Component({
  selector: "app-detail-ticket-view",
  templateUrl: "./detail-ticket-view.component.html",
  styleUrls: ["./detail-ticket-view.component.css"],
})
export class DetailTicketViewComponent implements OnInit {
  @Input() ticketId;
  @Input() formsList;
  @Output() backToTicket: EventEmitter<any> = new EventEmitter<any>();
  displayUpdateResources = false;
  resources = [];
  imageUrl: any;
  showOdometer = false;
  viewImageFlag: boolean = false;
  reviewType: any;
  showTooltip: boolean = false;
  PMSStages = [
    "Driver Assigned",
    "Awaiting Job Card Approval",
    "Review Service Challan",
    "Vehicle at workshop",
    "Vehicle Received",
    "Received Car from workshop",
    "Ticket Closed",
  ];

  maintenanceDp: any;
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  actionType: string;
  restoreStateOfUpdateResources() {
    this.displayUpdateResources = false;
  }

  allowedToEdit = false;
  ticket;
  segregatedCategory: MenuItem[];
  home: MenuItem;
  stageData;
  currentStageData;
  selectedStageData;
  selectedReason;
  displayModal;
  reviewDialog = false;
  editCategoryView = false;
  reasonListData = [];

  amount;
  odometerReading;
  insurance;
  remark;
  resolutionNotes;
  discardReason;
  refund;
  selectedType;
  selectedCategory;
  categoriesList = [];
  typesListMap = {};
  typeList = [];
  serviceCenterList = [];
  selectedServiceCenter;
  selectedMaintenanceDp;
  selectedDateTime;
  reasonList = "";
  TiReason = "";
  rejectionReason = "";
  hideData = false;
  showOdometerDialog = false;
  closeOdometerDialog = false;
  closeOdometerMessage = "This ticket has been closed";
  object = Object;
  canUserTakeAction;
  userId = null;
  displayUpdateTicket = false;
  dynamicFormBasicConfiguration = null;
  roleName: string;
  issues = [];
  subIssues = [];
  viewRejectionReason = false;
  previousIssues = [];
  previousSubIssues = [];
  repair_data = [];
  ssoId;
  message = "";
  DriverList: any = "";
  schedule = "";
  currentDate = "";
  selectedMaintenanceDriver = "";
  changeRequest: boolean;
  ticketBody = {
    actionTakenBy: null,
    ticketId: "",
    action: "",
  };
  disableButton: boolean;
  isFormExist: boolean;
  workshopForm: FormGroup;
  pickupDriverForm: FormGroup;
  maintenanceList = [];
  workshopList = [];
  successBoxDialog = false;
  showDialogCarSentToWorkshop = false;
  assignPickUpDriverDialog = false;
  showAllFields = true;
  selectedPickupDriver;
  selectedPickupTime;
  minDate;
  calendarDisable = true;
  driverPenaltyDetails;
  penaltyType;
  penaltyName;
  penaltyData: any[] = [];
  displayDialog = false;
  display;
  penaltyNote;
  applicablePenaltyDurationInMins;
  reversedDuration: string;
  checkAmt: boolean = false;
  reverseAmount: number;
  reverseMbgCheckbox: boolean = false;
  penaltyReason;
  driverIdToPenalty;
  penaltyReasonValidated: boolean = false;
  penaltyDuration: number;
  reversalPenaltyMessage;
  disbursalTimestamp;
  reversalTicketUpdate: boolean = false;
  selectSlotList = [];
  selectedSlot;
  showSelectSlot: boolean = false;
  showIncidentTimestamp: boolean = false;
  selectedIncidentDateTime;
  todayDate;
  leaseDailyStatsDetails;
  reasonCodeList = [];
  selectedCode;
  resolutionDialog: boolean;
  showResolution: boolean;
  formList = [];
  selectedFormId;
  isBtnDisable: boolean = true;
  showNoIssue: boolean = false;
  noIssueDescription: string = "";
  imagesUrl: string[] = [];
  finalImagesUrl: string[] = [];
  showCarousel: boolean = false;
  noIssueIndex: boolean = false;
  showNoIssueSpinner: boolean = false;
  attachmentUrls: string[] = [];
  currentAttachments: string[] = [];
  showAttachment: boolean = false;
  showViewImage: boolean = false;
  WorkItemDetails = [];
  showWorkItemDetails: boolean = false;
  dutyDeniedPenaltyDetails;
  driverRfsCallLogCols = [
    { field: "serialNo", header: "S No." },
    { field: "callType", header: "Call Type" },
    { field: "from", header: "From" },
    { field: "to", header: "To" },
    { field: "player", header: "Player" },
    { field: "time", header: "Time" },
  ];
  dutyDeniedPenaltyType;
  displayAudioDialog: boolean = false;
  dutyDeniedAudioUrl = null;
  reversalDutyDeniedTicketUpdate: boolean = false;
  dutyDeniedPenaltyReason;
  dutyDeniedPenaltyReasonValidated;
  dutyDeniedCreditedBy;
  displayDutyDeniedPenaltyPopup: boolean = false;
  dutyDenied;
  driverAbsenteeismDetails;
  reverseSEBCheckbox: boolean = false;
  singleSlot: boolean = true;
  dailyStatsData;
  absenteeismPenaltyType;
  displayAbsenteeismPenaltyPopup: boolean = false;
  displayAbsenteeismConfirmationPopup: boolean = false;
  absenteeismPenaltyReasonValidated;
  reversalAbsentTicketUpdate: boolean = false;
  absenteeismPenaltyReason;
  absentDailyStatsDataSlotOne;
  absentDailyStatsDataSlotTwo;
  reverseSlotOne: boolean = false;
  reverseSlotTwo: boolean = false;
  reverseBothSlots: boolean = false;
  selectedSlotOne: boolean = false;
  selectedSlotTwo: boolean = false;
  selectedBothSlots: boolean = false;
  selectedSlotValidated: boolean = true;
  displaySlotList: boolean = true;

  sendByCraneDailog: boolean = false;
  isAssignedPickupDriver: boolean = false;
  isEditingAssignedPickupDriver: boolean = false;

  workshopCraneTypeForm = {
    isLoading: false,
    workshopOptions: [],
    craneTypeOptions: [],
    workshopResult: [],
    craneResult: [],
    workshop: null,
    craneType: null,
  };

  country: string;
  checkedOutHub:string;
  vehicleStatus:string;
  hubList=[];

  showRemarkModal: boolean = false;

  latestRemark = null;
  isAnyStageCtaVisible = false;
  isPickupDriverCtaVisible = false;
  showShiftInchargeCloseCta = false;

  constructor(
    private ticketViewService: TicketViewService,
    private stageViewService: StageViewService,
    private messageService: MessageService,
    private cookieService: CookieService,
    private formService: DynamicFormService,
    private ticketService: TicketViewService,
    private fb: FormBuilder,
    private hubService:HubService,
    private spinner: NgxSpinnerService,
    private localeService: LocalService,
    private sendByCraneService: SendByCraneService,
    private incidentHeirarchyService: IncidentHeirarchyService
  ) {
    this.userId = this.cookieService.get("ssoId");
    this.country = localStorage.getItem("country");
    this.minDate = this.localeService.getDate();
    this.todayDate = this.localeService.getDate();

    this.getWorkshopAndCranesByZone();
  }

  isStagePresent(stage: string) {
    if (!this.ticket || !this.ticket.stageData) return false;
    return this.ticket.stageData.find((s) => s.stageName === stage);
  }

  getWorkshopAndCranesByZone() {
    // call workshop and crane data
    const zoneId = this.cookieService.get("selectedZone");
    this.workshopCraneTypeForm.isLoading = true;

    this.sendByCraneService.getWorkshopAndCranesByZone(zoneId).subscribe(
      (res) => {
        const { code, response, message } = res;
        if (code !== 200) {
          console.error(message);
          return;
        }

        const { cranes = [], workshops = [] } = response;
        const craneOptions = cranes.map((crane) => {
          return { label: crane.name, value: crane };
        });
        const workshopOptions = workshops.map((workshop) => {
          return { label: workshop.workshopName, value: workshop };
        });

        this.workshopCraneTypeForm.workshopResult = workshops;
        this.workshopCraneTypeForm.craneResult = cranes;
        this.workshopCraneTypeForm.workshopOptions = workshopOptions;
        this.workshopCraneTypeForm.craneTypeOptions = craneOptions;

        if (
          this.ticket &&
          this.ticket.centerLocation &&
          this.ticket.centerLocation.craneId
        ) {
          this.workshopCraneTypeForm.craneType =
            this.workshopCraneTypeForm.craneResult.find(
              (crane) => `${crane.id}` === this.ticket.centerLocation.craneId
            );
        }
        if (
          this.ticket &&
          this.ticket.centerLocation &&
          this.ticket.centerLocation.workshopId
        ) {
          this.workshopCraneTypeForm.workshop =
            this.workshopCraneTypeForm.workshopResult.find(
              (w) => w.id == this.ticket.centerLocation.workshopId
            );
        }

        this.workshopCraneTypeForm.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.workshopCraneTypeForm.isLoading = false;
      }
    );
  }

  transformData(data: any[]): any[] {
    const workgroupMap: { [key: string]: any } = {};

    data.forEach((item) => {
      if (!workgroupMap[item.workgroupName]) {
        workgroupMap[item.workgroupName] = {
          ...item,
          workitemName: [item.workitemName],
        };
      } else {
        workgroupMap[item.workgroupName].workitemName.push(item.workitemName);
      }
    });

    Object.values(workgroupMap).forEach((workgroup: any) => {
      const itemCounts = workgroup.workitemName.reduce(
        (acc: { [key: string]: number }, name: string) => {
          acc[name] = (acc[name] || 0) + 1;
          return acc;
        },
        {}
      );

      workgroup.workitemName = Object.entries(itemCounts).map(
        ([name, count]: [string, number]) => {
          return count > 1 ? `${name} (${count})` : name;
        }
      );
    });
    return Object.values(workgroupMap);
  }

  ngOnInit() {
    this.canUserTakeAction = false;
    this.getReasonList();
    this.getFormList();
    this.roleName = localStorage.getItem("roleName");
    this.ssoId = this.cookieService.get("ssoId");
    this.hubService.getAllHubs().subscribe(({ response = [] }: any) => {      
      this.hubList = response;
    })

    this.ticketViewService
      .getDetailsForTicketId(this.ticketId)
      .subscribe((res: Response<[]>) => {
        this.ticket = res.response;
        console.log(this.ticket['formData']['Vehicle Number'])
        if(this.ticket.project=='operationsTMS'){
          let vehicleNumber=this.ticket['formData']['Vehicle Number'] ?this.ticket['formData']['Vehicle Number']:this.ticket['formData']['Vehicle Number'][0]

          this.ticketViewService.getVehicleOnlineStatus(vehicleNumber).subscribe((res:any)=>{
            this.vehicleStatus=res['response'].state
            this.checkedOutHub=res['response'].checkedOutHub
          })

        }
        

        this.latestRemark = this.getLatestRemark(this.ticket);
        if (
          this.ticket.jobCardStatus == "INITIATED" &&
          this.ticket.currentStage == "Due for hub review"
        ) {
          this.ticketViewService
            .getWorkItemDetails(this.ticket.jobCardNumber)
            .subscribe((res: any) => {
              this.WorkItemDetails = this.transformData(
                res.response.workItemStateList
              );
            });

          this.showWorkItemDetails = true;
        } else {
          this.showWorkItemDetails = false;
        }

        if (
          this.ticket.formId == "Q9pxdIJo" ||
          this.ticket.formId == "nH8XDd67"
        ) {
          this.isFormExist = true;
        }
        if (
          this.ticket.currentStage === "Resolved" ||
          this.ticket.currentStage === "Declined"
        ) {
          this.disableButton = true;
        }

        if (this.ticket.category[1] !== "Preventive Services") {
          this.checkSubissues();
        }
        if (this.ticket.currentStage == "Due for hub review") {
          this.TiReason = this.ticket.stageData[
            this.ticket.stageData.length - 1
          ]["stageActionData"]
            ? this.ticket.stageData[this.ticket.stageData.length - 1][
                "stageActionData"
              ][0]["description"]
            : "";
          const files =
            this.ticket.stageData[this.ticket.stageData.length - 1]["files"];
          if (files && files.length) {
            this.currentAttachments = files;
            this.attachmentUrls = files;
            this.showViewImage = true;
          }
        }
        const { assignedForEdit = [], assignedForAction = [] } = this.ticket;
        this.allowedToEdit = assignedForEdit.some(
          ({ ssoId }) => ssoId == this.userId
        );
        if (this.ticket.project == "driverPartnerTMS")
          this.allowedToEdit = null;
        this.canUserTakeAction = assignedForAction.some(
          ({ ssoId }) => ssoId == this.userId
        );
        if (this.ticket.files && this.ticket.files.length) {
          this.resources = this.ticket.files.map((file) => ({
            ...file,
            isSelected: true,
          }));
        }

        let ssoId = this.cookieService.get("ssoId");
        // if (this.ticket.assignedForAction.includes(ssoId)) {
        //   this.canUserTakeAction = true;
        // }
        this.home = { icon: "pi pi-home" };
        this.segregatedCategory = [];
        for (let category in this.ticket["category"]) {
          this.segregatedCategory.push({
            label: this.ticket["category"][category],
          });
        }
        this.segregatedCategory.push({ label: this.ticket["ticketId"] });
        if (this.ticket["stageId"]) {
          let stageId = this.ticket["stageId"];
          this.stageViewService
            .getStageDataFromStageId(stageId)
            .subscribe((res: Response<[]>) => {
              this.stageData = res.response["stageData"];

              if (this.ticket["currentStage"] == "Sent to workshop")
                this.currentStageData = this.stageData.find(
                  (x) => x.stageName === "Car sent to workshop"
                );
              else
                this.currentStageData = this.stageData.find(
                  (x) => x.stageName === this.ticket["currentStage"]
                );
            });
        }

        // if(this.ticket.currentStage == 'Driver Assigned' || this.ticket.currentStage == 'Vehicle at workshop' ){
        if (
          this.country !== "AE" &&
          (this.ticket.currentStage == "Odometer Reading" ||
            this.ticket.currentStage == "Ticket Created" ||
            this.ticket.currentStage == "Assigned to MSE")
        ) {
          this.stageViewService
            .getVehicleSeviceRegionList()
            .subscribe((res) => {
              this.serviceCenterList = [];
              res["response"].forEach((element) => {
                this.serviceCenterList.push({
                  label: element["workshopName"],
                  value: {
                    id: element["id"],
                    location: element["workshopName"],
                    lat: element["latitude"],
                    lng: element["longitude"],
                  },
                });
              });
            });
        }
        let tmsPenaltyType = this.ticket.formId;
        if (
          this.ticket &&
          this.ticket.formData &&
          this.ticket.formData.fieldsData &&
          this.ticket.formData.fieldsData.newIssueType
        ) {
          tmsPenaltyType = this.ticket.formData.fieldsData.newIssueType;
        }
        this.getDriverPenaltyDetailsForCategoryChange(tmsPenaltyType);
        if (this.penaltyType && this.ticket && this.ticket.leaseDailyStatsId) {
          this.getDriverPenalty();
        }
        if (this.dutyDeniedPenaltyType)
          this.getDriverDutyDeniedPenaltyDetails();
        if (this.absenteeismPenaltyType) this.getDriverAbsenteeismDetails();
      });
    this.getFormData();
    this.workshopForm = this.fb.group({
      workshop: ["", Validators.required],
      maintenanceDp: [""],
      pickupDateTime: [""],
    });
    this.pickupDriverForm = this.fb.group({
      maintenanceDp: ["", Validators.required],
      pickupDateTime: ["", Validators.required],
    });

    this.amount = 0;
    this.insurance = 0;
    this.remark = null;
    this.refund = null;
  }

  backToAllTicket() {
    localStorage.removeItem("ticketId");
    this.backToTicket.emit();
  }

  getCraneAndWorkshopDetails() {
    const zoneId = this.cookieService.get("selectedZone");

    this.sendByCraneService.getWorkshopAndCranesByZone(zoneId).subscribe(
      (res) => {
        const { code, response, message } = res;
        if (code !== 200) {
          console.error(message);
          return;
        }

        const { cranes = [], workshops = [] } = response;
        const craneOptions = cranes.map((crane) => {
          return { label: crane.name, value: crane };
        });
        const workshopOptions = workshops.map((workshop) => {
          return { label: workshop.workshopName, value: workshop };
        });

        this.workshopCraneTypeForm.workshopResult = workshops;
        this.workshopCraneTypeForm.craneResult = cranes;
        this.workshopCraneTypeForm.workshopOptions = workshopOptions;
        this.workshopCraneTypeForm.craneTypeOptions = craneOptions;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getLatestStage() {
    const { stageData } = this.ticket;
    return stageData[stageData.length - 1];
  }

  getPickUpDriverObject(key: string) {
    const stage = this.getLatestStage();
    if (stage.stageActionData && stage.stageActionData.length) {
      const obj = stage.stageActionData.find((s) => s[key]);
      return obj[key] ? obj[key] : "";
    }
    return "";
  }

  openEditSendByCrane() {
    this.selectedStageData = this.stageData.find(
      (stage) => stage.stageName === "Sent by crane"
    );

    this.workshopCraneTypeForm.craneType =
      this.workshopCraneTypeForm.craneResult.find(
        (crane) => `${crane.id}` === this.ticket.centerLocation.craneId
      );
    this.workshopCraneTypeForm.workshop =
      this.workshopCraneTypeForm.workshopResult.find(
        (w) => w.id == this.ticket.centerLocation.workshopId
      );
    this.sendByCraneDailog = true;
  }

  onDialogClose() {
    this.isAssignedPickupDriver = false;
    this.isEditingAssignedPickupDriver = false;
    this.workshopCraneTypeForm.craneType = null;
    this.workshopCraneTypeForm.workshop = null;
  }

  submitWorkshopCraneForm() {
    const { workshop, craneType } = this.workshopCraneTypeForm;

    this.ticket["centerLocation"] = {
      ...this.ticket["centerLocation"],
      workshopId: workshop.id,
      workshopName: workshop.workshopName,
      craneId: craneType.id,
      craneName: craneType.name,
    };

    // reset selected data to disable the button
    this.workshopCraneTypeForm.craneType = null;
    this.workshopCraneTypeForm.workshop = null;
    this.sendByCraneDailog = false;

    this.submitStageForm();
  }

  openStageForm(selectedStage, edit) {
    if (selectedStage === "Sent by crane") {
      this.selectedStageData = this.stageData.find(
        (stage) => stage.stageName === selectedStage
      );
      this.sendByCraneDailog = true;
      this.isAssignedPickupDriver =
        this.selectedStageData.stageName === "Workshop Pick-up Driver Assigned";
      return;
    }
    if (selectedStage === "Odometer Reading") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName === selectedStage
      );
      this.showOdometer = true;
      this.hideData = true;
      return;
    }

    if (selectedStage === "No Issue Found" && this.country !== "AE") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName === "No Issue Found"
      );
      this.showNoIssue = true;
      return;
    }
    if (selectedStage === "Reject resolution") {
      this.showRejectionReason();
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName === "Rejected in Hub review"
      );
      return;
    }

    if (selectedStage == "Accept resolution") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName === "Close Ticket"
      );
      this.submitStageForm();
      return;
    }

    if (selectedStage == "Hub pickup driver assigned") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName == "Hub pickup driver assigned"
      );
      return;
    }
    if (selectedStage == "Assign pickup driver") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName == "Assign pickup driver"
      );
      return;
    }
    if (selectedStage == "Workshop pickup driver assigned") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName == "Workshop pickup driver assigned"
      );
      return;
    }
    if (selectedStage == "MD arrived at workshop") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName == "MD arrived at workshop"
      );
      return;
    }
    if (selectedStage == "Ride canceled by MD") {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName == "Ride canceled by MD"
      );
      return;
    }
    if (
      selectedStage === "Car sent to workshop" ||
      selectedStage === "Send car to workshop" ||
      selectedStage === "Assign workshop at hub" ||
      selectedStage === "Repaired at Hub"
    ) {
      this.selectedStageData = this.stageData.find(
        (x) => x.stageName === selectedStage
      );
      this.hideData = true;
      this.showOdometer = false;

      this.doManageState(false);
      if (edit) {
        this.workshopForm.reset();
      }
      this.showDialogCarSentToWorkshop = true;
      if (
        selectedStage === "Car sent to workshop" ||
        selectedStage === "Send car to workshop"
      ) {
        this.selectedStageData = this.stageData.find(
          (x) => x.stageName === "Car sent to workshop"
        );
        this.showAllFields = true;
      }

      if (
        selectedStage === "Repaired at Hub" ||
        selectedStage === "Assign workshop at hub"
      ) {
        this.selectedStageData = this.stageData.find(
          (x) => x.stageName === "Repair car at hub"
        );
        this.showAllFields = false;
      }
      if (edit) {
        this.submitStageForm();
      }

      // this.submitStageForm();
      return;
    }
    this.selectedStageData = this.stageData.find(
      (x) => x.stageName === selectedStage
    );

    this.selectedReason = this.reasonList;
    if (this.reasonList && this.reasonList !== "Other") {
      this.submitStageForm();
      return;
    }
    if (
      this.ticket.formData["PMS Flow"] == "NEW" &&
      this.selectedStageData.elements[0] == "Amount" &&
      this.selectedStageData.stageName != "Repair car at hub"
    ) {
      this.submitStageForm();
      return;
    }
    if (
      selectedStage === "Resolved" &&
      this.ticket.formData.fieldsData &&
      this.ticket.formData.fieldsData.newCategory
    ) {
      this.getFormId(this.ticket.formData.fieldsData["newIssueType"]);
      this.resolutionNotes = null;
      this.isBtnDisable = true;
      this.selectedCode = null;
      this.showResolution = false;
      this.getResolutionReasons(this.selectedFormId);
      this.resolutionDialog = true;
      return;
    }
    if (selectedStage === "Resolved") {
      this.resolutionNotes = null;
      this.isBtnDisable = true;
      this.selectedCode = null;
      this.showResolution = false;
      this.getResolutionReasons(this.ticket.formId);
      this.resolutionDialog = true;
      return;
    }

    this.displayModal = true;
  }

  filldata() {
    const previousValues = Object.assign({}, this.ticket.centerLocation);
    this.ticket.centerLocation = {};
    this.ticket.centerLocation = {
      workshopId:
        this.selectedServiceCenter && this.selectedServiceCenter.value
          ? this.selectedServiceCenter.value.id.toString()
          : null,
      workshopName:
        this.selectedServiceCenter && this.selectedServiceCenter.value
          ? this.selectedServiceCenter.value.workshopName
          : null,
      driverId:
        this.selectedMaintenanceDp && this.selectedMaintenanceDp.value
          ? this.selectedMaintenanceDp.value.id.toString()
          : null,
      driverName:
        this.selectedMaintenanceDp && this.selectedMaintenanceDp.value
          ? this.selectedMaintenanceDp.value.firstName +
            " " +
            this.selectedMaintenanceDp.value.lastName
          : null,
      craneId: previousValues.craneId,
      craneName: previousValues.craneName,
      scheduleTimestamp: this.selectedDateTime
        ? this.localeService.getDate(this.selectedDateTime).getTime()
        : null,
    };
  }
  submitStageForm() {
    this.displayModal = false;
    this.resolutionDialog = false;
    let currentStageData = this.currentStageData;
    let stageActionData = [];
    this.currentStageData = null;
    if (
      this.selectedStageData.elements.includes("Odometer Reading") &&
      (this.odometerReading == undefined ||
        this.odometerReading < 0 ||
        (this.ticket.lastOdometerReading != null &&
          this.ticket.lastOdometerReading != undefined &&
          this.ticket.lastOdometerReading > this.odometerReading))
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid Odometer Reading",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Odometer Reading")) {
      if (this.odometerReading.toString().length > 6) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please enter valid Odometer Reading",
        });
        this.doManageState(true);
        return "";
      }
      stageActionData.push({ "Odometer Reading": this.odometerReading });
    }

    if (
      (this.ticket.formData["PMS Flow"] == "OLD" ||
        this.ticket.formData["PMS Flow"] == null) &&
      this.selectedStageData.elements.includes("Amount") &&
      (this.amount == undefined || this.amount < 0)
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid amount",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Amount")) {
      stageActionData.push({ Amount: this.amount });
    }

    if (this.selectedStageData.elements.includes("Assign pickup driver")) {
      // if (this.disableSubmitButton) {
      //   return;
      // }
      // this.disableSubmitButton = true;
      const previousValues = Object.assign({}, this.ticket.centerLocation);
      this.ticket.centerLocation = {};

      this.ticket.centerLocation = {
        returningScheduleTimestamp: this.selectedPickupTime ? this.localeService.getDate(this.selectedPickupTime).getTime() : null,
        pickUpDriverId:
          this.selectedPickupDriver && this.selectedPickupDriver.value
            ? this.selectedPickupDriver.value.id.toString()
            : null,
        pickUpDriverName:
          this.selectedPickupDriver && this.selectedPickupDriver.label
            ? this.selectedPickupDriver.label
            : null,
        workshopId: this.workshopForm.value.workshop.value.id
          ? this.workshopForm.value.workshop.value.id.toString()
          : null,
        workshopName: this.workshopForm.value.workshop.value.workshopName
          ? this.workshopForm.value.workshop.value.workshopName
          : null,
        craneId: previousValues.craneId,
        craneName: previousValues.craneName,
      };
    }
    if (
      this.selectedStageData.elements.includes("Hub pickup driver assigned")
    ) {
      // if (this.disableSubmitButton) {
      //   return;
      // }
      // this.disableSubmitButton = true;
      this.ticket.driverid =
        this.selectedMaintenanceDp && this.selectedMaintenanceDp.value
          ? this.selectedMaintenanceDp.value.id.toString()
          : null;
      this.filldata();
      this.currentStageData = currentStageData;
    }

    if (
      this.selectedStageData.elements.includes("Service Location") &&
      this.selectedServiceCenter.value == undefined
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please select service center",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Service Location")) {
      stageActionData.push({
        "Service Location": this.selectedServiceCenter.value.location,
      });
      this.filldata();
      // this.ticket["centerLocation"] = this.selectedServiceCenter.value;
      this.currentStageData = currentStageData;
      this.hideData = false;
    }

    if (
      this.selectedStageData.elements.includes("Remark") &&
      (this.remark == undefined || this.remark.trim() == "")
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid remark",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Remark")) {
      stageActionData.push({ Remark: this.remark });
    }

    if (
      this.selectedStageData.elements.includes("Resolution Notes") &&
      (this.resolutionNotes == undefined || this.resolutionNotes.trim() == "")
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid resolution notes",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (
      this.selectedStageData.elements.includes("Resolution Notes") &&
      !this.ticket.formData.fieldsData.newCategory
    ) {
      stageActionData.push({ "Resolution Notes": this.resolutionNotes });
      this.ticket["resolutionMarks"] = this.resolutionNotes;
      this.ticket["formData"] = {
        ...this.ticket["formData"],
        reasonCode: this.selectedCode.label,
      };
    } else if (
      this.selectedStageData.elements.includes("Resolution Notes") &&
      this.ticket["formData"].fieldsData.newCategory
    ) {
      stageActionData.push({
        "Resolution Notes":
          "(This issue is to be raised in " +
          this.ticket.formData.fieldsData.newCategory +
          " and " +
          this.ticket.formData.fieldsData.newIssueType +
          ") " +
          this.resolutionNotes,
      });
      this.ticket["resolutionMarks"] =
        "(This issue is to be raised in " +
        this.ticket.formData.fieldsData.newCategory +
        " and " +
        this.ticket.formData.fieldsData.newIssueType +
        ") " +
        this.resolutionNotes;
      this.ticket["formData"] = {
        ...this.ticket["formData"],
        reasonCode: this.selectedCode.label,
      };
    }

    if (
      this.selectedStageData.elements.includes("Discard Reason") &&
      (this.selectedReason == undefined || this.selectedReason.trim() == "")
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please select valid decline reason",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Discard Reason")) {
      if (
        this.selectedReason == "Other" &&
        (this.discardReason == undefined || this.discardReason.trim() == "")
      ) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please enter valid decline reason",
        });
        this.currentStageData = currentStageData;
        return;
      } else {
        this.selectedReason != "Other"
          ? (this.discardReason = this.selectedReason)
          : null;
        stageActionData.push({ "Discard Reason": this.discardReason });
        this.ticket["Discard Reason"] = this.discardReason;
      }
    }

    if (
      this.selectedStageData.elements.includes("Refund") &&
      (this.refund == undefined || this.refund.trim() == "")
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid refund",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Refund")) {
      stageActionData.push({ Refund: this.refund });
    }
    if (
      this.selectedStageData.elements.includes("Insurance") &&
      (this.insurance == undefined || this.insurance < 0)
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Please enter valid insurance amount",
      });
      this.currentStageData = currentStageData;
      return;
    } else if (this.selectedStageData.elements.includes("Insurance")) {
      stageActionData.push({ "Insurance Amount": this.insurance });
    }

    this.canUserTakeAction = false;
    this.messageService.add({
      severity: "info",
      summary: "In Progress",
      detail: "Please wait update in progress",
    });
    this.amount = 0;
    this.insurance = 0;
    this.remark = null;
    let ssoId = this.cookieService.get("ssoId");

    let updateData = this.ticket;
    let stageName = "";
    let stageNote = null;
    updateData["Repair"] = this.previousIssues;
    updateData["subIssue"] = this.previousSubIssues;

    //PMS
    // if (
    //   this.ticket["category"].includes("Preventive Services") &&
    //   this.selectedStageData.stageName == "Car sent to workshop"
    // ) {
    //   stageName = "Odometer Reading Entered";
    //   stageNote = "Assign this vehicle to a driver partner before proceeding";
    // } else
    stageName = this.selectedStageData.stageName;
    if (stageName != "Close Ticket") {
      if (stageName === "No Issue Found") {
        let stageData = {
          stageName: stageName,
          stageActionData: [{ description: this.noIssueDescription }],
          createdTime: this.localeService.getDate(),
          actionTakenBy: Number(ssoId),
          files: this.imagesUrl,
        };
        updateData["stageData"].push(stageData);
        if (this.country !== "AE") {
          updateData["currentStage"] = "Due for hub review";
        } else {
          updateData["currentStage"] = "No Issue Found";
        }
        updateData.role = ["Shift Incharge"];
      } else {
        let stageData = {
          stageName: stageName,
          stageActionData: stageActionData,
          createdTime: this.localeService.getDate(),
          actionTakenBy: ssoId,
          stageNote: stageNote,
        };
        updateData["stageData"].push(stageData);

        updateData["currentStage"] = stageName;
      }
    }
    // if(this.ticket.isForceClose && !(['425954','17098','434117','434117','410909','186038','119']).includes(ssoId)){
    //     window.alert("Force Close not allowed")
    //     this.getTicketDetails();
    //     return
    //   }

    if (this.ticket.currentStage === "Due for hub review") {
      this.ticket.isForceClose = stageName == "Close Ticket";
    }

    if (
      stageName == "Close Ticket" &&
      this.selectedStageData.children.includes("Close Ticket")
    ) {
      updateData["stageData"].push({
        stageName: "Close Ticket",
        createdTime: this.localeService.getDate(),
        actionTakenBy: ssoId,
      });
      updateData["currentStage"] = "Ticket Closed";
      // updateData['colour']=''
      updateData["role"] = [];
      updateData["isActive"] = false;
    }
    this.ticket.actionType = "STAGE_CHANGE";
    this.ticket.odometerReadingEntered = this.odometerReading;
    if (
      this.previousIssues.length > 0 &&
      this.ticket.category[1] !== "Preventive Services"
    ) {
      this.ticket.formData["Repair"] = this.previousIssues;
      this.ticket.formData["subIssue"] = this.previousSubIssues;
    }
    this.ticket.updatedBy = this.ssoId;
    this.showDialogCarSentToWorkshop = false;
    this.assignPickUpDriverDialog = false;
    this.showNoIssue = false;
    this.ticketViewService.updateTicket(this.ticket).subscribe((res: any) => {
      if (res.code == 200) {
        this.getTicketDetails();
        if (
          this.ticket.project == "operationsTMS" &&
          (res.response.currentStage == "Hub pickup driver assigned" ||
            res.response.currentStage == "Workshop pickup driver assigned")
        ) {
          this.successBoxDialog = true;
        }
      } else {
        alert(res.response["message"]);
      }
    });
  }
  doManageState(check) {
    let ssoId = this.cookieService.get("ssoId");
    // if (this.ticket.assignedForAction.includes(ssoId)) {
    //   this.canUserTakeAction = true;
    // }

    if (this.ticket.files && this.ticket.files.length) {
      this.resources = this.ticket.files.map((file) => ({
        ...file,
        isSelected: true,
      }));
    }

    this.home = { icon: "pi pi-home" };
    this.segregatedCategory = [];
    for (let category in this.ticket["category"]) {
      this.segregatedCategory.push({
        label: this.ticket["category"][category],
      });
    }
    this.segregatedCategory.push({ label: this.ticket["ticketId"] });
    if (this.ticket["stageId"]) {
      let stageId = this.ticket["stageId"];
      this.stageViewService
        .getStageDataFromStageId(stageId)
        .subscribe((res: Response<[]>) => {
          this.stageData = res["data"]["stageData"];
          this.currentStageData = this.stageData.find(
            (x) => x.stageName === this.ticket["currentStage"]
          );
          if (this.ticket["currentStage"] == "Sent to workshop") {
            this.currentStageData = this.stageData.find(
              (x) => x.stageName === "Car sent to workshop"
            );
          } else if (this.ticket["currentStage"] == "Ticket Created") {
            this.showOdometer = false;
            this.hideData = false;
          } else {
            this.showOdometer = false;
            this.hideData = false;
            this.currentStageData = this.stageData.find(
              (x) => x.stageName === this.ticket["currentStage"]
            );

            if (check) {
              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Ticket Successfully updated",
              });
            }
          }
        });
    }
  }
  getTicketDetails() {
    this.ticketViewService
      .getDetailsForTicketId(this.ticketId)
      .subscribe((res: Response<[]>) => {
        this.ticket = res.response;
        this.latestRemark = this.getLatestRemark(this.ticket);
        const { assignedForEdit = [], assignedForAction = [] } = this.ticket;
        this.allowedToEdit = assignedForEdit.some(
          ({ ssoId }) => ssoId == this.userId
        );
        this.canUserTakeAction = assignedForAction.some(
          ({ ssoId }) => ssoId == this.userId
        );
        if (this.ticket.category[1] !== "Preventive Services") {
          this.checkSubissues();
        }
        this.doManageState(true);
      });
  }
  editTicketForm() {
    const { formId, formData } = this.ticket;
    this.formService
      .getFormById(formId)
      .subscribe(({ response: form, code, message }: any) => {
        if (code !== 200) {
          return alert(message);
        }
        form.elements = form.elements.map((e) => {
          if (e.name !== "submit") {
            return {
              ...e,
              value:
                (e.html === "dateTime" &&
                  formData[e.name] &&
                  this.localeService.getDate(formData[e.name])) ||
                formData[e.name] ||
                "",
            };
          }
          return e;
        });
        this.dynamicFormBasicConfiguration = form;
        this.displayUpdateTicket = true;
      });
  }
  submitCurrentForm({ values }) {
    const apiData = {
      ...this.ticket,
      formData: {
        ...this.ticket.formData,
        ...values,
      },
    };
    this.ticketViewService
      .updateTicket(apiData)
      .subscribe(({ response, code, errorMessage }: any) => {
        if (code === 400) {
          alert(
            "This ticket cannot be edited as the vehicle has already been marked “Car sent to workshop”"
          );
          return;
        }
        if (code !== 200) {
          return alert(errorMessage);
        }
        this.ticket = response;
        this.latestRemark = this.getLatestRemark(this.ticket);
        this.restoreStateOfUpdate();
        this.getTicketDetails();
        return;
      });
  }
  restoreStateOfUpdate() {
    this.displayUpdateTicket = false;
    this.dynamicFormBasicConfiguration = null;
  }
  updateFiles() {
    const { ticketId } = this.ticket;
    this.restoreStateOfUpdateResources();
    this.ticketViewService
      .updateFiles(
        ticketId,
        this.resources.filter((resource) => resource.isSelected)
      )
      .subscribe(({ code, response, message }) => {
        if (code !== 200) {
          return alert(message);
        }
        this.ticket = response;
        this.latestRemark = this.getLatestRemark(this.ticket);
        return alert(message);
      });
  }
  fileUpdateDialog() {
    this.displayUpdateResources = true;
  }
  convertToNormalCase(str: string) {
    if ("selectSlot" === str) {
      str = str.replace("select", "incident");
    }
    let splitWord = str.replace(/([A-Z])/g, " $1");

    let words = splitWord.split(" ");
    let result = [];
    for (let word of words) {
      result.push(
        word.substr(0, 1).toUpperCase() +
          word.substr(1, word.length).toLowerCase()
      );
    }
    return result.join(" ");
  }

  isObject(data) {
    if (Array.isArray(data)) {
      if (data && data.length > 0 && data[0] === Object(data[0])) {
        return "ARRAY_OF_OBJECT";
      } else return "PRIMITIVE";
    }

    if (data === Object(data)) return "OBJECT";
    else return "PRIMITIVE";
  }

  getHeaders(data) {
    let result = [];

    for (let obj of data) {
      if (obj) {
        for (let key of Object.keys(obj)) {
          if (!result.includes(key)) result.push(key);
        }
      }
    }
    return result;
  }
  reviewDialogBox(type) {
    this.reviewType = type;
    this.reviewDialog = true;
  }
  createRide(action) {
    //dispatch call to create Ride
    if (action == "SEND_DRIVER_TO_WORKSHOP") {
      this.ticket["assignedDriverName"] = this.ticket.assignedDriverName;
      this.ticket["driverPunchId"] = this.ticket.driverPunchId;
      //It will be a conditional execution based on new and old pms flow please add condtition NEW_OLD
      if (
        this.ticket.formData["PMS Flow"] == "OLD" ||
        this.ticket.formData["PMS Flow"] == null
      ) {
        this.ticket["currentStage"] = "Review Service Challan";
        this.ticket["isServiceChallanUploaded"] = false;
        this.ticket["stageData"].push({
          stageName: "Vehicle at workshop",
          stageActionData: [
            {
              assignedDriverName: this.ticket.assignedDriverName,
              driverPunchId: this.ticket.driverPunchId,
            },
          ],
          createdTime: this.localeService.getDate(),
          actionTakenBy: this.cookieService.get("ssoId"),
        });
      }
    }
    let driverId = this.ticket["driverPunchId"];
    this.ticket["isRideAssigned"] = true;
    this.ticket["centerLocation"] = this.ticket["centerLocation"]
      ? this.ticket["centerLocation"]
      : this.selectedServiceCenter.value;
    this.ticketViewService
      .createPhantomRide(driverId, this.ticket["centerLocation"])
      .subscribe((res) => {
        if (res["response"] == true) {
          this.ticket.updatedBy = this.userId;
          this.ticketViewService
            .updateTicket(this.ticket)
            .subscribe((res: Response<{}>) => {
              this.getTicketDetails();
            });
        } else {
          alert("Something went wrong");
          this.getTicketDetails();
        }
      });
  }
  receivedCard() {
    this.ticket["stageData"].push({
      stageName: "Received Car from workshop",
      stageActionData: [],
      createdTime: this.localeService.getDate(),
      actionTakenBy: this.userId,
    });
    this.ticket["stageData"].push({
      stageName: "Close Ticket",
      createdTime: this.localeService.getDate(),
      actionTakenBy: this.userId,
    });
    this.ticket["currentStage"] = "Ticket Closed";
    this.ticket["isActive"] = false;
    this.ticket.updatedBy = this.userId;
    this.ticketViewService
      .updateTicket(this.ticket)
      .subscribe((res: Response<{}>) => {
        if (res.errorCode != 200) alert(res.response["message"]);
        this.getTicketDetails();
      });
  }

  review(type, status) {
    let driverId = Number(this.ticket["driverPunchId"]);
    if (type == "JOB_CARD") {
      if (status == "Approved" || status == "APPROVED_AND_CLOSE_TICKET") {
        this.ticket.currentStage = "Vehicle at workshop";
        this.ticket["approvedJobCard"] = this.ticket.reviewCardUrl;
        this.ticket["assignedDriverName"] = null;
        this.ticket["driverPunchId"] = null;
        this.ticket["isRideAssigned"] = false;
      }
      if (status == "Rejected") {
        this.ticket["stageData"].push({
          stageName: "Awaiting Job Card Approval",
          stageActionData: [
            {
              reviewCardUrl: this.ticket.reviewCardUrl,
              status: "REJECTED",
            },
          ],
          createdTime: this.localeService.getDate(),
          actionTakenBy: this.cookieService.get("ssoId"),
        });
        this.ticket["isReviewJobCardUploaded"] = false;
        this.ticket.reviewCardUrl = null;
      }
    } else if (type == "SERVICE_CHALLAN") {
      if (status == "Approved") {
        this.ticket.currentStage = "Vehicle Received";
        this.ticket["approvedServiceChallan"] = this.ticket.serviceChallanUrl;
      }
      if (status == "Rejected") {
        this.ticket["stageData"].push({
          stageName: "Review Service Challan",
          stageActionData: [
            {
              reviewCardUrl: this.ticket.serviceChallanUrl,
              status: "REJECTED",
            },
          ],
          createdTime: this.localeService.getDate(),
          actionTakenBy: this.cookieService.get("ssoId"),
        });
        this.ticket["isServiceChallanUploaded"] = false;
        this.ticket.serviceChallanUrl = null;
      }
    }
    this.reviewDialog = false;
    this.ticket.updatedBy = this.userId;
    //Call Reject/Approve Dispatch API
    this.ticketViewService
      .reviewDocument(
        driverId,
        this.ticket["formData"]["Vehicle Number"],
        status.toUpperCase()
      )
      .subscribe((res) => {
        if (
          status == "APPROVED_AND_CLOSE_TICKET" &&
          res["response"] == "SUCCESS"
        ) {
          this.ticket["stageData"].push({
            stageName: "Received Car from workshop",
            stageActionData: [],
            createdTime: this.localeService.getDate(),
            actionTakenBy: this.userId,
          });
          this.ticket["stageData"].push({
            stageName: "Close Ticket",
            createdTime: this.localeService.getDate(),
            actionTakenBy: this.userId,
          });
          this.ticket["currentStage"] = "Ticket Closed";
          this.ticket["isActive"] = false;
        }
        this.ticketViewService
          .updateTicket(this.ticket)
          .subscribe((res: Response<{}>) => {
            this.getTicketDetails();
          });
      });
  }

  viewImage(image) {
    switch (image) {
      case "JOB_CARD":
        this.imageUrl = this.ticket.approvedJobCard;
        this.viewImageFlag = true;
        break;
      case "SERVICE_CHALLAN":
        this.imageUrl = this.ticket.approvedServiceChallan;
        this.viewImageFlag = true;
        break;
      case "DriverIssue":
        this.imageUrl =
          this.ticket.formData.file != undefined
            ? this.ticket.formData.file
            : this.ticket.formData.fieldsData.files;
        this.viewImageFlag = true;
    }
  }

  getSelectedStageTitle() {
    if (this.ticket && this.ticket.currentStage === "Assigned to MSE") {
      return "Send by crane";
    }
    return this.ticket && this.ticket.currentStage
      ? this.ticket.currentStage
      : "";
  }

  getStageName(stage) {
    if (stage == "Car Repaired") {
      return "Close Ticket";
    } else if (stage === "Sent by crane") {
      return "Send by crane";
    } else {
      return stage;
    }
  }

  openEditCategoryModal() {
    this.editCategoryView = true;
  }

  updateTicket() {
    let stageActionData = [];
    if (this.ticket.currentStage == "Ticket Created") {
      /*   let formIndex = this.formsList.findIndex(form => {
         return form.name == this.selectedCategory.value
         })
      let form = this.formsList[formIndex];
      this.ticket.category = [form.name];
     this.ticket.formData.type = this.selectedType.value;
      this.ticket.formId = form.formId;
      this.ticket.formData.typeId = this.formsList[formIndex].types[this.selectedType.value];

     if (form.name == 'Car Maintenance Issues') {
        this.ticket.formData['team'] = 'Maintenance Team';
      } else if (form.name == 'Ride Related Issues') {
        this.ticket.formData['team'] = 'Operations Team';
      } else {
        this.ticket.formData['team'] = 'Onboarding Team';
      }
      //      formId Can't Be Update for Dynamic forms

      */
      stageActionData.push({
        "Discard Reason":
          "Please raise it as a " +
          this.selectedType.label +
          " ticket in " +
          this.selectedCategory.label,
      });

      this.ticket["stageData"].push({
        stageName: "Discard",
        stageActionData: stageActionData,
        createdTime: this.localeService.getDate(),
        actionTakenBy: this.userId,
      });
      this.ticket["currentStage"] = "Discard";
      this.ticketViewService
        .updateTicket(this.ticket)
        .subscribe((res: Response<{}>) => {
          if (res.errorCode == 400) alert(res.response["message"]);
          this.selectedType = "";
          this.selectedCategory = "";
          this.getTicketDetails();
          this.editCategoryView = false;
          this.onCloseCategory();
        });
    } else if (this.ticket.currentStage == "Due for hub review") {
      this.rejectionReason.trim();
      if (!this.rejectionReason) {
        alert("Enter atleast valid string");
        return "";
      }
      stageActionData.push({
        Reason: this.rejectionReason,
      });

      this.ticket["stageData"].push({
        stageName: "Rejected in hub review",
        stageActionData: stageActionData,
        createdTime: this.localeService.getDate(),
        actionTakenBy: this.userId,
      });
      this.ticket["currentStage"] = "Rejected in hub review";
      this.ticket["role"] = ["TI"];
      if (
        this.previousIssues.length > 0 &&
        this.ticket.category[1] !== "Preventive Services"
      ) {
        this.ticket.formData["Repair"] = this.previousIssues;
        this.ticket.formData["subIssue"] = this.previousSubIssues;
      }
      this.ticket.updatedBy = this.ssoId;
      this.ticketViewService
        .updateTicket(this.ticket)
        .subscribe((res: Response<{}>) => {
          if (res.errorCode == 400) {
            alert(res.response["message"]);
          }
          this.viewRejectionReason = false;
          this.rejectionReason = "";
          this.getTicketDetails();
        });
    } else {
      return alert("Can only update category for Ticket Created stage");
    }
  }
  setType(type) {
    this.selectedType = null;
    this.typeList = [];
    this.typesListMap[type.value].forEach((type) => {
      let obj = {
        label: type,
        value: type,
      };
      this.typeList.push(obj);
    });
    this.resetSlotAndIncidentDateTime();
  }

  onCloseCategory() {
    this.selectedCategory = null;
    this.selectedType = null;
    this.typeList = [];
    this.resetSlotAndIncidentDateTime();
  }
  resetSlotAndIncidentDateTime() {
    this.showSelectSlot = false;
    this.showIncidentTimestamp = false;
    this.selectedIncidentDateTime = null;
    this.selectSlotList = [];
    this.selectedSlot = null;
  }

  changeReason(reason) {
    this.reasonList = reason["label"];
  }

  getReasonList() {
    this.ticketViewService.getReasonList().subscribe((res) => {
      if (!res["response"] || !res["response"].length) return;
      this.reasonListData = res["response"][0]["reasonsList"];
      this.categoriesList = res["response"][0]["categoriesList"];
      this.typesListMap = res["response"][0]["typesListMap"];
    });
  }

  getLocalDate(date) {
    if (!date) {
      return "";
    }
    return this.localeService.getDate(date);
  }
  getDate24Hours(date) {
    if (!date) {
      return "";
    }
    return this.localeService.getDate24Hours(date);
  }

  isDate(date) {
    if (date === "") {
      return date;
    }
    if (typeof date == "string") {
      return date;
    }
    let epoch = Number(date);
    let newDate;
    newDate = this.localeService.getDate(epoch);
    if (newDate == "Invalid Date") {
      return date;
    } else {
      newDate = moment(newDate).format("MMM Do YYYY, h:mm:ss A");
      return newDate;
    }
  }

  showRejectionReason() {
    this.viewRejectionReason = true;
  }

  checkSubissues() {
    this.issues = [];
    this.subIssues = [];
    this.repair_data = [];
    this.previousSubIssues = [];
    if (
      this.ticket.formData["subIssue"] &&
      this.ticket.formData["subIssue"].length > 0
    ) {
      for (let i = 0; i < this.ticket.formData["subIssue"].length; i++) {
        this.issues.push(
          "(" +
            this.ticket.formData["subIssue"][i].issueId +
            ")" +
            this.ticket.formData["subIssue"][i].issueName
        );
        this.repair_data.push(this.ticket.formData["subIssue"][i].issueName);
        const obj = {
          issueId: this.ticket.formData["subIssue"][i].issueId,
          issueName: this.ticket.formData["subIssue"][i].issueName,
          subIssues: this.ticket.formData["subIssue"][i].subIssues,
        };
        if (
          this.ticket.formData["subIssue"][i].subIssues &&
          this.ticket.formData["subIssue"][i].subIssues.length > 0
        ) {
          for (
            let j = 0;
            j < this.ticket.formData["subIssue"][i].subIssues.length;
            j++
          ) {
            let issueIdData = "";
            if (this.ticket.formData["subIssue"][i].issueId) {
              issueIdData =
                "(" + this.ticket.formData["subIssue"][i].issueId + ")";
            }
            this.subIssues.push(
              issueIdData +
                "" +
                this.ticket.formData["subIssue"][i].subIssues[j].subIssueName
            );
            this.ticket.formData["subIssue"][i].issueId = "";
          }
        }
        this.previousSubIssues.push(obj);
      }
      this.previousIssues = this.repair_data;
      this.ticket.formData["Repair"] = this.issues;
      this.ticket.formData["subIssue"] = this.subIssues;
    }
  }

  showOdometerPopup() {
    if (this.ticket.formData.KM) {
      if (this.odometerReading < this.ticket.formData.KM - 749) {
        this.showOdometerDialog = true;
      } else {
        this.submitStageForm();
      }
    }
  }

  ticketAction(data, action) {
    this.changeRequest = true;

    if (action === "Accept") {
      this.ticketBody = {
        actionTakenBy: localStorage.getItem("ssoId"),
        ticketId: data.ticketId,
        action: "Accept",
      };
      return;
    }
    if (action === "Reject") {
      this.ticketBody = {
        actionTakenBy: localStorage.getItem("ssoId"),
        ticketId: data.ticketId,
        action: "Reject",
      };
      return;
    }
  }

  resolveTicket() {
    this.ticketViewService
      .resolveTicket(this.ticketBody)
      .subscribe((res: Response<[]>) => {
        if (res.errorCode === 200) {
          this.changeRequest = false;
          this.disableButton = true;

          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Ticket Successfully updated",
          });
          this.ticketViewService
            .getDetailsForTicketId(this.ticketId)
            .subscribe((res: Response<[]>) => {
              this.ticket = res.response;
              this.latestRemark = this.getLatestRemark(this.ticket);
            });

          return;
        }
        if (res.errorCode !== 200) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.errorMessage,
          });
          this.ticketViewService
            .getDetailsForTicketId(this.ticketId)
            .subscribe((res: Response<[]>) => {
              this.ticket = res.response;
              this.latestRemark = this.getLatestRemark(this.ticket);
            });

          return;
        }
      });
  }

  closePopup() {
    this.showOdometerDialog = false;
  }
  closeTicket() {
    this.showOdometerDialog = false;
    this.closeOdometerDialog = true;
    this.closeOdometerMessage = "This ticket has been closed";
    this.submitStageForm();
  }
  ngOnDestroy() {
    this.previousIssues = [];
    this.previousSubIssues = [];
  }
  getFormData() {
    if (this.localeService.isLocaleUAE()) return;
    this.ticketViewService.getMaintenanceData().subscribe((res: any) => {
      this.maintenanceList = [];
      res.response.maintenanceDriverList.forEach((element) => {
        this.maintenanceList.push({
          label: element.firstName + " " + element.lastName,
          name: element.firstName + " " + element.lastName + " - " + element.id,
          value: element,
        });
      });
      this.workshopList = [];
      res.response.workShopList.forEach((element) => {
        this.workshopList.push({
          label: element.workshopName,
          value: element,
        });
      });
    });
  }
  onDropdownIconClick(event: string): void {
    if (event === "trash") {
      this.maintenanceDp = "";
      this.calendarDisable = true;
      this.workshopForm.get("maintenanceDp").setValue("");
      this.workshopForm.get("pickupDateTime").setValue("");
      this.workshopForm.get("pickupDateTime").clearValidators();
      this.workshopForm.get("pickupDateTime").updateValueAndValidity();
      this.pickupDriverForm.get("maintenanceDp").setValue("");
      this.pickupDriverForm.get("pickupDateTime").setValue("");
      this.pickupDriverForm.get("pickupDateTime").clearValidators();
      this.pickupDriverForm.get("pickupDateTime").updateValueAndValidity();
    }
    this.checkDate("");
  }
  selectedMaintenanceDP(event) {
    if (event.value) {
      this.calendarDisable = false;
      this.maintenanceDp = event.value;
      this.workshopForm
        .get("pickupDateTime")
        .setValidators([Validators.required]);
      this.pickupDriverForm
        .get("pickupDateTime")
        .setValidators([Validators.required]);
    } else {
      this.workshopForm.get("pickupDateTime").clearValidators();
      this.pickupDriverForm.get("pickupDateTine").clearValidators();
    }
    this.workshopForm.get("pickupDateTime").updateValueAndValidity();
    this.pickupDriverForm.get("pickupDateTime").updateValueAndValidity();
  }
  submitForm(): void {
    if (this.workshopForm.valid) {
      this.selectedServiceCenter = this.workshopForm.value.workshop;
      this.selectedMaintenanceDp = this.workshopForm.value.maintenanceDp;
      this.selectedDateTime = this.workshopForm.value.pickupDateTime;
      this.submitStageForm();
    } else {
      console.log("Form is invalid. Please check the required fields.");
    }
  }

  submitPickUpForm(): void {
    if (this.pickupDriverForm.valid) {
      this.selectedPickupDriver = this.pickupDriverForm.value.maintenanceDp;
      this.selectedPickupTime = this.pickupDriverForm.value.pickupDateTime;
      this.submitStageForm();
    } else {
    }
  }
  closePickUpDriverDialog() {
    this.assignPickUpDriverDialog = false;
    this.doManageState(false);
  }
  editPickupDriver(data, stage) {
    let selectedDp;
    let timeStamp;
    this.patchWorkshopValues(data, stage);
    this.doManageState(false);
    this.assignPickUpDriverDialog = true;
    if (data.pickUpDriverName) {
      selectedDp = this.maintenanceList.find((workshop) => {
        const trimmedLabel = workshop.label.trim().toLowerCase();
        const trimmedDataName = data.pickUpDriverName.trim().toLowerCase();
        if (trimmedLabel == trimmedDataName) {
          return workshop;
        }
      });
    }

    if (data.returningScheduleTimestamp) {
      timeStamp = this.localeService.getDate(data.returningScheduleTimestamp);
    }
    if (selectedDp) {
      this.calendarDisable = false;
    }
    this.pickupDriverForm.patchValue({
      maintenanceDp: selectedDp,
      pickupDateTime: timeStamp,
    });
    if (selectedDp) {
      this.maintenanceDp = selectedDp.value;
    }
    this.openStageForm(stage, false);
    this.doManageState(false);
  }
  editWorkshopDialog(data, stage) {
    this.doManageState(false);
    this.showDialogCarSentToWorkshop = true;
    this.patchWorkshopValues(data, stage);
  }
  patchWorkshopValues(data, stage) {
    let selectedDp;
    let timeStamp;

    let selectedWorkshop;
    selectedWorkshop = this.workshopList.find((workshop) => {
      const trimmedLabel = workshop.label.trim().toLowerCase();
      const trimmedDataName = data.workshopName
        ? data.workshopName.trim().toLowerCase()
        : "";
      if (trimmedLabel == trimmedDataName) {
        return workshop;
      }
    });
    selectedWorkshop = this.workshopList.find((workshop) => {
      const trimmedLabel = workshop.value.id;
      let trimmedDataName = data.workshopId ? data.workshopId : "";
      if (data.workshopId == null) {
        trimmedDataName = data.id;
      }
      if (trimmedLabel == trimmedDataName) {
        return workshop;
      }
    });

    if (data.driverName) {
      selectedDp = this.maintenanceList.find((workshop) => {
        const trimmedLabel = workshop.label.trim().toLowerCase();
        const trimmedDataName = data.driverName
          ? data.driverName.trim().toLowerCase()
          : "";
        if (trimmedLabel == trimmedDataName) {
          return workshop;
        }
      });
    }
    if (data.scheduleTimestamp) {
      timeStamp = this.localeService.getDate(data.scheduleTimestamp);
    }
    if (selectedDp) {
      this.calendarDisable = false;
    }

    this.workshopForm.patchValue({
      workshop: selectedWorkshop,
      maintenanceDp: selectedDp,
      pickupDateTime: timeStamp,
    });
    if (selectedDp) {
      this.maintenanceDp = selectedDp.value;
    }
    this.doManageState(false);
    this.openStageForm(stage, false);
  }
  openPickUpDialog(data, stage) {
    this.patchWorkshopValues(data, stage);
    this.openStageForm("Workshop pickup driver assigned", true);
    this.doManageState(false);
    this.pickupDriverForm.reset();
    this.assignPickUpDriverDialog = true;
    this.showDialogCarSentToWorkshop = false;
  }
  checkTime(inDate) {
    inDate = moment(inDate).format("MMM Do YYYY, h:mm:ss A");
    return inDate;
  }

  checkDate(event) {
    if (event) {
      this.workshopForm
        .get("maintenanceDp")
        .setValidators([Validators.required]);
      this.pickupDriverForm
        .get("maintenanceDp")
        .setValidators([Validators.required]);
    } else {
      this.workshopForm.get("maintenanceDp").clearValidators();
      this.pickupDriverForm.get("maintenanceDp").clearValidators();
    }
    this.workshopForm.get("maintenanceDp").updateValueAndValidity();
    this.pickupDriverForm.get("maintenanceDp").updateValueAndValidity();
  }

  getDriverPenalty() {
    this.ticketViewService
      .getDriverPenaltyDetails(this.ticket.leaseDailyStatsId, this.penaltyType)
      .subscribe((res: any) => {
        if (res.code != 200) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.errorMessage,
          });
          return;
        }
        if (res.response === null || res.response === undefined) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No data found for driver penalty details",
          });
        }
        if (res.code === 200) {
          this.driverPenaltyDetails = res.response;
          if (this.penaltyType === "LATE_DUTY_START") {
            this.reversalPenaltyMessage =
              "This is a valid penalty as the Driver Partner started the duty at " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.actualDutyStartTime
              ) +
              ", the allowed duty start time was " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.allowedDutyStartTime
              ) +
              " and the driver was " +
              this.convertSecondsToMinutes(
                this.driverPenaltyDetails.actualDutyStartTime,
                this.driverPenaltyDetails.allowedDutyStartTime
              ) +
              " minutes late to start the duty";
          } else if (this.penaltyType === "LATE_DUTY_END") {
            this.reversalPenaltyMessage =
              "This is a valid penalty as the Driver Partner did key handover at " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.actualDutyEndTime
              ) +
              ", the allowed duty end time was " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.allowedDutyEndTime
              ) +
              " and the driver was " +
              this.convertSecondsToMinutes(
                this.driverPenaltyDetails.actualDutyEndTime,
                this.driverPenaltyDetails.allowedDutyEndTime
              ) +
              " minutes late to end the duty";
          } else if (this.penaltyType === "EARLY_DUTY_STOP") {
            this.reversalPenaltyMessage =
              "This is a valid penalty as the Driver Partner ended the duty at " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.actualDutyEndTime
              ) +
              ", the official duty end time was " +
              this.convertEpochMillisToTime(
                this.driverPenaltyDetails.officialDutyEndTime
              ) +
              " and the driver was " +
              this.convertSecondsToMinutes(
                this.driverPenaltyDetails.officialDutyEndTime,
                this.driverPenaltyDetails.actualDutyEndTime
              ) +
              " minutes early to end the duty";
          }
          if (res.response.creditedBy && this.reversalTicketUpdate)
            this.updateTmsTicket();
        }
      }),
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail:
            "An error occurred while fetching driver lease details. Please try again.",
        });
      };
  }

  convertEpochMillisToTime(epochMillis) {
    const date = this.localeService.getDate(epochMillis);
    return date ? moment(date).format("HH:mm") : "";
  }
  convertSecondsToMinutes(seconds1, seconds2) {
    let seconds = 0;
    if (seconds1 && seconds2) {
      seconds = seconds1 - seconds2;
      seconds = seconds ? Math.round(seconds / 60000) : seconds;
    }
    return seconds;
  }

  showSlab() {
    if (
      this.driverPenaltyDetails.earlyDutyStopPenalties &&
      this.driverPenaltyDetails.penaltyName === "EARLY_DUTY_STOP"
    ) {
      this.getPenaltySlabData(this.driverPenaltyDetails.earlyDutyStopPenalties);
    } else if (
      this.driverPenaltyDetails.lateDutyEndPenalties &&
      this.driverPenaltyDetails.penaltyName === "LATE_DUTY_END"
    ) {
      this.getPenaltySlabData(this.driverPenaltyDetails.lateDutyEndPenalties);
    } else if (
      this.driverPenaltyDetails.lateDutyStartPenalties &&
      this.driverPenaltyDetails.penaltyName === "LATE_DUTY_START"
    ) {
      this.getPenaltySlabData(this.driverPenaltyDetails.lateDutyStartPenalties);
    }
  }

  getPenaltySlabData(penaltiesDetails) {
    if (penaltiesDetails === null || penaltiesDetails === undefined) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while fetching driver penalty slab. Please try again.",
      });
    }
    try {
      this.penaltyData = [];
      Object.entries(penaltiesDetails)
        .sort(([a], [b]) => {
          const [aStart, aEnd] = a.split("-").map(Number);
          const [bStart, bEnd] = b.split("-").map(Number);
          return aStart - bStart || aEnd - bEnd;
        })
        .forEach(([key, value]) => {
          const slab = key;
          this.penaltyData.push({
            slabRange: slab,
            penaltyAmount: `₹${value}`,
          });
        });
      this.displayDialog = true;
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while processing showSlab response.",
      });
    }
    this.displayDialog = true;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode == 0) {
      return false;
    }
    return true;
  }

  onInputChange(newValue: string) {
    try {
      this.reversedDuration = newValue;

      if (newValue === "") {
        this.penaltyNote = "";
        this.checkAmt = true;
      }

      if (newValue == undefined || newValue == null || newValue == "") {
        return;
      }

      let penaltyData = {
        reversePenaltyDuration: newValue,
        penaltyAmount: this.driverPenaltyDetails.penaltyAmount,
        penaltyDuration: this.driverPenaltyDetails.penaltyDuration,
        dailyStatsId: this.driverPenaltyDetails.dailyStatsId,
        penaltyType: this.driverPenaltyDetails.penaltyName,
      };

      this.ticketViewService.getDriverPenaltySlab(penaltyData).subscribe(
        (res: any) => {
          try {
            if (this.reversedDuration == "") {
              return;
            }

            this.reverseAmount = res.response.amount;

            if (newValue > this.driverPenaltyDetails.penaltyDuration) {
              this.checkAmt = true;
              this.penaltyNote =
                "Note - Reversed penalty duration is more than the actual penalty duration.";
              return;
            }
            if (
              res.response.amount > 0 &&
              ["LOSS_OF_MBG", "EXTRA_LEASE_COST"].includes(
                this.driverPenaltyDetails.penaltyName
              )
            ) {
              this.checkAmt = false;
              this.penaltyNote = `Note - ${res.response.amount} Rs will be refunded`;
            } else if (res.response.amount > 0) {
              this.checkAmt = false;
              this.penaltyNote =
                "Note - The updated penalty slab will be " +
                res.response.penaltySlab +
                " Min and " +
                res.response.amount +
                " Rs will be refunded.";
            } else {
              this.checkAmt = true;
              this.penaltyNote =
                "Note - Penalty amount shall remain the same, and Zero rupees will be refunded.";
            }
          } catch (error) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail:
                "An error occurred while processing onInputChange response.",
            });
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail:
              "An error occurred while fetching onInputChange. Please try again.",
          });
        }
      );
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "An unexpected error occurred. Please try again.",
      });
    }
  }

  refundAmount() {
    try {
      if (
        this.driverPenaltyDetails.penaltyName != "ABSENT" &&
        (this.applicablePenaltyDurationInMins == null ||
          this.applicablePenaltyDurationInMins == undefined ||
          this.applicablePenaltyDurationInMins == "")
      ) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill Applicable Penalty Duration",
        });
        return;
      }
      if (
        this.driverPenaltyDetails.penaltyName != "ABSENT" &&
        this.applicablePenaltyDurationInMins == "0"
      ) {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "You cannot fill 0 min",
        });
        return;
      }

      let wordCounts = this.penaltyReason
        ? this.penaltyReason.split(/\s+/).length
        : 0;
      if (
        this.driverPenaltyDetails.penaltyName !== "ABSENT" &&
        wordCounts < 24
      ) {
        this.penaltyReasonValidated = true;
        return;
      }
      this.penaltyReasonValidated = false;
      let disbursalTime = moment(this.disbursalTimestamp).valueOf();
      let reqBody = {
        amount: this.driverPenaltyDetails.penaltyAmount,
        type: "CREDIT",
        reason: "LEASE_PENALTY_REFUND: " + this.penaltyReason,
        occurrenceTimestamp: this.driverPenaltyDetails.penaltyDate,
        disbursalTimestamp: disbursalTime,
        driverName: this.driverPenaltyDetails.driverName,
        driverId: this.driverPenaltyDetails.driverId,
        otherFlagsObj: {
          leaseDriverDailyStatsId: this.driverPenaltyDetails.dailyStatsId,
          penaltyType: this.driverPenaltyDetails.penaltyName,
          applicablePenaltyDurationInMins: this.applicablePenaltyDurationInMins,
          reversalAmount: this.reverseAmount,
          penaltyDuration: this.penaltyDuration,
        },
        reverseMbg: this.reverseMbgCheckbox,
      };
      this.spinner.show();
      this.ticketViewService.saveCreditDebitData(reqBody).subscribe(
        (res: any) => {
          try {
            this.spinner.hide();
            if (res.errorCode == 200) {
              this.reset();
              this.messageService.add({
                severity: "success",
                summary: "Info",
                detail: "Data saved successfully",
              });
              this.reversalTicketUpdate = true;
              this.getDriverPenalty();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: res.errorMessage,
              });
            }
          } catch (error) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail:
                "An error occurred while processing refundAmount response.",
            });
          }
        },
        (err) => {
          this.spinner.hide();
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `Error refunding amount: ${err}`,
          });
        }
      );
      this.display = false;
      this.driverIdToPenalty = null;
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "An unexpected error occurred. Please try again.",
      });
    }
  }

  updateTmsTicket() {
    if (
      this.penaltyType === "LATE_DUTY_START" ||
      this.penaltyType === "LATE_DUTY_END" ||
      this.penaltyType === "EARLY_DUTY_STOP"
    ) {
      let Penalty_Reversal_Reason =
        "LEASE_PENALTY_REFUND: " + this.penaltyReason;
      let req = {
        ticketNo: this.ticket.ticketId,
        action: "REVERSED",
        creditedBy: this.driverPenaltyDetails.creditedBy,
        stageActionData: [{ Penalty_Reversal_Reason }],
      };
      this.ticketViewService
        .updateTmsTicket(req)
        .subscribe((res: Response<{}>) => {
          if (res.errorCode == 400) alert(res.response["message"]);
        });
    }
  }

  reset() {
    this.driverPenaltyDetails = null;
    this.applicablePenaltyDurationInMins = null;
  }

  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

  isDisabled(): boolean {
    if (
      this.driverPenaltyDetails.creditedAmount >= 0 ||
      this.driverPenaltyDetails.creditedAmount === null
    ) {
      return true;
    } else {
      return false;
    }
  }
  returnAmountToDriver() {
    this.checkAmt = false;
    this.penaltyNote = "";
    this.applicablePenaltyDurationInMins = "";
    this.penaltyReason = "";
    this.penaltyDuration = this.driverPenaltyDetails.penaltyDuration;
    this.driverIdToPenalty = this.driverPenaltyDetails.dailyStatsId;
    this.display = true;
  }

  updateCategory() {
    if (this.ticket.currentStage == "Ticket Created") {
      this.ticket["formData"]["fieldsData"] = {
        ...this.ticket["formData"]["fieldsData"],
        newCategory: this.selectedCategory.label,
        newIssueType: this.selectedType.label,
      };
      this.ticket["currentStage"] = "In-Progress";
      this.ticket["stageData"].push({
        stageName: "In-Progress",
        createdTime: this.localeService.getDate(),
        actionTakenBy: this.cookieService.get("ssoId"),
      });
      this.resetOldTicketType();
      if (
        this.ticket.formData.fieldsData.newIssueType === "Duty Denied Penalty"
      ) {
        if (!this.ticket.formData.fieldsData.incidentTimestamp) {
          this.ticket.formData.fieldsData.incidentTimestamp =
            this.selectedIncidentDateTime.getTime();
        }
        this.getDriverPenaltyDetailsForCategoryChange(
          this.ticket.formData.fieldsData.newIssueType
        );
        if (this.dutyDeniedPenaltyType)
          this.getDriverDutyDeniedPenaltyDetails();
      } else if (
        this.ticket.formData.fieldsData.newIssueType === "Absenteeism Penalty"
      ) {
        if (!this.ticket.formData.fieldsData.incidentTimestamp) {
          this.ticket.formData.fieldsData.incidentTimestamp =
            this.selectedIncidentDateTime.getTime();
        }
        this.getDriverPenaltyDetailsForCategoryChange(
          this.ticket.formData.fieldsData.newIssueType
        );
        if (this.absenteeismPenaltyType) this.getDriverAbsenteeismDetails();
      }
      if (this.showSelectSlot) {
        this.getDriverPenaltyDetailsForCategoryChange(
          this.ticket.formData.fieldsData.newIssueType
        );
        this.getLeaseDailyStatsDetails();
      } else this.updateTmsTicketOnCategoryChange();
    } else {
      return alert("Can only update category for Ticket Created stage");
    }
  }

  updateTmsTicketOnCategoryChange() {
    this.ticketViewService
      .updateTicket(this.ticket)
      .subscribe((res: Response<{}>) => {
        if (res.errorCode == 400) alert(res.response["message"]);
        let tmsPenaltyType = this.ticket.formData.fieldsData.newIssueType;
        this.getDriverPenaltyDetailsForCategoryChange(tmsPenaltyType);
        if (
          this.penaltyType &&
          this.ticket &&
          this.ticket.leaseDailyStatsId &&
          tmsPenaltyType
        ) {
          this.penaltyName = tmsPenaltyType.replace("Penalty", "");
          this.getDriverPenalty();
        }
        this.selectedType = "";
        this.selectedCategory = "";
        this.resetSlotAndIncidentDateTime();
        this.getTicketDetails();
        this.editCategoryView = false;
        this.onCloseCategory();
      });
  }

  resetOldTicketType() {
    this.driverPenaltyDetails = null;
    this.dutyDeniedPenaltyDetails = null;
    this.penaltyType = null;
    this.penaltyName = null;
    this.dutyDeniedPenaltyType = null;
    this.absenteeismPenaltyType = null;
    this.driverAbsenteeismDetails = null;
    this.absentDailyStatsDataSlotOne = null;
    this.absentDailyStatsDataSlotTwo = null;
    this.displayAbsenteeismPenaltyPopup = false;
  }

  getLeaseDailyStatsDetails() {
    if (this.selectedSlot && this.selectedSlot.value) {
      this.ticket.formData.fieldsData.selectSlot = this.selectedSlot.value;
    }
    if (!this.ticket.formData.fieldsData.incidentTimestamp) {
      this.ticket.formData.fieldsData.incidentTimestamp =
        this.selectedIncidentDateTime.getTime();
    }
    if (
      this.ticket &&
      this.ticket.formData &&
      this.ticket.formData.driverId &&
      this.ticket.formData.fieldsData.selectSlot &&
      this.ticket.formData.fieldsData.incidentTimestamp &&
      this.penaltyType
    ) {
      this.ticketService
        .getLeaseDailyStatsDetails(
          this.ticket.formData.driverId,
          this.ticket.formData.fieldsData.selectSlot,
          this.ticket.formData.fieldsData.incidentTimestamp,
          this.penaltyType
        )
        .subscribe((res: any) => {
          if (res.code === 200) {
            this.driverPenaltyDetails = res.response;
            if (res.response !== null && res.response !== undefined) {
              this.ticket.leaseDailyStatsId = this.driverPenaltyDetails.id;
            } else {
              this.ticket.leaseDailyStatsId = null;
            }
            this.updateTmsTicketOnCategoryChange();
          }
        });
    }
  }

  getDriverPenaltyDetailsForCategoryChange(tmsPenaltyType) {
    if (tmsPenaltyType === "Ds8D3niU")
      tmsPenaltyType = "Late Duty Start Penalty";
    else if (tmsPenaltyType === "MaefJQKN")
      tmsPenaltyType = "Late Duty End Penalty";
    else if (tmsPenaltyType === "hvAxNbez")
      tmsPenaltyType = "Early Duty End Penalty";
    else if (tmsPenaltyType === "tysvshty") tmsPenaltyType = "Loss of MBG";
    else if (tmsPenaltyType === "utxaswq") tmsPenaltyType = "Extra Lease Cost";

    switch (tmsPenaltyType) {
      case "Late Duty Start Penalty":
        this.penaltyType = "LATE_DUTY_START";
        break;
      case "Late Duty End Penalty":
        this.penaltyType = "LATE_DUTY_END";
        break;
      case "Early Duty End Penalty":
        this.penaltyType = "EARLY_DUTY_STOP";
        break;
      case "SDeIMGDx":
      case "Duty Denied Penalty":
        this.dutyDeniedPenaltyType = "Driver Denied Duty";
        break;
      case "6O7yWSuq":
      case "Absenteeism Penalty":
        this.absenteeismPenaltyType = "Absenteeism";
        break;
      case "Loss of MBG":
        this.penaltyType = "LOSS_OF_MBG";
        break;
      case "Extra Lease Cost":
        this.penaltyType = "EXTRA_LEASE_COST";
        break;
    }
    if (this.penaltyType)
      this.penaltyName = tmsPenaltyType.replace("Penalty", "");
  }

  setSelectSlotList() {
    if (this.ticket) {
      this.resetSlotAndIncidentDateTime();
      console.log(this.selectedType);

      if (
        this.selectedCategory.value === "Penalty Related Issues" &&
        this.selectedType &&
        (this.selectedType.value === "Late Duty Start Penalty" ||
          this.selectedType.value === "Early Duty End Penalty" ||
          this.selectedType.value === "Late Duty End Penalty" ||
          this.selectedType.value === "Loss of MBG" ||
          this.selectedType.value === "Extra Lease Cost")
      ) {
        this.displaySlotList = true;
        this.showSelectSlot = true;
        this.selectSlotList.push({ label: "Slot-1", value: "Slot-1" });
        this.selectSlotList.push({ label: "Slot-2", value: "Slot-2" });
      }

      if (this.selectedType.value === "Extra Lease Cost") {
        this.displaySlotList = false;
        this.ticket.formData.fieldsData.selectSlot = "Slot-1";
        this.selectedSlot = true;
      }

      if (
        this.showSelectSlot &&
        this.ticket &&
        this.ticket.formData &&
        this.ticket.formData.fieldsData
      ) {
        if (this.ticket.formData.fieldsData.incidentTimestamp) {
          this.selectedIncidentDateTime =
            this.ticket.formData.fieldsData.incidentTimestamp;
        } else {
          this.showIncidentTimestamp = true;
        }
      }

      if (
        this.selectedCategory.value === "Penalty Related Issues" &&
        this.selectedType &&
        (this.selectedType.value === "Duty Denied Penalty" ||
          this.selectedType.value === "Absenteeism Penalty" ||
          this.selectedType.value === "Loss of MBG" ||
          this.selectedType.value === "Extra Lease Cost")
      ) {
        if (
          this.ticket &&
          this.ticket.formData &&
          this.ticket.formData.fieldsData &&
          !this.ticket.formData.fieldsData.incidentTimestamp
        ) {
          this.showIncidentTimestamp = true;
        }
      }
    }
  }

  enableUpdateCategory() {
    if (this.selectedCategory && this.selectedType) {
      if (this.showSelectSlot && !this.selectedSlot) return true;
      if (this.showIncidentTimestamp && !this.selectedIncidentDateTime)
        return true;
      return false;
    }
    return true;
  }

  getResolutionReasons(formId) {
    this.ticketViewService
      .getReasonCodes(formId)
      .subscribe((res: Response<[]>) => {
        let reasonData = res.response["reasons"];
        this.reasonCodeList = reasonData.map((reason: any) => ({
          label: reason.reason_code,
          value: reason.reason_text,
        }));
      });
  }

  getSelectedCode(event) {
    this.resolutionNotes = event.value.value;
    this.showResolution = true;
    this.isBtnDisable = false;
  }

  getFormList() {
    this.formService
      .fetchAllActiveFormsFromProject("driverPartnerTMS")
      .subscribe((res: any) => {
        this.formList = res.response;
      });
  }

  getFormId(nameToSearch: string) {
    const normalizedSearchName = nameToSearch.replace(/\s/g, "").toLowerCase();
    const result = this.formList.find((obj) => {
      const normalizedObjectName = obj.name.replace(/\s/g, "").toLowerCase();
      return normalizedObjectName.includes(normalizedSearchName);
    });
    if (result) {
      this.selectedFormId = result.formId;
    } else {
      console.log("No matching form found.");
    }
  }
  fileHandler(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.showNoIssueSpinner = true;
    if (file) {
      this.ticketViewService.noIssueUploadImage(file).subscribe(
        (res) => {
          this.showNoIssueSpinner = false;
          this.imagesUrl.push(res.response[0]);
        },
        (error) => {}
      );
    }
    let e = event.target as HTMLInputElement;
    e.value = "";
  }

  onUploadClick(): void {
    if (this.imagesUrl.length === 4) {
      return;
    }
    this.fileInput.nativeElement.click();
  }

  carouselDisplay(index) {
    this.finalImagesUrl = [...this.imagesUrl];
    this.finalImagesUrl.unshift(this.finalImagesUrl.splice(index, 1)[0]);
    console.log(index, this.finalImagesUrl);
    this.showCarousel = true;
  }
  deleteNoIssueImage(index) {
    this.imagesUrl.splice(index, 1);
  }
  checkImageLimit() {
    this.showTooltip = this.imagesUrl.length < 4 ? false : true;
  }

  attachmentDisplay(data) {
    this.attachmentUrls = data.files;
    this.showAttachment = true;
  }

  showViewImageAttachments() {
    this.attachmentUrls = this.currentAttachments;
    this.showAttachment = true;
  }

  getDriverDutyDeniedPenaltyDetails() {
    if(this.ticket && this.ticket.formData && this.ticket.formData.driverId && 
      this.ticket.formData.fieldsData && this.ticket.formData.fieldsData.incidentTimestamp){
    this.ticketService
      .getDriverDutyDeniedPenaltyDetails(
        this.ticket.formData.driverId,
        this.ticket.formData.fieldsData.incidentTimestamp
      )
      .subscribe((res: any) => {
        if (res.code != 200) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.errorMessage,
          });
          return;
        }
        if (res.response === null || res.response === undefined) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No data found for driver duty denied penalty details",
          });
        }
        if (res.code === 200 && res.response !== null) {
          this.dutyDeniedPenaltyDetails = res.response;
          if (this.reversalDutyDeniedTicketUpdate) {
            let reason = `DUTY_DENIED_PENALTY_REFUND : (${this.dutyDenied.rideId}) ${this.dutyDeniedPenaltyReason}`;
            this.updateDutyDeniedPenaltyTmsTicket(
              reason,
              this.dutyDeniedCreditedBy
            );
            this.dutyDenied = null;
          }
        }
        this.displayDutyDeniedPenaltyPopup = false;
        this.dutyDeniedPenaltyReason = null;
      }),
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail:
            "An error occurred while fetching driver duty denied penalty. Please try again.",
        });
      };
    }
  }

  isDutyDeniedPenaltyBtnDisabled(dutyDeniedPenalty): boolean {
    if (dutyDeniedPenalty && dutyDeniedPenalty.isPenaltyReversed === false) {
      return false;
    }
    return true;
  }

  nofOfRfsNumber(dutyDeniedPenalty) {
    if (dutyDeniedPenalty && dutyDeniedPenalty.driverRfsDetailResponses) {
      return dutyDeniedPenalty.driverRfsDetailResponses;
    }
  }

  playAudio(url) {
    this.displayAudioDialog = true;
    this.dutyDeniedAudioUrl = url;
  }

  onHidePlayAudio() {
    this.displayAudioDialog = false;
    this.dutyDeniedAudioUrl = null;
  }

  updateDutyDeniedPenaltyTmsTicket(Penalty_Reversal_Reason, creditedBy) {
    let req = {
      ticketNo: this.ticket.ticketId,
      action: "REVERSED",
      creditedBy: creditedBy,
      stageActionData: [{ Penalty_Reversal_Reason }],
    };
    this.ticketViewService
      .updateTmsTicket(req)
      .subscribe((res: Response<{}>) => {
        if (res.errorCode == 400) alert(res.response["message"]);
      });
  }

  returnDutyDeniedAmount(dutyDenied) {
    this.dutyDeniedPenaltyReason = null;
    this.reversalDutyDeniedTicketUpdate = false;
    this.dutyDeniedCreditedBy = null;
    this.dutyDeniedPenaltyReasonValidated = false;
    this.displayDutyDeniedPenaltyPopup = true;
    this.dutyDenied = dutyDenied;
  }

  refundDutyDeniedPenaltyAmount() {
    try {
      let wordCounts = this.dutyDeniedPenaltyReason
        ? this.dutyDeniedPenaltyReason.split(/\s+/).length
        : 0;
      if (wordCounts < 24) {
        this.dutyDeniedPenaltyReasonValidated = true;
        return;
      }
      this.dutyDeniedPenaltyReasonValidated = false;
      let reqBody = {
        rideId: this.dutyDenied.rideId,
        driverId: this.dutyDenied.driverId,
        reason: "DUTY_DENIED_PENALTY_REFUND : " + this.dutyDeniedPenaltyReason,
        recordDate: this.ticket.formData.fieldsData.incidentTimestamp,
      };
      this.spinner.show();
      this.ticketViewService.saveDutyDeniedCreditDebitData(reqBody).subscribe(
        (res: any) => {
          try {
            this.spinner.hide();
            if (res.errorCode == 200) {
              this.reset();
              this.messageService.add({
                severity: "success",
                summary: "Info",
                detail: "Data saved successfully",
              });
              this.reversalDutyDeniedTicketUpdate = true;
              this.dutyDeniedCreditedBy = this.dutyDenied.creditedBy;
              this.getDriverDutyDeniedPenaltyDetails();
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: res.errorMessage,
              });
            }
          } catch (error) {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail:
                "An error occurred while processing duty denied refundAmount response.",
            });
          }
        },
        (err) => {
          this.spinner.hide();
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: `Error duty denied refunding amount: ${err}`,
          });
        }
      );
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail:
          "An unexpected error occurred while refund duty denied. Please try again.",
      });
    }
  }

  getDriverAbsenteeismDetails() {
    this.singleSlot = true;
  if(this.ticket && this.ticket.formData && this.ticket.formData.driverId && 
    this.ticket.formData.fieldsData && this.ticket.formData.fieldsData.incidentTimestamp){
    this.ticketViewService
      .getDriverAbsenteeismPenaltyDetails(
        this.ticket.formData.driverId,
        this.ticket.formData.fieldsData.incidentTimestamp
      )
      .subscribe((res: any) => {
        if (res.code != 200) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: res.errorMessage,
          });
          return;
        }
        if (res.response === null || res.response === undefined) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "No data found for driver penalty details",
          });
        }
        if (res.code === 200) {
          let data = res.response;
          for (let day in data) {
            data[day]["leaseCostPenaltyBreakup"] = JSON.parse(
              data[day]["leaseCostPenaltyBreakup"]
                ? data[day]["leaseCostPenaltyBreakup"]
                : 0
            );
            data[day]["minimumGuaranteePenaltyBreakup"] = data[day][
              "minimumGuaranteePenaltyBreakup"
            ]
              ? JSON.parse(data[day]["minimumGuaranteePenaltyBreakup"])
              : null;
          }
          this.dailyStatsData = data;
          this.dailyStatsData.forEach((element) => {
            if (element.recordDate) {
              let date = this.localeService.getDate(element.recordDate); // The 0 there is the key, which sets the date to the epoch

              element.recordDate =
                (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
                "/" +
                (date.getMonth() > 8
                  ? date.getMonth() + 1
                  : "0" + (date.getMonth() + 1)) +
                "/" +
                date.getFullYear();
            }
            if (element.checkInTimestamp) {
              let date = this.localeService.getDate(element.checkInTimestamp);
              element.checkInTimestamp = date.toLocaleString("en-US");
            }
            if (element.officialDutyStartTimestamp) {
              let date = this.localeService.getDate(
                element.officialDutyStartTimestamp
              );
              element.officialDutyStartTimestamp = date.toLocaleString("en-US");
            }
            if (element.officialDutyEndTimestamp) {
              let date = this.localeService.getDate(
                element.officialDutyEndTimestamp
              );
              element.officialDutyEndTimestamp = date.toLocaleString("en-US");
            }
            if (element.dutyStartTimestamp) {
              let date = this.localeService.getDate(element.dutyStartTimestamp);
              element.dutyStartTimestamp = date.toLocaleString("en-US");
            }
            if (element.dutyEndTimestamp) {
              let date = this.localeService.getDate(element.dutyEndTimestamp);
              element.dutyEndTimestamp = date.toLocaleString("en-US");
            }
            if (element.officialDutyEndTimestampOne) {
              let date = this.localeService.getDate(
                element.officialDutyEndTimestampOne
              );
              element.officialDutyEndTimestampOne =
                date.toLocaleString("en-US");
            }
            if (element.dutyEndTimestampOne) {
              let date = this.localeService.getDate(
                element.dutyEndTimestampOne
              );
              element.dutyEndTimestampOne = date.toLocaleString("en-US");
            }
            element.hasOverlappingHappen =
              element.hasOverlappingHappened == false ||
              element.hasOverlappingHappened == null
                ? "No"
                : "Yes";
            element.leaseCostPenalty = element.leaseCostPenalty.toFixed(2);
            element.leaseCostPenalty = parseFloat(element.leaseCostPenalty);
            element.actualMinimumGuarantee =
              element.actualMinimumGuarantee.toFixed(2);
            element.actualMinimumGuarantee = parseFloat(
              element.actualMinimumGuarantee
            );
            element.minimumGuaranteePenalty =
              element.minimumGuaranteePenalty.toFixed(2);
            element.minimumGuaranteePenalty = parseFloat(
              element.minimumGuaranteePenalty
            );
            if (element.lastRideCreatedTimestamp) {
              let date = this.localeService.getDate(
                element.lastRideCreatedTimestamp
              );
              element.lastRideCreatedTimestamp = date.toLocaleString("en-US");
            }
            if (element.lastRideArrivedTimestamp) {
              let date = this.localeService.getDate(
                element.lastRideArrivedTimestamp
              );
              element.lastRideArrivedTimestamp = date.toLocaleString("en-US");
            }
            if (element.lastRideDropTimestamp) {
              let date = this.localeService.getDate(
                element.lastRideDropTimestamp
              );
              element.lastRideDropTimestamp = date.toLocaleString("en-US");
            }
            if (element.breakDownCreatedTimestamp) {
              let date = this.localeService.getDate(
                element.breakDownCreatedTimestamp
              );
              element.breakDownCreatedTimestamp = date.toLocaleString("en-US");
            }
          });
          if (this.dailyStatsData && this.dailyStatsData.length > 0) {
            this.driverAbsenteeismDetails = this.dailyStatsData[0];
            this.absentDailyStatsDataSlotOne = this.dailyStatsData[0];
            this.singleSlot = this.dailyStatsData.length > 1 ? false : true;
            this.absentDailyStatsDataSlotTwo =
              this.dailyStatsData.length > 1 ? this.dailyStatsData[1] : null;
          }
          if (
            res.code === 200 &&
            res.response !== null &&
            this.reversalAbsentTicketUpdate
          ) {
            let reason = `ABSENT_PENALTY_REFUND : ${this.absenteeismPenaltyReason}`;
            this.updateDutyDeniedPenaltyTmsTicket(
              reason,
              this.driverAbsenteeismDetails.creditedBy
            );
          }
          this.displayAbsenteeismPenaltyPopup = false;
          this.absenteeismPenaltyReason = null;
        }
      });
    }
  }

  getAbsenteeismDetailsOnSlotSwitch(slot: number) {
    if (slot === 2 && this.absentDailyStatsDataSlotTwo) {
      return (this.driverAbsenteeismDetails = this.absentDailyStatsDataSlotTwo);
    }
    return (this.driverAbsenteeismDetails = this.absentDailyStatsDataSlotOne);
  }

  reverseAbsenteeismPenaltyConfirmation() {
    if (
      (this.reverseSEBCheckbox && !this.reverseMbgCheckbox) ||
      (!this.reverseSEBCheckbox && this.reverseMbgCheckbox)
    ) {
      this.displayAbsenteeismConfirmationPopup = true;
      return;
    }
    this.displayAbsenteeismPenaltyPopup = true;
  }

  returnAbsenteeismPenaltyAmount() {
    this.absenteeismPenaltyReason = "";
    this.displayAbsenteeismPenaltyPopup = true;
    this.displayAbsenteeismConfirmationPopup = false;
    this.absenteeismPenaltyReasonValidated = false;
    this.selectedSlotValidated = true;
    this.reversalAbsentTicketUpdate = false;
    this.selectedSlotOne = false;
    this.selectedSlotTwo = false;
    this.selectedBothSlots = false;
  }

  isAbsenteeismReversalBtnDisabled(): boolean {
    if (
      (this.driverAbsenteeismDetails.leaseCostPenalty === null ||
        this.driverAbsenteeismDetails.leaseCostPenalty <= 0) &&
      (this.driverAbsenteeismDetails.minimumGuaranteePenalty === null ||
        this.driverAbsenteeismDetails.minimumGuaranteePenalty <= 0)
    ) {
      return true;
    }

    this.reverseSlotOne = false;
    this.reverseSlotTwo = false;
    this.reverseBothSlots = false;
    if (
      this.absentDailyStatsDataSlotOne &&
      this.absentDailyStatsDataSlotOne.attendanceState === "ABSENT" &&
      this.absentDailyStatsDataSlotOne.absentPenaltyReversed === false
    )
      this.reverseSlotOne = true;
    if (
      this.absentDailyStatsDataSlotTwo &&
      this.absentDailyStatsDataSlotTwo.attendanceState === "ABSENT" &&
      this.absentDailyStatsDataSlotTwo.absentPenaltyReversed === false
    ) {
      this.reverseSlotTwo = true;
      this.reverseBothSlots = this.reverseSlotOne ? true : false;
    }
    if (this.reverseSlotOne || this.reverseSlotTwo || this.reverseBothSlots)
      return false;
    return true;
  }

  reverseAbsenteeismPenalty() {
    try {
      let wordCounts = this.absenteeismPenaltyReason
        ? this.absenteeismPenaltyReason.split(/\s+/).length
        : 0;
      if (wordCounts < 24) {
        this.absenteeismPenaltyReasonValidated = true;
        return;
      }
      this.absenteeismPenaltyReasonValidated = false;
      if (
        this.selectedSlotOne === false &&
        this.selectedSlotTwo === false &&
        this.selectedBothSlots === false
      ) {
        this.selectedSlotValidated = false;
        return;
      }
      this.selectedSlotValidated = true;
      if (this.selectedBothSlots === false) {
        this.selectedBothSlots =
          this.selectedSlotOne && this.selectedSlotTwo ? true : false;
      }
      let reqBody = {
        driverId: this.driverAbsenteeismDetails.driverId,
        recordDate: this.ticket.formData.fieldsData.incidentTimestamp,
        selectedSlot: this.selectedSlotOne ? 1 : 2,
        reversePenalty: true,
        reverseMBG: this.reverseMbgCheckbox,
        reverseSEB: this.reverseSEBCheckbox,
        reverseReferral: this.reverseSEBCheckbox,
        reverseForBothSlot: this.selectedBothSlots,
        penaltyType: "ABSENT",
        reason: "ABSENT_PENALTY_REFUND : " + this.absenteeismPenaltyReason,
      };
      this.spinner.show();
      this.ticketViewService
        .saveDriverAbsenteeismPenaltyDetails(reqBody)
        .subscribe(
          (res: any) => {
            try {
              this.spinner.hide();
              if (res.errorCode == 200) {
                this.reset();
                this.messageService.add({
                  severity: "success",
                  summary: "Info",
                  detail: "Data saved successfully",
                });
                this.reversalAbsentTicketUpdate = true;
                this.reverseMbgCheckbox = false;
                this.reverseSEBCheckbox = false;
                this.selectedSlotOne = false;
                this.selectedSlotTwo = false;
                this.selectedBothSlots = false;
                this.getDriverAbsenteeismDetails();
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: res.errorMessage,
                });
              }
            } catch (error) {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail:
                  "An error occurred while processing absenteeism refund response.",
              });
            }
          },
          (err) => {
            this.spinner.hide();
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: `Error absenteeism refunding amount: ${err}`,
            });
          }
        );
    } catch (error) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail:
          "An unexpected error occurred while refund absneteeism penalty. Please try again.",
      });
    }
  }

  checkPenaltyType(penaltyName) {
    if (
      penaltyName &&
      (penaltyName === "LOSS_OF_MBG" || penaltyName === "EXTRA_LEASE_COST")
    ) {
      return false;
    }
    return true;
  }

  // in your component
  hasPenaltyName(): boolean {
    return (
      !!this.driverPenaltyDetails.penaltyName &&
      this.driverPenaltyDetails.penaltyName !== "LOSS_OF_MBG" &&
      this.driverPenaltyDetails.penaltyName !== "EXTRA_LEASE_COST"
    );
  }

  toggleRemarkModal(event) {
    this.showRemarkModal = event.closekey;
    if (event.event === "SUCCESS") {
      // show success dailog box and refetch ticket;
      this.getTicketDetails();
      this.closeOdometerDialog = true;
      this.closeOdometerMessage = "Remark has been added.";
    }
  }

  getLatestRemark(ticket) {
    if (!ticket) return null;
    return [...ticket.stageData]
      .reverse()
      .find((s) => s.stageName === "Remark");
  }

  isImage(url: string) {
    return (
      url.endsWith(".jpg") ||
      url.endsWith(".png") ||
      url.endsWith(".svg") ||
      url.endsWith(".jpeg")
    );
  }

  showActionBar() {
    return (
      (this.currentStageData &&
        this.currentStageData.stageName !== "Ticket Closed" &&
        this.currentStageData.stageName !== "Closed" &&
        this.canUserTakeAction) ||
      (this.ticket["category"].includes("Preventive Services") &&
        this.PMSStages.includes(this.ticket.currentStage) &&
        this.canUserTakeAction)
    );
  }

  handleMdFlow() {
    const possibleStates = [
      "Workshop pickup driver assigned",
      "MD arrived at workshop",
      "Ride canceled by MD",
    ];
    return (
      this.ticket.project === "operationsTMS" &&
      possibleStates.includes(this.ticket.currentStage)
    );
  }

  handleCarSentToWorkshopFlow() {
    const possibleStates = [
      "Car sent to workshop",
      "Hub pickup driver assigned",
    ];
    return (
      possibleStates.includes(this.ticket.currentStage) && this.country !== "AE"
    );
  }

  showStageCTA(stage: string) {
    const restrictedStages = ["Assign pickup driver", "Close Ticket"];
    const result =
      this.roleName === this.currentStageData["role"][0] &&
      !restrictedStages.includes(stage);

    this.isAnyStageCtaVisible = this.isAnyStageCtaVisible || result;
    return result;
  }

  handleAssignPickupDriver(stage: string) {
    const result =
      stage === "Assign pickup driver" &&
      this.roleName === "MSE" &&
      this.ticket &&
      this.ticket.centerLocation &&
      !this.ticket.centerLocation.pickUpDriverId;

    this.isPickupDriverCtaVisible = this.isPickupDriverCtaVisible || result;
    return result;
  }

  showStageCloseButtonForShiftIncharge(stage: string) {
    const result = stage === "Close Ticket" && this.roleName === "Shift Incharge";

    this.showShiftInchargeCloseCta = this.showShiftInchargeCloseCta || result;
    return result;
  }

  showMaintananceDriverAcceptReject() {
    const restrictedStages = ["Resolved", "Closed", "Discard"];
    return (
      this.isFormExist && !restrictedStages.includes(this.ticket.currentStage)
    );
  }

  showSendByCraneEditFlow() {
    return (
      this.ticket.project === "operationsTMS" &&
      this.currentStageData &&
      this.currentStageData["role"].includes(this.roleName) &&
      this.canUserTakeAction
    );
  }

  isTicketFormEditable() {
    const parentCondition = this.PMSStages.includes(this.ticket.currentStage);
    const editTicketForm =
      this.allowedToEdit && this.ticket.currentStage !== "Ticket Closed";
    const showCreateRideButton =
      this.ticket.currentStage === "Driver Assigned" &&
      this.ticket.isRideAssigned === false;
    const showSendDriverToWorkshop =
      this.ticket.currentStage === "Vehicle at workshop" &&
      this.ticket.assignedDriverName;
    const showRecievedCarToWorkshop =
      this.ticket.currentStage === "Vehicle Received";

    return (
      parentCondition &&
      (editTicketForm ||
        showCreateRideButton ||
        showSendDriverToWorkshop ||
        showRecievedCarToWorkshop)
    );
  }

  isMarkedIssueNotFound() {
    const { stageName = '' } = this.ticket.stageData[this.ticket.stageData.length - 1];
    return (
      this.ticket.currentStage === 'No Issue Found' || 
      (this.ticket.currentStage === 'Due for hub review' && stageName === 'No Issue Found')
    );
  }

  doesRoleHasAccess() {
    const isNotHubManager = this.roleName !== "Hub Manager";

    const anyActionableItemIsVisible =
      (this.handleMdFlow() && this.roleName === "MSE" && this.ticket.centerLocation.pickUpDriverId) ||
      (this.handleCarSentToWorkshopFlow() && this.roleName === "MSE") ||
      (!this.hideData && this.isAnyStageCtaVisible) ||
      (!this.hideData && this.isPickupDriverCtaVisible) ||
      (!this.hideData && this.showShiftInchargeCloseCta) ||
      (this.showOdometer && this.hideData) || // maintanance driver odometer check
      this.showMaintananceDriverAcceptReject() ||
      (this.showSendByCraneEditFlow() && this.currentStageData && this.currentStageData.stageName === "Sent by crane") ||
      this.isTicketFormEditable();

    return (
      isNotHubManager && this.showActionBar() && anyActionableItemIsVisible && !this.isMarkedIssueNotFound()
    );
  }
}
