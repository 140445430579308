import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TicketViewService } from 'src/app/services/ticket-view.service';
import { OdometerAndInsuranceState } from 'src/app/utils/state/odometerAndInsurance.service';
import { TicketCreationService } from 'src/app/utils/state/ticketCreation.service';

import * as _ from 'lodash';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-insurance-modal',
  templateUrl: './insurance-modal.component.html',
  styleUrls: ['./insurance-modal.component.css']
})
export class InsuranceModalComponent implements OnInit {

  @Input()
  visible: boolean = false;

  /**
   * Using variable to store values inserted previously in ticket form
   */
  formData: any = {};

  @Output()
  handleOnHide = new EventEmitter();

  @Output()
  handleSubmit = new EventEmitter();

  /**
   * Three input fields for incident description, time and place
   */
  form: FormGroup;

  medicalProofs: string[] = [];
  medicalProofsObject: { [key: string]: { loader: boolean, document: string } } = {
    'driver': { loader: false, document: '' },
    'rider': { loader: false, document: '' },
    'third party': { loader: false, document: '' }
  };

  accidentMedia: string[] = [];

  uploadModalVisibility: boolean = false;

  /**
   * Documents related to insurance like RC, Insurance and previously uploaded fitness
   */
  insuranceDetails: any = {};

  /**
   * Documents related to driver like DLs etc.
   */
  driverDocuments: any = {};

  uploadingFlags = {
    fir: false,
    other: false,
  };
  firDocuments = {
    fir: '',
    other: '',
  };

  maxDate = new Date();

  isUAE: boolean = false;
  liability: string = '';

  constructor(
    private fb: FormBuilder, 
    private odoInsurance: OdometerAndInsuranceState,
    private localeService: LocalService,
    private ticketViewService: TicketViewService,
    private msgService: MessageService,
    private ticketCreationState: TicketCreationService,
  ) {
    this.form = this.fb.group({
      incidentDescription: ['', Validators.required],
      dateTime: [null, Validators.required],
      place: ['', Validators.required],
    });
  }

  async populateForm() {
    if (!this.formData) return;

    this.form.patchValue({
      incidentDescription: this.formData.get('incidentStory'),
      dateTime: new Date(this.formData.get('Date Of Issue')),
      place: this.formData.get('place')
    });

    const formData = JSON.parse(this.formData.get('formData') || '{}');
    this.medicalProofs = formData.injuryTo || [];

    ['driver', 'rider', 'third party'].forEach(mp => {
      this.medicalProofsObject[mp] = { 
        loader: false, 
        document: _.get(formData, `medicalProofs[${mp}].document`, ''),
      };
    });

    this.accidentMedia = formData['Incident Photo/Video'] || [];
    this.firDocuments.fir = formData['firDocument'];
    this.firDocuments.other = formData['otherDocument'];
    this.liability = formData['liabilityFlagging'];

    this.odoInsurance.driverDetails$.subscribe(driverDocuments => {
      this.driverDocuments = driverDocuments || {};
    });

    this.odoInsurance.odoInsure$.subscribe(({ response }) => {
      this.insuranceDetails = response || {};
    });
  }

  ngOnInit() {
    this.ticketCreationState.formDataState$.subscribe(res => {
      this.formData = res;
      this.populateForm();
    });

    this.isUAE = this.localeService.isLocaleUAE();
    this.uploadModalVisibility = this.isUAE;
  }

  getDriverIdAndName() {
    const formData = JSON.parse(this.formData.get('formData') || '{}');
    const attr = formData['Driver'];

    const seperation = (attr || '').split(',');
    return { punchId: seperation[1], name: seperation[0] };
  }


  onHide(event) {
    this.handleOnHide.emit(event);
    this.visible = false;
  }

  toggleUploadModalVisibility(value: boolean) {
    this.uploadModalVisibility = value;
  }

  getUploadFiles(files: string[]) {
    this.accidentMedia = files;
  }

  closeModal() {
    this.visible = false;
    this.handleOnHide.emit(false);
  }

  async uploadFIR(event, documentType: 'fir' | 'other') {
    this.uploadingFlags[documentType] = true;
    const fir = [...event.target.files];

    try {
      const { data, errorMessage, code } = await this.ticketViewService.uploadDocuments(fir).toPromise();
      if (code !== 200) throw new Error(errorMessage);

      this.firDocuments[documentType] = data.pop();

      this.uploadingFlags[documentType] = false;
    } catch (error) {
      console.error(error);
      this.uploadingFlags[documentType] = false;
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please try again after sometime',
      });
    }
  }

  async uploadMedicalProof(event, party: 'driver' | 'rider' | 'third party') {
    const files = [...event.target.files];
    this.medicalProofsObject[party].loader = true;

    try {
      const { data, errorMessage, code } = await this.ticketViewService.uploadDocuments(files).toPromise();
      if (code !== 200) throw new Error(errorMessage);
      this.medicalProofsObject[party].loader = false;
      
      this.medicalProofsObject[party].document = data.pop();
    } catch (error) {
      console.error(error);
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please try again after sometime',
      });
      this.medicalProofsObject[party].loader = false;
    }
  };

  handleModalSubmit() {
    const liabilityConditon = this.isUAE ? !this.liability : false;
    if (!this.form.valid || liabilityConditon) {
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter valid details',
      });

      return;
    }

    if (this.isUAE && !this.firDocuments.fir) {
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Police report upload is mandatory',
      });
      return;
    }

    if (!this.medicalProofs.every(proof => this.medicalProofsObject[proof].document)) {
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please add remaining medical documents',
      });
      return;
    }
    
    const formData = Object.assign({}, JSON.parse(this.formData.get('formData') || '{}'));

    const proofs = {};
    this.medicalProofs.forEach(mp => {
      proofs[mp] = this.medicalProofsObject[mp];
    });

    formData['Incident Photo/Video'] = this.accidentMedia.filter(m => m);
    formData['firDocument'] = this.firDocuments.fir;
    formData['medicalProofs'] = proofs;
    formData['injuryTo'] = this.medicalProofs;
    formData['vehicleRC'] = this.insuranceDetails.rcURL;
    formData['insuranceDocument'] = this.insuranceDetails.insuranceURL;

    if (this.isUAE) {
      formData['otherDocument'] = this.firDocuments.other;
      formData['liabilityFlagging'] = this.liability;
    }
    
    if (this.driverDocuments['Driving License (Front)'] && this.driverDocuments['Driving License (Front)'].url) {
      formData['driverLicense'] = this.driverDocuments['Driving License (Front)'].url || '';
    }

    formData.incidentStory = this.form.get('incidentDescription').value;
    formData['Date Of Issue'] = this.form.get('dateTime').value;
    formData['place'] = this.form.get('place').value;

    this.formData.set('incidentStory', this.form.get('incidentDescription').value);
    this.formData.set('Date Of Issue', this.form.get('dateTime').value);
    this.formData.set('place', this.form.get('place').value);
    this.formData.set('formData', JSON.stringify(formData));

    this.handleSubmit.emit(this.formData);
  }

  getKeyFromFormData(key: string) {
    if (!this.formData) return '{}';
    return JSON.parse(this.formData.get('formData') || '{}')[key];
  }

  removeFile(key: string) {
    if (Object.keys(this.firDocuments).includes(key)) {
      this.firDocuments[key] = '';
      return;
    }
  }

}
