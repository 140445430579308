import {
  Component,
  ViewContainerRef,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  Validators,
} from "@angular/forms";
import { Field } from "../../model/field.interface";
import { FieldConfig } from "../../model/field-config.interface";
import { MaintenaceTicketsListComponent } from "src/app/components/maintenace-tickets-list/maintenace-tickets-list.component";
import { FormServiceService } from "../../../form-service.service";
import { LocalService } from "src/app/services/local.service";
@Component({
  selector: "file-upload",
  template: `
    <div style="position: relative;">
      <p style="color: white; font-size: 12px;padding-left: 2%;">Upload File</p>
      <!-- <label [attr.for]="inputId"> {{label}} </label> <br/>
      <input
      class="file-input"
        type="file"
        [attr.id]="inputId"
        [attr.accept]="accept"
        [attr.multiple]="multiple"
      />-->
      <span class="btn btn-primary btn-file">
        <input
          type="file"
          [attr.id]="inputId"
          [attr.accept]="accept"
          [attr.multiple]="multiple"
        />
      </span>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() inputId;
  @Input() accept = "image/*,audio/*,video/*";
  @Input() multiple = false;
  @Input() className = "dynamic_form column";
  @Input() label;
  @Input()
  onChange: Function;
  private file: File | any | null = null;
  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.length > 1 ? event : event && event.item(0);
    this.onChange(file);
    this.file = file;
  }
  constructor(private host: ElementRef<HTMLInputElement>) {}
  writeValue(value: null) {
    this.host.nativeElement.value = "";
    this.file = null;
  }
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }
  registerOnTouched(fn: Function) {}
}
// Input-file
@Component({
  selector: "form-input-file",
  styleUrls: ["form-items.component.css"],
  template: `
    <div
      class="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <file-upload
        [formControlName]="config.name || 'files'"
        [label]="config.label"
        [inputId]="config.htmlId"
        [multiple]="config.multiple"
        [className]="config.className"
        [accept]="config.accept"
      >
      </file-upload>
    </div>
  `,
})
export class FormInputFileComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
// Input-Group
@Component({
  selector: "form-input-group",
  styleUrls: ["form-items.component.css"],
  template: `
    <div
      [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <div
        *ngIf="config.htmlType === 'checkbox'"
        class=" Input_Group_Container  row "
      >
        <label *ngIf="config.label" class="checkbox"
          >{{ config.label }}
          <span
            *ngIf="config.validations && config.validations.required"
            class="required_field"
            >*</span
          >
        </label>
        <ng-container *ngFor="let checkbox of config.options">
          <p-checkbox
            [name]="config.groupName ? config.groupName : config.name"
            [formControl]="group.controls[config.name]"
            [label]="checkbox.label"
            [value]="checkbox.value"
            [inputId]="checkbox._id"
            [styleClass]="checkbox.className"
          >
          </p-checkbox>
        </ng-container>
      </div>
      <div
        *ngIf="config.htmlType === 'radio'"
        class="Input_Group_Container row"
      >
        <label *ngIf="config.label" class="radio-button"
          >{{ config.label }}
          <span
            *ngIf="config.validations && config.validations.required"
            class="required_field"
            >*</span
          >
        </label>
        <p-radioButton
          *ngFor="let radio of config.options"
          [name]="config.groupName ? config.groupName : config.name"
          [value]="radio.value"
          [formControlName]="config.name"
          [label]="radio.label"
          [styleClass]="radio.className"
        >
        </p-radioButton>
      </div>
    </div>
  `,
})
export class FormInputGroupComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
// Input
@Component({
  selector: "form-input",
  styleUrls: ["form-items.component.css"],
  template: `
    <div
      [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <label *ngIf="config.label" [attr.for]="config.htmlId"
        >{{ config.label }}
        <span
          *ngIf="config.validations && config.validations.required"
          class="required_field"
          >*</span
        >
      </label>
      <input
        pInputText
        id="input"
        [attr.id]="config.htmlId"
        [attr.type]="config.htmlType"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name"
      />
    </div>
  `,
})
export class FormInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
// Select | Dropdown | Multiselect
@Component({
  selector: "form-select",
  styleUrls: ["form-items.component.css"],
  template: ` <div
    [class]="config.className ? config.className : 'dynamicForm'"
    [formGroup]="group"
  >
    <label *ngIf="config.label" for="config.htmlId"
      >{{ config.label }}
      <span
        *ngIf="config.validations && config.validations.required"
        class="required_field"
        >*</span
      >
    </label>
    <ng-container *ngIf="config.multiple">
      <p-multiSelect
        appendTo="body"
        [options]="config.options"
        [formControlName]="config.name"
        [defaultLabel]="config.optionLabel"
        filter="true"
        filterBy="label, value"
        [styleClass]="
          config.styleClass ? config.styleClass : 'dynamicForm singleSelect'
        "
        [panelStyleClass]="config.styleClass ? config.styleClass : ''"
      >
      </p-multiSelect>
    </ng-container>
    <ng-container *ngIf="!config.multiple">
      <p-dropdown
        appendTo="body"
        [options]="config.options"
        [formControlName]="config.name"
        filter="true"
        filterBy="label, value"
        (onChange)="onVehicleChange($event)"
        [placeholder]="config.placeholder ? config.placeholder : config.label"
        [styleClass]="config.styleClass ? config.styleClass : ''"
        [panelStyleClass]="
          config.styleClass ? config.styleClass : 'dynamicForm multiSelect'
        "
      >
      </p-dropdown>

      <div *ngIf="isOdometer && config.label == 'Vehicle Number'" class="mt-2">
        <label for=""
          >Odometer Reading <span class="required_field">*</span></label
        >
        <input
          pInputText
          [(ngModel)]="odometerReading"
          [ngModelOptions]="{ standalone: true }"
          placeholder="Odometer Reading"
          class="inputField"
          required
          (input)="setOdometerReading($event)"
          (keydown)="validateOdometerReading($event)"
        />
        <div
          *ngIf="group.controls['odometerReading']?.invalid"
          class="text-danger mt-2"
        >
          Please enter valid odometer reading
        </div>
      </div>

      <app-maintenace-tickets-list
        *ngIf="isShowTickets"
        (onVehicleChange)="onVehicleChange($event)"
      ></app-maintenace-tickets-list>
    </ng-container>
  </div>`,
})
export class FormSelectComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  odometerReadingValue: any;
  selectedVehicle: String;
  isShowTickets: boolean = true;
  isOdometer: boolean = false;
  @ViewChild(MaintenaceTicketsListComponent, { static: false })
  maintenaceComp: MaintenaceTicketsListComponent;
  odometerReading;
  country: string;
  constructor(private formService: FormServiceService) {
    this.country = localStorage.getItem('country');
  }
  onVehicleChange(event) {
    let formsList = [
      "Missing Docs",
      "Tag",
      "Missing Tags",
      "Challan Date",
      "Car Consumable",
      "Missing Item",
      "Type of Add Ons",
    ];
    let renderedForms = Object.keys(this.group.controls);
    formsList.forEach((form) => {
      if (renderedForms.indexOf(form) > -1) {
        this.isShowTickets = false;
      }
    });
    this.selectedVehicle = event.value;
    if (this.maintenaceComp) {
      this.isOdometer = true;
      this.formService
      .getOdometerReading(this.selectedVehicle)
      .subscribe((res) => {
        this.odometerReading =
          res.response && res.response.comparisonValue
            ? res.response.comparisonValue
            : 0;
        this.group.addControl(
          "odometerReading",
          new FormControl(this.odometerReadingValue, [
            Validators.required,
            Validators.pattern(/^\d+(\.\d+)?$/),
            Validators.max(999999),
          ])
          
        );
        this.group.controls["odometerReading"].setValue(this.odometerReading);
      });
      this.maintenaceComp.getOpenTicketsByVehicle(this.selectedVehicle);
    }
  }
  setOdometerReading(event) {
    this.group.controls["odometerReading"].setValue(event.target.value);
  }
  validateOdometerReading(event) {
    const charCode = event.which ? event.which : event.keyCode;
    const input = event.target.value;  

    if (
      (charCode >= 48 && charCode <= 57) ||
      charCode == 8 ||
      charCode == 46 ||
      charCode == 190 ||
      charCode == 37 ||
      charCode == 39
    ) {
      if (input.length === 0 && charCode == 48) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
// TextArea
@Component({
  selector: "form-textarea",
  styleUrls: ["./form-items.component.css"],
  template: ` <div
    [class]="config.className ? config.className : 'dynamicForm'"
    [formGroup]="group"
  >
    <label
      *ngIf="config.label"
      [attr.for]="config.htmlId"
      style="font-size: 12px;"
      >{{ config.label }}
      <span
        *ngIf="config.validations && config.validations.required"
        class="required_field"
        >*</span
      >
    </label>
    <textarea
      [attr.id]="config.htmlId"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name"
      style="width: 100%; font-size: 14px;"
      pInputTextarea
    ></textarea>
  </div>`,
})
export class FormTextAreaComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
// Button
@Component({
  selector: "form-button",
  styleUrls: ["form-items.component.css"],
  template: `
    <div
      [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group"
    >
      <button
        class="nextSubmit"
        pButton
        [disabled]="config.disabled"
        label="{{ config.label }}"
        type="submit"
      ></button>
    </div>
  `,
})
export class FormButtonComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  constructor() {
    console.log(this.config, "FormButtonComponent");
  }
}
// Date-time
@Component({
  selector: "form-date-time",
  styleUrls: ["form-items.component.css"],
  template: `
    <div
      [class]="config.className ? config.className : 'dynamicForm dateTime'"
      [formGroup]="group"
    >
      <label [for]="config.htmlId" *ngIf="config.label"
        >{{ config.label }}
        <span
          *ngIf="config.validations && config.validations.required"
          class="required_field"
          >*</span
        >
      </label>
      <ng-container *ngIf="config.htmlType === 'datetime'">
        <p-calendar
          [formControlName]="config.name"
          hourFormat="12"
          [showTime]="true"
          [showIcon]="true"
          [maxDate]="maxDate"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
      <ng-container *ngIf="config.htmlType === 'date'">
        <p-calendar
          [formControlName]="config.name"
          [showIcon]="true"
          [maxDate]="maxDate"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
      <ng-container *ngIf="config.htmlType === 'time'">
        <p-calendar
          [formControlName]="config.name"
          hourFormat="12"
          [timeOnly]="true"
          [showIcon]="true"
          [inputId]="config.htmlId"
          appendTo="body"
        ></p-calendar>
      </ng-container>
    </div>
  `,
})
export class FormDateTimeComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  today;
  maxDate;

  constructor(private localeService: LocalService) {
    this.today = localeService.getDate();
    this.maxDate = new Date(this.today.setHours(24, 0, 0));
  }
}
