<div *ngIf="isUserLoggedIn === true" class="top-nav">
  <div class="logo">
    <img class="logo-image" src="assets/logo2.png" />
  </div>
  <div class="user-options">
    <div>
      <div *ngIf="projectList && projectList.length > 1" class="cell-table">
        <span class="ui-float-label">
          <p-dropdown [options]="projectList" [(ngModel)]="selectedProject" (onChange)="onSelectProject($event.value)"
            optionLabel="label">
          </p-dropdown>
        </span>
      </div>
      <div *ngIf="isHubRequired" class="cell-table">
        <div class="cell-table">
          <span class="ui-float-label">
            <p-dropdown [options]="zoneList" [(ngModel)]="selectedZoneValue" (onChange)="onSelectZone($event.value)"
              optionLabel="label">
            </p-dropdown>
          </span>
        </div>
        <div class="cell-table">
          <span class="ui-float-label">
            <p-dropdown [options]="hubList" [(ngModel)]="selectedHubListValue" (onChange)="onSelectType($event.value, true)"
              optionLabel="label">
            </p-dropdown>
          </span>
        </div>
        <!-- <span class="ui-float-label">
          <p-dropdown [options]="hubList" [(ngModel)]="selectedHub" (onChange)="onSelectType($event.value)"
            optionLabel="label">
          </p-dropdown>
        </span> -->
      </div>
      <a (click)="logout()" class="padding: 0px;margin: 0px;"><i class="fa fa-sign-out" aria-hidden="true"></i></a>
    </div>
  </div>
</div>
<div *ngIf="isUserLoggedIn === true" class="top-container">
  <div class="left-content" [ngClass]="{ 'short-width': onlyIcons === true }">
    <ul>
      <li class="nav-title">
        <a (click)="toggleIcons()"><i class="fa fa-bars" aria-hidden="true"></i><span
            *ngIf="onlyIcons === false">Menu</span></a>
      </li>
      <!-- <li>
        <a href="http://{{hubUrl}}/dashboard" routerLinkActive="menu-active"><i class="fa fa-tachometer"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Dashboard</span></a>
      </li> -->
      <!-- <li>
        <a href="http://{{hubUrl}}/assets" routerLinkActive="menu-active"><i class="fa fa-briefcase"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Hub Assets</span></a>
      </li> -->
      <li *ngIf="checkPermission('MENU_KEY_HANDOVER')">
        <a href="http://{{ hubUrl }}/navigate/key-handover" routerLinkActive="menu-active"><i class="fa fa-key"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Key Handover</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_VEHICLES_HUB')">
        <a href="http://{{ hubUrl }}/navigate/fleet" routerLinkActive="menu-active"><i class="fa fa-car"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Vehicles</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_DRIVERS')">
        <a href="http://{{ hubUrl }}/navigate/drivers" routerLinkActive="menu-active"><i class="fa fa-user"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Drivers</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_CASHBOOK')">
        <a href="http://{{ hubUrl }}/navigate/cashbook" routerLinkActive="menu-active"><i class="fa fa-money"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Cashbook</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_REPORT_HUB')">
        <a href="http://{{ hubUrl }}/navigate/reports" routerLinkActive="menu-active"><i class="fa fa-archive"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Reports</span></a>
      </li>
      <!-- <li>
        <a href="http://{{hubUrl}}/slots" routerLinkActive="menu-active"><i class="fa fa-clone"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Slots</span></a>
      </li> -->

      <li *ngIf="checkPermission('MENU_ATTENDANCE')">
        <a href="http://{{ hubUrl }}/navigate/attendance" routerLinkActive="menu-active"><i class="fa fa-calendar-o"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Attendance</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_LIVE_ATTENDANCE')">
        <a href="http://{{ hubUrl }}/navigate/live-attendance" routerLinkActive="menu-active"><i
            class="fa fa-calendar-o" aria-hidden="true"></i><span *ngIf="onlyIcons === false">Live Attendance</span></a>
      </li>
      <!--SSO ID of Admin and Finance Team Only-->
      <!-- <li *ngIf="checkPermission('MENU_SETTLEMENT')">
        <a href="http://{{ hubUrl }}/settlement" routerLinkActive="menu-active"
          ><i class="fa fa-balance-scale" aria-hidden="true"></i
          ><span *ngIf="onlyIcons === false">Settlement</span></a
        >
      </li> -->
      <!-- <li>
        <a href="http://{{hubUrl}}/incentives" routerLinkActive="menu-active"><i class="fa fa-book"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Incentives</span></a>
      </li> -->
      <!-- <li>
        <a href="http://{{hubUrl}}/hub" routerLinkActive="menu-active"><i class="fa fa-cog" aria-hidden="true"></i><span
            *ngIf="onlyIcons === false"> Hub</span></a>
      </li> -->
      <!-- <li>
        <a href="http://{{hubUrl}}/shift" routerLinkActive="menu-active"><i class="fa fa-cog"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false"> Shift</span></a>
      </li> -->
      <li *ngIf="checkPermission('MENU_LEASE')">
        <a href="http://{{ hubUrl }}/navigate/lease" routerLinkActive="menu-active"><i class="fa fa-cog"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false"> Lease</span></a>
      </li>
      <!-- <li><a routerLink="/tickets" routerLinkActive="menu-active"><i class="fa fa-tags" aria-hidden="true"></i><span
            *ngIf="onlyIcons===false">Tickets <span style="color: #ef5350;">(Old)</span></span></a></li> -->
      <li *ngIf="checkPermission('MENU_TICKETS')">
        <a routerLink="/ticket/view" [ngClass]="{'menu-active': checkIfTicketIsActive('operations')}"><i class="fa fa-tags" aria-hidden="true"></i><span
            *ngIf="onlyIcons === false">Tickets <span style="color: #43a047">(New)</span></span></a>
      </li>
      <li *ngIf="checkPermission('MENU_ISSUES_TAT') && localeSerice.isLocaleIndia()" >
        <a routerLink="/issues/tat" routerLinkActive="menu-active"
          ><i class="fa fa-clock-o" aria-hidden="true"></i
          ><span *ngIf="onlyIcons === false"
            >Issue Wise TAT </span
          ></a
        >
      </li>

      <li *ngIf="checkPermission('MENU_CREATE_TICKETS')">
        <a routerLink="/ticket/heirarchy" routerLinkActive="menu-active"><i class="fa fa-plus-square"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Create Tickets</span></a>
      </li>
      <li *ngIf="checkPermission('MENU_CONFIGURE_TICKETS')">
        <a routerLink="dynamic/form/create" routerLinkActive="menu-active"><i class="fa fa-cog"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Configure Tickets</span></a>
      </li>
      <!-- <li><a routerLink="/ticket/config-create" routerLinkActive="menu-active"><i class="fa fa-ticket"
            aria-hidden="true"></i><span *ngIf="onlyIcons===false">Create Config (delete)</span></a></li>
      <li><a routerLink="/ticket/config-active" routerLinkActive="menu-active"><i class="fa fa-ticket"
            aria-hidden="true"></i><span *ngIf="onlyIcons===false">Active config (delete)</span></a></li> -->

      <li *ngIf="checkPermission('MENU_SERVICE_TICKETS')">
        <a routerLink="/ticket/serviceticket" routerLinkActive="menu-active"><i class="fa fa-ticket"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Service Ticket</span></a>
      </li>

      <li *ngIf="checkPermission('MENU_DRIVER_ISSUES')">
        <a routerLink="/driver/issues/view" [ngClass]="{'menu-active': checkIfTicketIsActive('driver')}"><i class="fa fa-tags"
            aria-hidden="true"></i><span *ngIf="onlyIcons === false">Driver Issues <span
              style="color: #43a047">(New)</span></span></a>
      </li>

      <li *ngIf="hasRiderAcesss" (click)="handleProjectChange()" style="cursor: pointer;">
        <a  [ngClass]="{'menu-active': checkIfTicketIsActive('rider')}"><i class="fa fa-tags" aria-hidden="true"></i><span
            *ngIf="onlyIcons === false">Rider Support Ticket </span></a>
      <li *ngIf="checkPermission('PMS_SERVICE_HISTORY_INCIDENTS')">
        <a href="http://{{ axleUrl }}/navigate/pms-and-alignment/pms-summary" routerLinkActive="menu-active"><i
            class="fa fa-wrench" aria-hidden="true"></i><span *ngIf="onlyIcons === false"> PMS & Alignment</span></a>
      </li>

    </ul>
  </div>
  <div class="right-content">
    <p-dialog styleClass="custom-dialog" [(visible)]="displayCreateTicket" (onHide)="onHide()">
      <app-create-ticket-global *ngIf="displayCreateTicket"></app-create-ticket-global>
    </p-dialog>

    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="isUserLoggedIn === false" class="login-container">
  <!---->
  <div class="d-flex justify-content-center align-items-center" style="width: 100%">
    <div style="padding-top: 60px">
      <form #loginForm="ngForm" action="" (submit)="login()" method="POST" style="height: 100%; margin: auto">
        <div class="login-panel">
          <div style="display: flex; margin: 85px 5px 40px 5px">
            <img style="height: 100px; margin: auto" src="assets/logo2.png" />
          </div>
          <!-- <div style="margin: auto;">
            <button onclick="location.href='http://localhost:4200/dynamic/form/create'" class="btn btn-primary"> + Create Ticket</button>
          </div>
          <div class="line"><br></div> -->
          <div class="login-header">Login</div>
          <div style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-bottom: 20% !important;
              margin: auto;
            ">
            <span class="ui-float-label">
              <input id="float-input-username" [ngModelOptions]="{standalone: true}" type="text" size="30"
                [(ngModel)]="username" pInputText [disabled]="isProduction">
              <label for="float-input-username">Username</label>
            </span>
            <span class="ui-float-label">
              <input id="float-input-password" [ngModelOptions]="{standalone: true}" type="password" size="30"
                [(ngModel)]="password" pInputText [disabled]="isProduction">
              <label for="float-input-password">Password</label>
            </span>
            <button type="submit" class="submit" [disabled]="isProduction">Submit</button>
            <p style="color: #ae2e2e;font-size: 14px; padding: 0px;margin-bottom: 5px; text-align: center;">*Please use Google to login</p>
            <div id="gbutton" #gbutton class="google-button"></div>

            <div class="clear-cookies">
              <div class="text">
                If you are <b>facing problems</b> during login try this
              </div>
              <button pButton pRipple class="cta" (click)="clearCookiesManually()" type="button">
                <img src="assets/cookies.svg" />
                <div>Clear Cookies</div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>