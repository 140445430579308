import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { LocalService } from "src/app/services/local.service";
import { TicketViewService } from "src/app/services/ticket-view.service";
import { FileUploadComponent } from "../../file-upload/file-upload.component";

interface OptionsType {
  label: String;
  value: String | Number;
  remarkId: String | Number;
  role: String[];
  __v?: String | Number;
  _id?: String;
}

type OnCloseEventType = "SUCCESS" | "CLOSE";

@Component({
  selector: "app-remarks",
  templateUrl: "./remarks.component.html",
  styleUrls: ["./remarks.component.css"],
})
export class RemarksComponent implements OnInit {
  @ViewChild(FileUploadComponent, null) fileUploadComp: FileUploadComponent;

  @Input()
  showModal: boolean = false;

  @Input()
  ticket: { [key: string]: any } = null;

  @Input()
  getTicketDetails: Function = () => {};

  @Output()
  onClose = new EventEmitter<{
    closekey: boolean;
    event: OnCloseEventType;
  }>();

  remarkForm: FormGroup;
  reasonOptions: OptionsType[] = [];
  uploadedFiles: File[] = [];

  updationInProgress: boolean = false;

  constructor(
    private fb: FormBuilder,
    private ticketViewService: TicketViewService,
    private localeService: LocalService,
    public msg: MessageService,
  ) {
    this.remarkForm = this.fb.group({
      reason: ["", Validators.required],
      remarks: ["", Validators.required],
      file: [[]],
    });

    this.populateRemarks();
  }

  populateRemarks() {
    const roleName = localStorage.getItem("roleName");
    this.ticketViewService.getRemarks({ roleName }).subscribe(
      ({ response, code, errorMessage }) => {
        if (code !== 200) {
          console.error(errorMessage);
          this.msg.add({
            severity: "error",
            summary: "Error",
            detail: errorMessage,
          });
          return;
        }

        this.reasonOptions = response.filter((reason) =>
          reason.role.includes(roleName)
        );
      },
      (err) => {
        console.error(err);
        this.msg.add({
          severity: "error",
          summary: "Error",
          detail: 'Please try again after sometime.',
        });
      }
    );
  }

  ngOnInit() {}

  closeModal(event: OnCloseEventType = "CLOSE") {
    this.showModal = false;
    this.remarkForm.reset();
    this.uploadedFiles = [];
    this.fileUploadComp.resetFiles();
    this.onClose.emit({ closekey: this.showModal, event });
  }

  populateTicket() {
    const ssoId = localStorage.getItem("ssoId");
    const roleName = localStorage.getItem("roleName");

    const { value: reason } = this.remarkForm.get("reason").value;
    const remarks = this.remarkForm.get("remarks").value;
    const files = this.remarkForm.get("file").value;

    const remarkStage = {
      stageName: "Remark",
      stageActionData: [
        {
          reason,
          remark: remarks || "",
          actionStage: this.ticket.currentStage,
          platform: "Incident",
          role: roleName,
        },
      ],
      createdTime: new Date().getTime(),
      actionTakenBy: Number(ssoId),
      files,
    };

    const payload = Object.assign({}, this.ticket);
    payload.stageData.push(remarkStage);
    payload.currentStage = 'Remark';

    return payload;
  }

  async saveRemark() {
    try {
      this.updationInProgress = true;

      if (this.uploadedFiles.length) {
        const { data, errorMessage, code } = await this.ticketViewService
          .uploadRemarkDocuments(this.uploadedFiles)
          .toPromise();
        if (code !== 200) throw new Error(errorMessage);

        this.remarkForm.patchValue({ file: data });
      }
      const payload = this.populateTicket();

      this.ticketViewService.updateTicket(payload).subscribe(
        ({ code, errorMessage }: any) => {
          this.getTicketDetails();
          this.updationInProgress = false;

          if (code !== 200) {
            console.error(errorMessage);
            this.msg.add({
              severity: "error",
              summary: "Error",
              detail: errorMessage,
            });

            return;
          };
          this.closeModal("SUCCESS");
        },
        (error) => {
          console.error(error);
          this.updationInProgress = false;
        }
      );
    } catch (error) {
      this.updationInProgress = false;
      console.error(error);
      this.msg.add({
        severity: "error",
        summary: "Error",
        detail: 'Please try again after sometime.',
      });
    }
  }

  getUploadedFiles(files: File[]) {
    if (!files.length) return;
    this.uploadedFiles = files;
  }
}
