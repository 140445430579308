import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TicketListStateService {
    constructor() {
        this.ticketBehavior = new BehaviorSubject(null);
        this.ticketListState = this.ticketBehavior.asObservable();
    }
    set(data) {
        this.ticketBehavior.next(data);
    }
}
TicketListStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketListStateService_Factory() { return new TicketListStateService(); }, token: TicketListStateService, providedIn: "root" });
