import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { LocalService } from 'src/app/services/local.service';
import { UpdateTicketPayload, TicketViewService } from 'src/app/services/ticket-view.service';
import { OdometerAndInsuranceState } from 'src/app/utils/state/odometerAndInsurance.service';
import { TicketCreationService } from 'src/app/utils/state/ticketCreation.service';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.css']
})
export class InsuranceDetailsComponent implements OnInit {

  @Input()
  ticket: any = {};

  @Input()
  doesRoleHasAccess: boolean = false;

  @Input()
  previousSubIssues = [];

  @Input()
  isInsuranceApplicable: string = null;

  @Output()
  onEditComplete = new EventEmitter();

  openViewModal: boolean = false;

  openEditModal: boolean = false;

  roleName: string;

  isUAE: boolean = false;

  constructor(
    private ticketViewService: TicketViewService,
    private msgService: MessageService,
    private ticketCreationState: TicketCreationService,
    private odoInsurance: OdometerAndInsuranceState,
    private localeService: LocalService,
  ) { }

  ngOnInit() {
    this.roleName = localStorage.getItem('roleName');
    this.isUAE = this.localeService.isLocaleUAE();
  }

  showVehicleDetails() {
    const { insuranceDetails = {} } = this.ticket;
    return insuranceDetails.vehicleDetails;
  }

  getKey(path: string) {
    return _.get(this.ticket, path);
  }

  getVehicleRC() {
    return this.getKey('insuranceDetails.vehicleDetails.vehicleRc');
  }

  vehicleHasInsurance() {
    return this.getKey('insuranceDetails.vehicleDetails.isInsuranceApplicable') && this.getKey('insuranceDetails.vehicleDetails.insurance');
  }

  vehicleHasFitness() {
    return this.getKey('insuranceDetails.vehicleDetails.fitness') && this.getKey('insuranceDetails.vehicleDetails.fitnessCertificate');
  }

  showDriverDocuments() {
    const { insuranceDetails = {} } = this.ticket;
    return insuranceDetails.driverDetails;
  }

  driverHasDL() {
    return this.getKey('insuranceDetails.driverDetails.driverLicense');
  }

  showAccidentDetails() {
    const { insuranceDetails = {} } = this.ticket;
    return insuranceDetails.accidentalDetails;
  }

  getAccidentFiles() {
    const files = (this.getKey('insuranceDetails.accidentalDetails.files') || [])
      .filter(file => !!file);
    return files.concat(new Array(4 - files.length).fill(null));
  }

  showLegalDocuments() {
    return this.getKey('insuranceDetails.legalDetails');
  }

  getFir() {
    return this.getKey('insuranceDetails.legalDetails.firDoc');
  }

  getOtherFirDocument() {
    return this.getKey('insuranceDetails.legalDetails.otherDocument');
  }

  getLiabilityFlagging() {
    return this.getKey('insuranceDetails.legalDetails.liabilityFlagging');
  }

  getMedicalProof(key: string) {
    return this.getKey(`insuranceDetails.legalDetails.${key}`);
  }

  toggleViewModal(state: boolean) {
    this.openViewModal = state;
  }

  checkForMSE() {
    if (this.isUAE) return true;
    return this.roleName === 'MSE';
  }

  editInsurance() {
    this.odoInsurance.setDriverDetails({ 'Driving License (Front)': { url: this.driverHasDL() } });
    const response = {
      rcURL: this.getVehicleRC(),
      insuranceURL: this.vehicleHasInsurance(),
    };
    this.odoInsurance.setOdometerAndInsurance({ response });

    const newFormData = this.ticketViewService.formatTicketDataForInsuranceModal(this.ticket);
    this.ticketCreationState.set(newFormData);
  }

  async sendInsuranceDocument() {
    try {
      const ssoId = localStorage.getItem('ssoId');

      if (!this.ticket || !this.ticket.ticketId) {
        throw new Error("Ticket not found, Please try again later");
      }
  
      const payload: UpdateTicketPayload = {
        role: this.roleName,
        ssoId,
        stageName: 'Email',
        ticketId: this.ticket.ticketId,
      };

      const { response, errorCode }: any = await this.ticketViewService.updateTicketV2(payload).toPromise();
      if (errorCode !== 200) {
        throw new Error("Error in api")
      } else {
        this.msgService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Email sent !!',
        });
      }
    }
    catch (error) {
      console.error(error);
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Not able to send document, Please try again later',
      });
    }
  }

  toggleInsuranceModal(state: boolean) {
    if (state) this.editInsurance();
    this.openEditModal = state;
  }

  hideInsuranceModal() {
    this.toggleInsuranceModal(false);
    this.ticketCreationState.set(null);
  }

  async handleInsuranceSubmit(form) {
    const payload = Object.assign({}, this.ticket);
    const formData = JSON.parse(form.get('formData') || '{}');

    const driverMedicalProof = _.get(formData, 'medicalProofs.driver.document', '');
    const riderMedicalProof = _.get(formData, 'medicalProofs.rider.document', '');
    const thirdPartyMedicalProof = _.get(formData, 'medicalProofs["third party"].document', '');
    
    _.set(payload, 'formData["Date Of Issue"]', formData['Date Of Issue']);
    _.set(payload, 'formData.place', formData['place']);
    _.set(payload, 'formData.incidentStory', formData['incidentStory']);
    _.set(payload, 'insuranceDetails.accidentalDetails.files', formData['Incident Photo/Video']);
    _.set(payload, 'insuranceDetails.accidentalDetails.incidentStory', formData['incidentStory']);
    _.set(payload, 'insuranceDetails.accidentalDetails.place', formData['place']);
    _.set(payload, 'insuranceDetails.accidentalDetails.injuryTo', formData['injuryTo']);
    _.set(payload, 'insuranceDetails.legalDetails.driverMedicalProof', driverMedicalProof);
    _.set(payload, 'insuranceDetails.legalDetails.riderMedicalProof', riderMedicalProof);
    _.set(payload, 'insuranceDetails.legalDetails.thirdPartyMedicalProof', thirdPartyMedicalProof);
    _.set(payload, 'formData.subIssue', this.previousSubIssues);
    _.set(payload, 'insuranceDetails.legalDetails.firDoc', formData['firDocument']);

    if (this.isUAE) {
      _.set(payload, 'insuranceDetails.legalDetails.otherDocument', formData['otherDocument']);
      _.set(payload, 'insuranceDetails.legalDetails.liabilityFlagging', formData['liabilityFlagging']);
    }

    if (this.isInsuranceApplicable === 'No') {
      _.set(payload, 'insuranceDetails.vehicleDetails.isInsuranceApplicable', "Yes");
      _.set(payload, 'formData.isInsuranceApplicable', 'Yes');
    }

    try {
      const { response, errorCode }: any = await this.ticketViewService.updateTicket(payload).toPromise();
      if (errorCode !== 200) {
        throw new Error();
      } else {
        this.msgService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Ticket Updated !!',
        });
      }

      this.onEditComplete.emit(response);
      this.hideInsuranceModal();
    } catch (error) {
      console.error(error);
      this.msgService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Cannot update details at the moment.',
      });
    }
  }

  isInsuranceAcceptedOrRejected() {
    const stages = ['INSURANCE_ACCPETED', 'INSURANCE_REJECTED'];
    return stages.includes(this.isInsuranceApplicable);
  }

  isAddInsuranceCtaDisabled() {
    if (!this.isUAE) {
      return !this.doesRoleHasAccess || this.roleName !== 'MSE' || this.isInsuranceAcceptedOrRejected()
    }
    return this.roleName !== 'Shift Incharge';
  }
}
