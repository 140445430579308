import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
export class InsuranceDetailsComponent {
    constructor(ticketViewService, msgService, ticketCreationState, odoInsurance, localeService) {
        this.ticketViewService = ticketViewService;
        this.msgService = msgService;
        this.ticketCreationState = ticketCreationState;
        this.odoInsurance = odoInsurance;
        this.localeService = localeService;
        this.ticket = {};
        this.doesRoleHasAccess = false;
        this.previousSubIssues = [];
        this.isInsuranceApplicable = null;
        this.onEditComplete = new EventEmitter();
        this.openViewModal = false;
        this.openEditModal = false;
        this.isUAE = false;
    }
    ngOnInit() {
        this.roleName = localStorage.getItem('roleName');
        this.isUAE = this.localeService.isLocaleUAE();
    }
    showVehicleDetails() {
        const { insuranceDetails = {} } = this.ticket;
        return insuranceDetails.vehicleDetails;
    }
    getKey(path) {
        return _.get(this.ticket, path);
    }
    getVehicleRC() {
        return this.getKey('insuranceDetails.vehicleDetails.vehicleRc');
    }
    vehicleHasInsurance() {
        return this.getKey('insuranceDetails.vehicleDetails.isInsuranceApplicable') && this.getKey('insuranceDetails.vehicleDetails.insurance');
    }
    vehicleHasFitness() {
        return this.getKey('insuranceDetails.vehicleDetails.fitness') && this.getKey('insuranceDetails.vehicleDetails.fitnessCertificate');
    }
    showDriverDocuments() {
        const { insuranceDetails = {} } = this.ticket;
        return insuranceDetails.driverDetails;
    }
    driverHasDL() {
        return this.getKey('insuranceDetails.driverDetails.driverLicense');
    }
    showAccidentDetails() {
        const { insuranceDetails = {} } = this.ticket;
        return insuranceDetails.accidentalDetails;
    }
    getAccidentFiles() {
        const files = (this.getKey('insuranceDetails.accidentalDetails.files') || [])
            .filter(file => !!file);
        return files.concat(new Array(4 - files.length).fill(null));
    }
    showLegalDocuments() {
        return this.getKey('insuranceDetails.legalDetails');
    }
    getFir() {
        return this.getKey('insuranceDetails.legalDetails.firDoc');
    }
    getOtherFirDocument() {
        return this.getKey('insuranceDetails.legalDetails.otherDocument');
    }
    getLiabilityFlagging() {
        return this.getKey('insuranceDetails.legalDetails.liabilityFlagging');
    }
    getMedicalProof(key) {
        return this.getKey(`insuranceDetails.legalDetails.${key}`);
    }
    toggleViewModal(state) {
        this.openViewModal = state;
    }
    checkForMSE() {
        if (this.isUAE)
            return true;
        return this.roleName === 'MSE';
    }
    editInsurance() {
        this.odoInsurance.setDriverDetails({ 'Driving License (Front)': { url: this.driverHasDL() } });
        const response = {
            rcURL: this.getVehicleRC(),
            insuranceURL: this.vehicleHasInsurance(),
        };
        this.odoInsurance.setOdometerAndInsurance({ response });
        const newFormData = this.ticketViewService.formatTicketDataForInsuranceModal(this.ticket);
        this.ticketCreationState.set(newFormData);
    }
    sendInsuranceDocument() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const ssoId = localStorage.getItem('ssoId');
                if (!this.ticket || !this.ticket.ticketId) {
                    throw new Error("Ticket not found, Please try again later");
                }
                const payload = {
                    role: this.roleName,
                    ssoId,
                    stageName: 'Email',
                    ticketId: this.ticket.ticketId,
                };
                const { response, errorCode } = yield this.ticketViewService.updateTicketV2(payload).toPromise();
                if (errorCode !== 200) {
                    throw new Error("Error in api");
                }
                else {
                    this.msgService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Email sent !!',
                    });
                }
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Not able to send document, Please try again later',
                });
            }
        });
    }
    toggleInsuranceModal(state) {
        if (state)
            this.editInsurance();
        this.openEditModal = state;
    }
    hideInsuranceModal() {
        this.toggleInsuranceModal(false);
        this.ticketCreationState.set(null);
    }
    handleInsuranceSubmit(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const payload = Object.assign({}, this.ticket);
            const formData = JSON.parse(form.get('formData') || '{}');
            const driverMedicalProof = _.get(formData, 'medicalProofs.driver.document', '');
            const riderMedicalProof = _.get(formData, 'medicalProofs.rider.document', '');
            const thirdPartyMedicalProof = _.get(formData, 'medicalProofs["third party"].document', '');
            _.set(payload, 'formData["Date Of Issue"]', formData['Date Of Issue']);
            _.set(payload, 'formData.place', formData['place']);
            _.set(payload, 'formData.incidentStory', formData['incidentStory']);
            _.set(payload, 'insuranceDetails.accidentalDetails.files', formData['Incident Photo/Video']);
            _.set(payload, 'insuranceDetails.accidentalDetails.incidentStory', formData['incidentStory']);
            _.set(payload, 'insuranceDetails.accidentalDetails.place', formData['place']);
            _.set(payload, 'insuranceDetails.accidentalDetails.injuryTo', formData['injuryTo']);
            _.set(payload, 'insuranceDetails.legalDetails.driverMedicalProof', driverMedicalProof);
            _.set(payload, 'insuranceDetails.legalDetails.riderMedicalProof', riderMedicalProof);
            _.set(payload, 'insuranceDetails.legalDetails.thirdPartyMedicalProof', thirdPartyMedicalProof);
            _.set(payload, 'formData.subIssue', this.previousSubIssues);
            _.set(payload, 'insuranceDetails.legalDetails.firDoc', formData['firDocument']);
            if (this.isUAE) {
                _.set(payload, 'insuranceDetails.legalDetails.otherDocument', formData['otherDocument']);
                _.set(payload, 'insuranceDetails.legalDetails.liabilityFlagging', formData['liabilityFlagging']);
            }
            if (this.isInsuranceApplicable === 'No') {
                _.set(payload, 'insuranceDetails.vehicleDetails.isInsuranceApplicable', "Yes");
                _.set(payload, 'formData.isInsuranceApplicable', 'Yes');
            }
            try {
                const { response, errorCode } = yield this.ticketViewService.updateTicket(payload).toPromise();
                if (errorCode !== 200) {
                    throw new Error();
                }
                else {
                    this.msgService.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Ticket Updated !!',
                    });
                }
                this.onEditComplete.emit(response);
                this.hideInsuranceModal();
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Cannot update details at the moment.',
                });
            }
        });
    }
    isInsuranceAcceptedOrRejected() {
        const stages = ['INSURANCE_ACCPETED', 'INSURANCE_REJECTED'];
        return stages.includes(this.isInsuranceApplicable);
    }
    isAddInsuranceCtaDisabled() {
        if (!this.isUAE) {
            return !this.doesRoleHasAccess || this.roleName !== 'MSE' || this.isInsuranceAcceptedOrRejected();
        }
        return this.roleName !== 'Shift Incharge';
    }
}
