import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { MessageService } from "primeng/api";
import { IncidentHeirarchyService } from "src/app/services/incident-heirarchy.service";
import {
  IssueType,
  PreviousIssues,
  SubIssueType,
} from "src/app/utils/types/ticket";

import * as _ from "lodash";
import { TicketViewService, UpdateTicketPayload } from "src/app/services/ticket-view.service";
import { HubListState } from "src/app/utils/state/hublistState.service";

type OptionType = { label: string; value: string };
type OptionTypeArray = Array<OptionType>;

@Component({
  selector: "app-modify-ticket-modal",
  templateUrl: "./modify-ticket-modal.component.html",
  styleUrls: ["./modify-ticket-modal.component.css"],
})
export class ModifyTicketModalComponent implements OnInit {
  @Input()
  showModal: boolean = false;

  @Input()
  ticket = {};

  @Input()
  selectedSubIssues: PreviousIssues[] = [];

  @Output()
  onClose = new EventEmitter<boolean>();

  @Output()
  onSubmit = new EventEmitter();

  // ngModel values
  issueSubIssue = [{ issueId: "", subIssues: [] }];
  createNewTicket: boolean = false;

  // options to select
  issueOptions: OptionTypeArray = [];
  subIssueOptions: { [key: string]: OptionTypeArray } = {};
  // todo: get options from shashank
  reassignmentOptions: OptionTypeArray = [];
  reassignmentReason: string;
  description: string;

  constructor(
    private incidentService: IncidentHeirarchyService,
    private ticketViewService: TicketViewService,
    private msgService: MessageService,
    private hubListState: HubListState,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.getIssueOptions();
    this.populateReassignmentReasons();
    this.populateIssueAndSubIssues();
  }

  populateReassignmentReasons() {
    this.hubListState.constants$.subscribe(constants => {
      this.reassignmentOptions = constants['reassignmentOptions'];
    });
  }

  populateIssueAndSubIssues() {
    if (!this.selectedSubIssues.length) return;

    const issueIds = [];
    this.issueSubIssue = this.selectedSubIssues.map((issue) => {
      issueIds.push(issue.issueId);
      return {
        issueId: issue.issueId,
        subIssues: (issue.subIssues || []).map((subIssue) => subIssue.subIssueId),
      };
    });

    this.getSubIssueByIssueId(issueIds.join(","));
  }

  onIssueSelect(event) {
    const { value } = event;
    this.getSubIssueByIssueId(value);
  }

  filterSelectedOptions(options: OptionTypeArray, index: number) {
    const selectedValues = this.issueSubIssue
      .filter((v, i) => i !== index)
      .map((value) => value.issueId);
    return options.filter((option) => !selectedValues.includes(option.value));
  }

  async getIssueOptions() {
    try {
      const {
        response,
        errorMessage = "",
        errorCode = "",
      } = await this.incidentService.getIssues().toPromise();
      if (errorCode !== 200) throw new Error(errorMessage);

      this.issueOptions = (response || []).map((issue: IssueType) => ({
        label: issue.issueName,
        value: issue.issueId,
      }));
    } catch (error) {
      console.error(error);
      this.msgService.add({
        severity: "error",
        summary: "Error while fetching issues",
        detail: "Please try again later",
      });
    }
  }

  clearSubIssueForIssues(issueIds: string[]) {
    this.issueSubIssue = this.issueSubIssue.map((value) => {
      if (issueIds.includes(value.issueId)) {
        value.subIssues = [];
      }
      return value;
    });
  }

  populateSubIssueOptions(subIssues: SubIssueType[], issueId: string) {
    this.subIssueOptions[issueId] = (subIssues || []).map(
      (subIssue: SubIssueType) => ({
        label: subIssue.subIssueName,
        value: subIssue.subIssueId,
      })
    );
  }

  async getSubIssueByIssueId(issueId: string, clearSubIssue: boolean = false) {
    try {
      const multiple = issueId.includes(",");
      const zoneId = this.cookieService.get("selectedZone");
      if (!zoneId)
        throw new Error("Cannot select zone, please logout and try again");

      const {
        response,
        errorMessage = "",
        errorCode = "",
      } = await this.incidentService
        .getSubIssusesList(zoneId, issueId)
        .toPromise();
      if (errorCode !== 200) throw new Error(errorMessage);

      const issues = issueId.split(",");
      if (clearSubIssue) {
        this.clearSubIssueForIssues(issues);
      }

      if (!multiple) {
        this.populateSubIssueOptions(response, issueId);
        return;
      }

      issues.forEach((id) => {
        const subIssues = (response || []).filter(
          (subIssue) => subIssue.issueId === id
        );
        this.populateSubIssueOptions(subIssues, id);
      });
    } catch (error) {
      console.error(error);
      this.msgService.add({
        severity: "error",
        summary: "Error while fetching subissues",
        detail: "Please try again later",
      });
    }
  }

  closeModal() {
    this.showModal = false;
    this.onClose.emit(false);
  }

  addIssueOption() {
    this.issueSubIssue.push({ issueId: "", subIssues: [] });
  }

  removeIssueOption(index: number) {
    this.issueSubIssue = this.issueSubIssue.filter((v, i) => i !== index);
  }

  disableSubmit() {
    const anyIssueInvalid = this.issueSubIssue.some((value) => !value.issueId || !value.subIssues.length);
    const isDescriptionMandatory = this.reassignmentReason === 'Others'
    const anyFieldEmpty = this.createNewTicket
      ? !this.reassignmentReason || (isDescriptionMandatory && !this.description)
      : false;

    return anyIssueInvalid || anyFieldEmpty;
  }

  disableCreateTicket() {
    const noOfAncestors = _.get(this.ticket, 'centerLocation.reassignmentDetails.parentTicketId', []);
    return noOfAncestors.length >= 2;
  }

  getIssueMappingFromIds(ids: string[]) {
    const mapping = {};
    this.issueOptions.forEach((option) => {
      if (ids.includes(option.value)) {
        mapping[option.value] = option;
      }
    });

    return mapping;
  }

  getSubIssueMapping(subIssueIds: string[], issueId: string) {
    const subIsusses = this.subIssueOptions[issueId];
    return subIsusses
      .filter((subIssue) => subIssueIds.includes(subIssue.value))
      .map((subIssue) => ({
        subIssueId: subIssue.value,
        subIssueName: subIssue.label,
      }));
  }

  async updateIssues(payload: UpdateTicketPayload) {
    const {
      response,
      errorMessage = "",
      errorCode = "",
    } = await this.ticketViewService.updateTicketV2(payload).toPromise();
    if (errorCode !== 200) throw new Error(errorMessage);

    this.msgService.add({
      severity: "success",
      summary: "Success",
      detail: "Issues Updated",
    });

    return response;
  }

  async submit() {
    try {
      if (this.issueSubIssue.some((value) => !value.issueId)) {
        throw new Error("Please select valid issue/subissue");
      }

      const selectedIssueIds = this.issueSubIssue.map((field) => field.issueId);
      const issueMapping = this.getIssueMappingFromIds(selectedIssueIds);

      const issueSelection: PreviousIssues[] = [];
      this.issueSubIssue.forEach((field) => {
        const obj = {
          issueId: field.issueId,
          issueName: issueMapping[field.issueId].label,
          subIssues: this.getSubIssueMapping(field.subIssues, field.issueId),
        };
        issueSelection.push(obj);
      });

      const payload: UpdateTicketPayload = this.ticketViewService.formatIssueUpdatePayload(
        this.ticket,
        issueSelection,
        this.createNewTicket,
        this.reassignmentReason,
        this.description
      );
      const updatedTicket = await this.updateIssues(payload);
      this.onSubmit.emit(this.createNewTicket ? updatedTicket.ticketId : null);
    } catch (error) {
      console.error(error);
      this.msgService.add({
        severity: "error",
        summary: "Error while submitting",
        detail: error || "Please try again later",
      });
    }
  }
}
