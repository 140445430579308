import { DbUsersService } from 'src/app/services/db-users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TicketViewService } from 'src/app/services/ticket-view.service';
import { SelectItem } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Urls } from '../../models/api.constants';
import { HubService } from 'src/app/services/hub.service';
import * as moment from 'moment';
import { TatIssuesService } from 'src/app/services/tat-issues.service';
import { LocalService } from 'src/app/services/local.service';
import { TicketListStateService } from 'src/app/utils/state/ticketState.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HubListState } from 'src/app/utils/state/hublistState.service';

@Component({
  selector: 'app-tickets-view',
  templateUrl: './tickets-view.component.html',
  styleUrls: ['./tickets-view.component.css']
})
export class TicketsViewComponent implements OnInit, OnDestroy {
  skip = 0;
  limit = 10;
  isLoadComplete = false;
  allTicketsBasicData = [
  ];
  ticketId;
  statusData = []
  totalRecords = 0;
  ticketType: SelectItem[];
  selectedActiveTicketState;
  projection = 'min';
  currentUserSsoId = this.cookieService.get('ssoId');
  currentProject  = 'operationsTMS'
  query = null;
  role = []
  display: boolean = false;
  start_date:any;
  end_date:any;
  maxDateTime;
  selectedTicketId = null
  selectedVehicleNumber = null
  // filters
  // active state

  // Action
  selectedAssignedForAction: any[] = [];
  assignedForAction: SelectItem[] = [
    { label: 'Abcd', value: '119' }
  ];
  // View
  selectedAssignedForView: any[] = [];
  assignedForView: SelectItem[] = [];
  usersList: SelectItem[];
  isActive = null;
  expense=  {
    IN: [
      { label: 'TI', value: "TI" },
      { label: 'MSE', value: "MSE" },
      { label: 'Shift Incharge', value: "Shift Incharge" },
      { label: 'Hub Manager', value: "Hub Manager" }
    ],
    AE: [
      { label: 'Shift Incharge', value: "Shift Incharge" },
    ],
  };
  activeState = [
    { label: 'All', value: null },
    { label: 'Open', value: true },
    { label: 'Close', value: false }
  ];
  colorTag = [
    { label: 'All colour tags', value: '' },
    { label: 'Red tag', value: 'red' },
    { label: 'Yellow tag', value: 'yellow' },
    { label: 'Orange tag', value: 'orange' },
  ];

  categoryDataMapping = {
    IN: [
      { label: 'Preventive Services', value: 'Preventive Services' },
      { label: 'Unplanned Non Incident Repair', value: 'Unplanned Non Incident Repair'},
      { label: 'Unplanned Incident Repair', value: 'Unplanned Incident Repair' },
      { label: 'Fast Charger', value: 'Fast Charger' },
      { label: 'Slow Charger', value: 'Slow Charger' },
    ],
    AE: [
      { label: 'Unplanned Non Incident Repair', value: 'Unplanned Non Incident Repair'},
      { label: 'Unplanned Incident Repair', value: 'Unplanned Incident Repair' },
      { label: 'Preventive Services', value: 'Preventive Services' },
    ]
  };
  search = '';
  hubList = [];
  roleName: string;
  totalTickets = 0
  currentStage = [];
  sortingOrder = -1
  colour: string = ''
  hideFlag: boolean = false;
  date = new Date();
  endDate;
  startDate;
  category = [];
  showCsvLoader: boolean = false;
  showCsvSuccess: boolean = false;
  showCsvFailed: boolean = false;
  showCsvText: boolean = true

  timezone;
  displaySubIssues: boolean = false;
  issueDetails;
  issuesOptions;
  issues;
  // showHubFilter:boolean = false; Removed because we have a hub filter already on navbar
  selectedHubs = [];
  hubsOptions;
  PMSserviceTypes = ["Tyre Replacement", "Washing Required", "Allignment Balancing", "Preventive Service"];
  issueType;
  country;

  filterStateObj = {};

  loader: boolean = false;
  ticketSubject = new Subject<string>();

  searchWithFilters(e = { first: 0, rows: this.limit }) {
    if (this.skip !== e.first) {
      this.skip = e.first;
      this.limit = e.rows;
      this.isLoadComplete = false;

      const query = this.makequery();
      this.fetchSearchQuery(query);
      return;
    }
    this.skip = e.first;
    this.limit = e.rows;
    this.isLoadComplete = false;

    this.ticketListState.ticketListState.subscribe(res => {
      if (res && res.data) {
        const { count = 0, data = [], skip = 0 } = res || {};
        this.totalRecords = count;
        this.totalTickets = count;
        this.isLoadComplete = true;
        this.allTicketsBasicData = data;
        this.skip = skip;
      } else {
        const query = this.makequery();
        return this.fetchSearchQuery(query);
      }
    });
  }
  fetchSearchQuery(query = { skip: 0, limit: this.limit }) {
    this.loader = true;
    this.ticketViewService.loadOpsTickets(query)

      .subscribe(({ code, response = { count: 0, results: [] }, message }: any) => {
        const results = response.results;
        this.loader = false;
        this.totalTickets = response.count;
        for (let ticket of results) {
          const { assignedForAction } = ticket;
          if (assignedForAction != undefined)
            ticket.assignedForActionAlias = assignedForAction.filter(({ ssoId }) => ssoId != '119');
        }
        this.allTicketsBasicData = results;
        this.totalRecords = response.count;
        if (code !== 200) {
          return alert(message);
        }
        this.isLoadComplete = true;

        // add tickets to state
        this.ticketListState.set({
          count: this.totalRecords,
          data: this.allTicketsBasicData,
          skip: this.skip,
        });
      }, error => {
        console.error(error);
        this.loader = false;
      });

  }

  getLocaleBasedCategoryData() {
    const locale = localStorage.getItem('country');
    return this.categoryDataMapping[locale];
  }
  getLocaleBasedExpense() {
    const locale = localStorage.getItem('country');
    return this.expense[locale];
  }

  setFilterObj(obj: Object) {
    localStorage.setItem('filters', JSON.stringify(obj));
  }
  getFilterObj() {
    const filterString = localStorage.getItem('filters') || '{}';
    return JSON.parse(filterString);
  }

  constructor(
    private ticketViewService: TicketViewService,
    private cookieService: CookieService,
    private router: Router,
    private userService: DbUsersService,
    private hubService: HubService,
    private tatIssuesService: TatIssuesService,
    public localeService: LocalService,
    private ticketListState: TicketListStateService,
    private hubListState: HubListState,
  ) {
    const locale = localStorage.getItem('country');
    this.country=locale
    this.getIssues();
    this.getHubs();
    this.isActive = true;
    this.date = this.localeService.getDate();
    this.maxDateTime = this.localeService.getDate();
    this.maxDateTime.setDate(this.maxDateTime.getDate() + 31); 
    this.roleName=localStorage.getItem("roleName")
    let currentURL = this.router.url;
    this.cookieService.set('routeLocation', currentURL, null, '/', Urls.COOKIE_URL,null,"Lax");
    this.getTicketStates()
    this.userService.getAllUsers().subscribe(({ code, response = [], message }: any) => {
      if (code !== 200) {
        return alert(message);
      }
      this.usersList = response.map(({ name: label, ssoId: value }) => ({ label, value }));
    });
    this.hubService.getAllHubs().subscribe(({ response = [] }: any) => {      
      this.hubList = response;
    })
    let ticketId = localStorage.getItem('ticketId');
    if(ticketId){
      this.getDetailViewForTicket(ticketId);
      localStorage.removeItem('ticketId')
    }
    this.timezone = localeService.getTimeZone();
  }
  makequery() {
    const query: any = {};
    let filters = {};
    if (typeof this.isActive === 'boolean') {
      query.isActive = this.isActive;
    }
    if (this.selectedTicketId) {
      if (this.selectedTicketId[0] !== "T") {
        filters['formData.Vehicle Number'] = this.selectedTicketId;
      }
      else {
        query.ticketIds = this.selectedTicketId.split(','); 
      }
    }
    if (this.currentStage.length>0) {
      if(this.currentStage.includes('CAR_SENT_TO_WORKSHOP')){
        if (this.localeService.isLocaleIndia()) {
          filters['jobCardStatus']=this.currentStage
        }
        filters['currentStage']='Car sent to workshop'
      }
      else if (this.currentStage.includes('REJECTED_AT_WORKSHOP')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('APPROVED')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('ESTIMATED')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('REJECTED')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('WORK_IN_PROGRESS')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('READY_FOR_COLLECTION')) {
        filters['jobCardStatus'] = this.currentStage
      }
      else if (this.currentStage.includes('Job card closed')) {
        filters['jobCardStatus'] = 'CLOSED'
      }
      else if (this.currentStage.includes('Ticket Closed')) {
        query.isActive = false;
      }
      else if (this.currentStage.includes('Assigned to MSE')) {
        this.currentStage.push('Assign to MSE')
        filters['currentStage'] = this.currentStage
      }
      else if (this.currentStage.includes('Repair car at hub')) {
        this.currentStage.push('Repaired at Hub')
        filters['currentStage'] = this.currentStage
      }
      else if (this.currentStage.includes('Repaired at Hub')) {
        delete filters['currentStage'];
        const index = this.currentStage.indexOf('Repaired at Hub');
        if (index > -1) {
          this.currentStage.splice(index, 1);
        }
      }
      else if (this.currentStage.includes('Assign to MSE')) {
        delete filters['currentStage'];
        const index = this.currentStage.indexOf('Assign to MSE');
        if (index > -1) {
          this.currentStage.splice(index, 1);
        }
      }
      else if (this.currentStage.includes('Car sent to workshop')) {
        delete filters['jobCardStatus'];
        delete filters['currentStage'];
      }
      else {
        filters['currentStage'] = this.currentStage
      }
    }
    else {
      delete filters['currentStage'];
      delete filters['jobCardStatus'];
    }

    let PMSIssues = this.issues.filter(issue => this.PMSserviceTypes.includes(issue));
    let GMSIds = this.issues.filter(issue => !this.PMSserviceTypes.includes(issue));

    if (PMSIssues.length > 0 && this.issueType == 'PMS') {
      delete filters['formData.subIssue.issueId']
      filters['formData.Service Type'] = PMSIssues;
    }

    if (GMSIds.length > 0 && this.issueType == 'GMS') {
      delete filters['formData.Service Type']
      filters['formData.subIssue.issueId'] = GMSIds;
    }

    if (this.selectedHubs.length > 0) {
      filters['hubs'] = this.selectedHubs;
    }

    if (this.category.length > 0) {
      if (this.category.includes('Preventive Services')) {
        filters['category'] = this.category
      }
      if (this.category.includes('Unplanned Non Incident Repair')) {
        filters['category'] = this.category
      }
      if (this.category.includes('Unplanned Incident Repair')) {
        filters['category'] = this.category
      }
      if (this.category.includes('Fast Charger')) {
        filters['category'] = this.category
      }
      if (this.category.includes('Slow Charger')) {
        filters['category'] = this.category
      }
    }

    if (this.colour) {
      filters['colour'] = this.colour
    }

    if (this.role) {
      query.role = this.role
    }
    if (this.selectedVehicleNumber) {
      query.formData = { 'formData.Vehicle Number': this.selectedVehicleNumber }
    }
    if (this.selectedAssignedForAction && this.selectedAssignedForAction.length > 0) {
      query.assignedForAction = this.selectedAssignedForAction;
    }
    if (this.selectedAssignedForView && this.selectedAssignedForView.length > 0) {
      query.assignedForView = this.selectedAssignedForView;
    }
    query.filters = filters
    const search = this.search && this.search.trim();
    /*     if (search) {
          query.filters = {
            search,
          };
        } */

    if (this.projection) {
      query.projection = this.projection || 'min';
    }
    if (this.startDate && this.endDate) {
      query.startDate = this.startDate
      query.endDate = this.endDate
    }
    query.skip = this.skip;
    query.limit = this.limit;
    query.sortingOrder = this.sortingOrder;
    return query;
  }
  ngOnInit() {
    this.populateFilters();

    this.hubListState.filterHublist$.subscribe(res => {
      const pathName = location.pathname;
      if (pathName.includes('/ticket/view')) {
        const query = this.makequery();
        return this.fetchSearchQuery(query);
      }
    });

    this.ticketSubject.pipe(debounceTime(1000)).subscribe(val => {
      this.selectedTicketId = val;
      this.filterStateObj['searchQuery'] = this.selectedTicketId;
      this.setFilterObj(this.filterStateObj);
      const query = this.makequery();
      return this.fetchSearchQuery(query);
    });
  }

  populateFilters() {
    const filters = this.getFilterObj();
    this.filterStateObj = filters;
    if (!Object.keys(filters).length) return;
    if (filters.searchQuery) {
      this.selectedTicketId = filters.searchQuery;
    }
    if (filters.colour) {
      this.colour = filters.colour;
    }
    if (filters.category && filters.category.length) {
      this.category = filters.category;
    }
    if (filters.issues && filters.issues.length) {
      this.issues = filters.issues;
    }
    if (filters.hubs && filters.hubs.length) {
      this.selectedHubs = filters.hubs;
    }
    if (filters.roles && filters.roles.length) {
      this.role = filters.roles;
    }
    if (filters.ticketStatus && filters.ticketStatus.length) {
      this.currentStage = filters.ticketStatus;
    }
  }

  ngOnDestroy() {
    this.cookieService.delete('routeLocation', '/', Urls.COOKIE_URL);
  }

  getDetailViewForTicket(ticketId) {
    this.displaySubIssues =  false;
    this.ticketId = ticketId;

    const param = String(this.ticketId).split('/').join('-');
    this.router.navigate([`/ticket/view/${param}`]);
  }

  downloadCSV() {
    if (!this.start_date || !this.end_date) {
      return alert('Please select both start and end date');
    }
    let start = this.start_date.getTime();
    let end = this.end_date.getTime();
    if ((end.valueOf() - start.valueOf()) > 31 * 60 * 60 * 24 * 1000) {
      this.start_date = null;
      this.end_date = null;
      return alert('Can only view data for 31 days');
    }
    if (start > end) {
      this.start_date = null;
      this.end_date = null;
      return alert('End date should be greater than Start date');
    }
    
    this.start_date = this.localeService.getDate(this.start_date);
    this.end_date = this.localeService.getDate(this.end_date);

    let new_start_date = this.start_date.getTime()
    let new_end_date = this.end_date.getTime()
    this.isLoadComplete = false;


    this.ticketViewService.loadTicketsCSV(new_start_date, new_end_date, 'operationsTMS')
      .subscribe((res) => {
        saveAs(res, `tickets.csv`);
        this.isLoadComplete = true;
        this.display = false;
        this.start_date = null;
        this.end_date = null;
      });
  }

  openCSVDialog() {
    this.display = true;
  }
  hideDialogBox() {
    this.start_date = null;
    this.end_date = null;
  }

  getIssueType(ticket) {
    if (ticket.formData.Repair) {
      if (ticket.originalSubIssue) {
        ticket.formData.Repair = [];
        for (let i = 0; i < ticket.originalSubIssue.length; i++) {
          ticket.formData.Repair.push(ticket.originalSubIssue[i].issueName);
        }
        if (ticket.formData.Repair.length > 1) {
          return ticket.formData.Repair[0] + ' +';
        }
        return ticket.formData.Repair[0] || '';
      }
      if (ticket.formData.Repair.length > 1) {
        return ticket.formData.Repair[0] + ' +';
      }
      return ticket.formData.Repair[0] || '';
    } else if (ticket.formData['Service Type']) {
      return ticket.formData['Service Type'];
    } else if (ticket.formData.subIssue) {
      // this should be the ideal flow
      const issues = ticket.formData.subIssue.map(issue => issue.issueName);
      if (issues.length === 1) {
        return issues[0];
      } else if (issues.length > 1) {
        return `${issues[0]} +`;
      }
    }
    return '';
  }

  async getHubs() {
    this.hubsOptions = [];
    const ssoid = this.cookieService.get("ssoId");
    const zoneId = this.cookieService.get('selectedZone');
    try {
      const res = await this.tatIssuesService.getHubs(ssoid, zoneId).toPromise();
      this.hubsOptions = Object.keys(res.response).map(key => {
        return {
          label: res.response[key],
          value: key
        };
      });
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  }

  async getIssues() {
    this.issues = [];
    try {
      const res = await this.tatIssuesService.getIssues().toPromise();
      this.issuesOptions = res.response.map(item => ({
        value: item.issueId,
        label: item.issueName + ' (GMS)'
      }));
      this.issuesOptions.push({
        value: 'Preventive Service', label: 'Preventive Service (PMS)'
      })

      this.issuesOptions.push({
        value: 'Allignment Balancing', label: 'Allignment Balancing (PMS)'
      })

      this.issuesOptions.push({
        value: 'Tyre Replacement', label: 'Tyre Replacement (PMS)'
      })
      this.issuesOptions.push({
        value: 'Washing Required', label: 'Washing Required (PMS)'
      })
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  }


  openIssueBox(ticket) {
    if (!ticket.originalSubIssue) return;
    this.displaySubIssues = true;
    this.issueDetails = ticket;
  }
  getSubIssueNames(subIssues: any[]): string {
    return subIssues ? subIssues.map(subIssue => subIssue.subIssueName).join(', ') : '';
  }

  getTicketStates() {
    const obj = {
      role: this.roleName
    }
    this.ticketViewService.getTicketStates(obj).subscribe(res => {
      this.statusData = res['response']
    })
  }
  filterStatus(event) {
    this.currentStage = event.value
    const query = this.makequery();
    if (this.currentStage.length) {
      this.filterStateObj['ticketStatus'] = this.currentStage;
      this.setFilterObj(this.filterStateObj);
    }
    return this.fetchSearchQuery(query)
  }

  modelChangeFn(e) {
    this.ticketSubject.next((e.target.value || '').trim());
  }
  roleFilter(event) {
    this.role = event.value
    if (this.role.length) {
      this.filterStateObj['roles'] = this.role;
      this.setFilterObj(this.filterStateObj);
    }
    const query = this.makequery();
    return this.fetchSearchQuery(query)
  }

  dateSort() {
    this.sortingOrder = this.sortingOrder == -1 ? 1 : -1
    const query = this.makequery();
    return this.fetchSearchQuery(query)
  }
  setColorStyles(ticketData) {
    let styles;
    if (ticketData) {
      styles = {
        'border-left': '8px solid' + ticketData
      };
    }
    return styles;
  }
  changeStyle(item) {
    this.hideFlag = true
    let styles;
    if (item) {
      styles = {
        'color': item
      };
    } else {
      styles = {
        'display': 'none'
      };
    }
    return styles;
  }

  colorChange(event) {
    this.colour = event.value

    if (this.colour) {
      this.filterStateObj['colour'] = this.colour;
      this.setFilterObj(this.filterStateObj);
    }
    const query = this.makequery();
    return this.fetchSearchQuery(query)

  }
  dateSelectClickHandler() {

    if (this.date[0] && this.date[1]) {
      let start = this.date[0].getTime();
      let end = this.date[1].getTime();
      if ((end.valueOf() - start.valueOf()) > 31 * 60 * 60 * 24 * 1000) {
        this.date[0] = null;
        this.date[1] = null;
        return alert('Can only view data for 31 days');
      }
      if (start > end) {
        this.date[0] = null;
        this.date[1] = null;
        return alert('End date should be greater than Start date');
      }
      this.startDate = start;
      this.endDate = end;

      if (start && end) {
        this.filterStateObj['startDate'] = start;
        this.filterStateObj['endDate'] = end;
        this.setFilterObj(this.filterStateObj);
      }
      const query = this.makequery();
      return this.fetchSearchQuery(query)
    }
    // this.getData(this.startDate,this.endDate,0)
    //this.change(this.collectedByFilter)
  }

  removeFilter() {
    this.startDate = null
    this.endDate = null
    this.date = null
    this.filterStateObj['startDate'] = null;
    this.filterStateObj['endDate'] = null;
    this.setFilterObj(this.filterStateObj);
    const query = this.makequery();
    return this.fetchSearchQuery(query)
  }
  filterCategory(event) {
    this.category = event.value
    if (this.category.length) {
      this.filterStateObj['category'] = this.category;
      this.setFilterObj(this.filterStateObj);
    }
    const query = this.makequery()
    return this.fetchSearchQuery(query)
  }

  resetAllFilters() {
    this.issues = [];
    this.category = [];
    this.currentStage = [];
    this.selectedHubs = [];
    this.role = [];
    this.startDate = null;
    this.endDate = null;
    this.date = null;
    this.colour = '';
    this.selectedTicketId = null;

    this.filterStateObj = {};
    this.setFilterObj(this.filterStateObj);

    const query = this.makequery();
    return this.fetchSearchQuery(query);
  }

  showResetFilter() {
    const issueFilterSelected = this.issues && this.issues.length;
    const categoryFilterSelected = this.category && this.category.length;
    const stageFilterSelected = this.currentStage && this.currentStage.length;
    const hubFilterSelected = this.selectedHubs && this.selectedHubs.length;
    const roleFilterSelected = this.role && this.role.length;

    return (
      issueFilterSelected || 
      categoryFilterSelected || 
      stageFilterSelected || 
      hubFilterSelected || 
      roleFilterSelected ||
      this.startDate || 
      this.endDate ||
      this.colour ||
      this.selectedTicketId
    );
  }

  filterIssues(event) {
    this.issues = event.value;
    this.issueType = this.PMSserviceTypes.includes(event.itemValue) ? 'PMS' : 'GMS';
    if (this.issueType === 'PMS') {
      this.issues = this.issues.filter((issue) => this.PMSserviceTypes.includes(issue))
    } else if (this.issueType === 'GMS') {
      this.issues = this.issues.filter((issue) => !this.PMSserviceTypes.includes(issue))
    }
    if (this.issues.length) {
      this.filterStateObj['issues'] = this.issues;
      this.setFilterObj(this.filterStateObj);
    }
    const query = this.makequery()
    return this.fetchSearchQuery(query)
  }
  filterHubs(event) {
    this.selectedHubs = event.value
    if (this.selectedHubs.length) {
      this.filterStateObj['hubs'] = this.selectedHubs;
      this.setFilterObj(this.filterStateObj);
    }
    const query = this.makequery()
    return this.fetchSearchQuery(query)
  }
  postCsvTicketsForEmail() {
    this.showCsvLoader = true;
    this.showCsvText = false
    /*   const obj={
        startDate:this.startDate,
        endDate:this.endDate,
        role:this.role,
        status:this.currentStage,
        colour:this.colour,
        category:this.category,
        ticketId:this.selectedTicketId
      } */
    const query = this.makequery();
    this.ticketViewService.postCsvTicketsForEmail(query).subscribe(res => {
      if (res['code'] == 200) {
        this.showCsvLoader = false
        this.showCsvSuccess = true
        setTimeout(() => {
          this.showCsvText = true;
          this.showCsvFailed = false;
          this.showCsvSuccess = false;
        }, 5000);
      }
      else {
        this.showCsvLoader = false
        this.showCsvFailed = true
        setTimeout(() => {
          this.showCsvText = true;
          this.showCsvFailed = false;
          this.showCsvSuccess = false;
        }, 5000);
      }
    })
  }
  getBackgroundColor(ticket): string {
    return ticket.ticketAgeColor;
  }

  containsPreviousWorkshop(ticket) {
    return !!this.ticketViewService.getParentTicket(ticket);
  }

}
