import * as tslib_1 from "tslib";
import { OnInit, NgZone, ElementRef } from '@angular/core';
import { Headers } from './models/api.headers';
import { Urls } from './models/api.constants';
import { PermissionForUsers } from './guards/checkRoles';
import { environment } from 'src/environments/environment';
export class AppComponent {
    constructor(userService, cookieService, router, route, hubService, _incidentHeirarchySercive, _dynamicFormService, ticketViewService, messageService, ngZone, localeSerice, hubListState) {
        this.userService = userService;
        this.cookieService = cookieService;
        this.router = router;
        this.route = route;
        this.hubService = hubService;
        this._incidentHeirarchySercive = _incidentHeirarchySercive;
        this._dynamicFormService = _dynamicFormService;
        this.ticketViewService = ticketViewService;
        this.messageService = messageService;
        this.ngZone = ngZone;
        this.localeSerice = localeSerice;
        this.hubListState = hubListState;
        this.onlyIcons = false;
        this.isHubRequired = true;
        this.isLoadComplete = false;
        this.isGoingBackPossible = false;
        this.previouslyActiveForm = null;
        this.nextPrevFormRecords = {};
        this.activeIndex = 0;
        this.values = {};
        this.displayCreateTicket = false;
        this.isProduction = environment.production;
        this.gButtonInitialised = false;
        this.hasRiderAcesss = false;
    }
    clearCookiesManually() {
        this.clearCookies();
        window.location.reload();
    }
    clearCookies() {
        const country = localStorage.getItem('country');
        this.cookieService.set('selectedProject', this.selectedProject, 365, '/', Urls.COOKIE_URL, null, "Lax");
        // experimenal cookie deletion
        const paths = ['/', '/en-IN', '/en-AE', '/en-IN/navigate', '/en-AE/navigate'];
        paths.forEach(path => {
            this.cookieService.delete('accessToken' + country + Urls.COOKIE_SUFFIX, path, Urls.COOKIE_URL);
            this.cookieService.delete('userEmail', path, Urls.COOKIE_URL);
            this.cookieService.delete('ssoId', path, Urls.COOKIE_URL);
            this.cookieService.delete('selectedHub', path, Urls.COOKIE_URL);
            this.cookieService.delete('selectedProject', path, Urls.COOKIE_URL);
        });
        this.cookieService.deleteAll();
        console.log("==Cookies cleared==");
        Headers.clearHeaders();
    }
    onLoggedOutDoStateReset() {
        // app state
        this.onlyIcons = false;
        this.username = "";
        this.password = "";
        this.isUserLoggedIn = false;
        this.ssoId = null;
        this.hubList = null;
        this.selectedHub = "";
        this.selectedProject = '';
        this.hubUrl = "";
        this.axleUrl = "";
        this.projectList = null;
        this.isHubRequired = true;
        this.isLoadComplete = false;
        this.isGoingBackPossible = false;
        this.rootForm = null;
        this.currentlyActiveForm = null;
        this.previouslyActiveForm = null;
        this.nextPrevFormRecords = {};
        this.activeIndex = 0;
        this.items = null;
        this.values = {};
        return this.clearCookies();
    }
    ngOnInit() {
        const country = localStorage.getItem('country');
        const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
        if (accessToken) {
            return this.checkToken();
        }
        return this.onLoggedOutDoStateReset();
    }
    ngDoCheck() {
        try {
            let path = location.pathname;
            path = path.replace(/^\/en-[A-Z]{2}/g, '');
            const { value } = this.selectedProject;
            if (path === "/ticket/view" && value.projectName === "riderSupportTMS") {
                this.onSelectProject({
                    label: this.convertToNormalCase("operationsTMS"),
                    value: { projectName: "operationsTMS", isHubRequired: true },
                });
            }
            if (path == '/rider' && value.projectName !== "riderSupportTMS") {
                this.onSelectProject({
                    label: this.convertToNormalCase("riderSupportTMS"),
                    value: { projectName: "riderSupportTMS", isHubRequired: false },
                });
            }
        }
        catch (error) {
        }
    }
    intializeGoogleLogin() {
        this.gButtonInitialised = true;
        google.accounts.id.initialize({
            client_id: "393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com",
            callback: this.handleCredentialResponse.bind(this),
            auto_select: true,
            cancel_on_tap_outside: false,
        });
        google.accounts.id.renderButton(this.gButton.nativeElement, {
            type: "standard",
            theme: "filled_blue",
            size: "medium",
            width: 180,
            shape: "square",
        });
    }
    ngAfterContentChecked() {
        if (this.gButton && !this.gButtonInitialised) {
            this.intializeGoogleLogin();
        }
        ;
    }
    handleCredentialResponse(response) {
        try {
            this.ngZone.run(() => this.login(response.credential));
        }
        catch (e) {
            console.error("Error while trying to decode token", e);
        }
    }
    toggleIcons() {
        return (this.onlyIcons = !this.onlyIcons);
    }
    checkToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const country = localStorage.getItem('country');
                const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
                if (!accessToken) {
                    return this.onLoggedOutDoStateReset();
                }
                const apiResponse = yield this.userService.isTokenValid(accessToken);
                const { code, message, response } = apiResponse;
                if (code !== 200) {
                    this.onLoggedOutDoStateReset();
                    return;
                }
                const { sso_id: ssoId, projects = [], username } = response;
                this.ssoId = ssoId;
                this.projectList = projects.map((proj) => ({
                    label: this.convertToNormalCase(proj["projectName"]),
                    value: proj,
                }));
                this.hasRiderAcesss = this.projectList.some((item) => item.label === "Rider Support");
                if (this.projectList.length) {
                    this.selectedProject = this.projectList[0];
                    this.isHubRequired = this.selectedProject.value.isHubRequired;
                    this.cookieService.set("selectedProject", this.selectedProject.value.projectName, null, "/", Urls.COOKIE_URL, null, "Lax");
                }
                this.cookieService.set("ssoId", this.ssoId, 365, "/", Urls.COOKIE_URL, null, "Lax");
                localStorage.setItem("ssoId", this.ssoId);
                localStorage.setItem("username", username);
                this.getUserRole();
                this.userService.getUserRole(this.ssoId).subscribe((res) => {
                    let role;
                    localStorage.setItem("userRoles", JSON.stringify(res.response));
                    role = res.response.includes("MENU_TICKETS")
                        ? "/ticket/view"
                        : res.response.includes("MENU_CREATE_TICKETS")
                            ? "/ticket/heirarchy"
                            : res.response.includes("MENU_CONFIGURE_TICKETS")
                                ? "dynamic/form/create"
                                : "";
                    role == ""
                        ? (window.location.href = "http://" + Urls.HUB_WEB_URL)
                        : this.isUserLoggedIn == false
                            ? this.router.navigateByUrl(role)
                            : null;
                });
                this.getAuthorizedZones();
                //return this.getHubList();
            }
            catch (e) {
                console.error(e);
                return alert(e.message);
            }
        });
    }
    getAuthorizedZones() {
        this.userService.getAuthorizedZones().subscribe((res) => {
            this.zoneList = [];
            let zoneData = res.response;
            Object.keys(zoneData).forEach((zone) => {
                this.zoneList.push({ label: zoneData[zone], value: zone });
            });
            if (this.zoneList) {
                localStorage.setItem("zoneList", JSON.stringify(Object.keys(zoneData)));
                if (this.cookieService.get("selectedZone")) {
                    this.selectedZone = this.cookieService.get("selectedZone");
                    this.selectedZoneValue =
                        this.zoneList[this.zoneList.findIndex((x) => {
                            return x.value == this.selectedZone;
                        })];
                }
                else {
                    this.selectedZoneValue = this.zoneList[0];
                    this.cookieService.set("selectedZone", this.selectedZoneValue.value, null, "/", Urls.COOKIE_URL, null, "Lax");
                }
                this.getHubListBasedOnZone(this.selectedZoneValue.value);
            }
        });
    }
    getHubListBasedOnZone(zoneId) {
        this.hubUrl = Urls.HUB_WEB_URL;
        this.axleUrl = Urls.AXLE_WEB_URL;
        this.altiliumUrl = Urls.ALTILIUM_WEB_URL;
        this.hubService
            .getHubListBasedOnZone(this.ssoId, zoneId)
            .subscribe((res) => {
            this.hubList = [];
            let hubData = res.response;
            this.hubListState.set(hubData);
            if (Object.keys(res.response).length > 0) {
                this.hubList.push({ label: "ALL", value: "0" });
                localStorage.setItem("hubList", JSON.stringify(Object.keys(hubData)));
            }
            Object.keys(hubData).forEach((hub) => {
                if (hub != "0")
                    this.hubList.push({ label: hubData[hub], value: hub });
            });
            this.isUserLoggedIn = true;
            this.selectedHubListValue = this.hubList[0];
            this.onSelectType(this.selectedHubListValue);
        });
    }
    onSelectZone(event) {
        this.selectedZoneValue = event;
        this.selectedZone = event.value;
        this.cookieService.set("selectedZone", this.selectedZone, null, "/", Urls.COOKIE_URL, null, "Lax");
        this.getHubListBasedOnZone(event.value);
    }
    login(gmailToken = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (gmailToken) {
                    this.username = null;
                    this.password = null;
                }
                const apiResponse = yield this.userService.authenticate(this.username, this.password, gmailToken);
                const { response: { access_token = "" } = {}, error_description: message, } = apiResponse;
                if (!access_token) {
                    this.onLoggedOutDoStateReset();
                    return alert(message);
                }
                Headers.clearHeaders();
                Headers.addHeaders({ key: 'Authorization', value: 'Bearer ' + access_token });
                this.cookieService.set('userEmail', this.username, 365, '/', Urls.COOKIE_URL, null, "Lax");
                const country = localStorage.getItem('country');
                this.cookieService.set('accessToken' + country + Urls.COOKIE_SUFFIX, access_token, 365, '/', Urls.COOKIE_URL, null, "Lax");
                return this.checkToken();
            }
            catch (e) {
                console.error(e);
                return this.onLoggedOutDoStateReset();
            }
            finally {
            }
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const country = localStorage.getItem('country');
                const accessToken = this.cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);
                if (!accessToken) {
                    return this.onLoggedOutDoStateReset();
                }
                yield this.userService.logout(accessToken);
                this.cookieService.deleteAll();
                localStorage.clear();
            }
            catch (e) {
                console.error(e);
                return alert(e.message);
            }
            finally {
                this.onLoggedOutDoStateReset();
            }
            window.location.reload();
        });
    }
    getHubList() {
        this.hubService
            .getAllAuthorizedHubs(this.ssoId)
            .subscribe((res) => {
            this.hubList = [];
            let hubData = res.response;
            this.hubList.push({ label: "ALL", value: 0 });
            Object.keys(hubData).forEach((hub) => {
                if (hub != "0")
                    this.hubList.push({ label: hubData[hub].name, value: hub });
            });
            let previousSelectedHub = this.cookieService.get("selectedHub");
            if (this.hubList.length > 0) {
                if (previousSelectedHub != "") {
                    let previousSelectedHubObject = this.hubList[this.hubList
                        .map((x) => {
                        return x.value == previousSelectedHub;
                    })
                        .indexOf(true)];
                    this.selectedHub = previousSelectedHubObject;
                }
                else {
                    this.selectedHub = this.hubList[0];
                    this.cookieService.set("selectedHub", this.selectedHub.value, null, "/", Urls.COOKIE_URL, null, "Lax");
                }
            }
            if (this.selectedHub.value == 0) {
                Headers.replaceHeaders("hub", null);
            }
            else {
                Headers.replaceHeaders("hub", this.selectedHub.value);
            }
            // Headers.replaceHeaders('hub', this.selectedHub.value);
            this.isUserLoggedIn = true;
        });
    }
    onSelectType(event) {
        this.cookieService.set("selectedHub", event.value, null, "/", Urls.COOKIE_URL, null, "Lax");
        this.selectedHub = event;
        if (this.selectedHub.value == 0) {
            Headers.replaceHeaders("hub", null);
        }
        else {
            Headers.replaceHeaders("hub", event.value);
        }
        const routeLocation = this.router.url;
        if (routeLocation != "") {
            this.router
                .navigateByUrl("/dynamic/form/create", { skipLocationChange: true })
                .then(() => {
                this.router.navigateByUrl(routeLocation);
            });
        }
    }
    onSelectProject(event) {
        this.cookieService.set("selectedProject", event.value.project, null, "/", Urls.COOKIE_URL, null, "Lax");
        this.selectedProject = event;
        this.isHubRequired = event.value.isHubRequired;
        // Headers.replaceHeaders("project", this.selectedProject.value.projectName);
        this.cookieService.delete("selectedProject");
        this.cookieService.set("selectedProject", this.selectedProject.value.projectName, null, "/", Urls.COOKIE_URL, null, "Lax");
        if (event.value.projectName === "riderSupportTMS") {
            this.router.navigateByUrl("/rider");
            return;
        }
        const routeLocation = this.cookieService.get("routeLocation");
        if (routeLocation != "") {
            this.router
                .navigateByUrl("/dynamic/form/create", { skipLocationChange: true })
                .then(() => {
                this.router.navigateByUrl(routeLocation);
            });
        }
        if (event.value.projectName !== "riderSupportTMS" &&
            location.pathname !== "/ticket/view") {
            this.router.navigateByUrl("/ticket/view");
        }
    }
    handleProjectChange() {
        this.onSelectProject({
            "label": "Rider Support",
            "value": {
                "projectName": "riderSupportTMS",
                "isHubRequired": false
            }
        });
    }
    convertToNormalCase(str) {
        str = str.replace("TMS", "");
        let splitWord = str.replace(/([A-Z])/g, " $1");
        let words = splitWord.split(" ");
        let result = [];
        for (let word of words) {
            result.push(word.substr(0, 1).toUpperCase() +
                word.substr(1, word.length).toLowerCase());
        }
        return result.join(" ");
    }
    submitHandler() {
        const { usersToAction } = this.currentlyActiveForm;
        let postValues = {
            usersToAction,
        };
        // linkedlist like traversal from root to all linked forms
        const HIERARCHY_ORDER = [];
        let current = this.rootForm.formId;
        const view = new Set();
        const action = new Set();
        while (current) {
            if (!this.values[current]) {
                current = null;
            }
            else {
                for (const key of Object.keys(this.values[current])) {
                    if (key.toUpperCase().includes("HIERARCHY_")) {
                        HIERARCHY_ORDER.push(key.replace("HIERARCHY_", ""));
                    }
                }
                postValues = Object.assign({}, postValues, this.values[current]);
                let data = this.values[current];
                current = this.nextPrevFormRecords[current].next;
            }
        }
        HIERARCHY_ORDER.push(this.currentlyActiveForm["name"]);
        this.isLoadComplete = false;
        const ACTIVE_FORMID = this.currentlyActiveForm.formId;
        HIERARCHY_ORDER.shift();
        const _a = this.values[ACTIVE_FORMID], { dataFormData } = _a, rest = tslib_1.__rest(_a, ["dataFormData"]);
        dataFormData.append("formData", JSON.stringify(rest));
        dataFormData.append("formId", ACTIVE_FORMID);
        dataFormData.append("category", JSON.stringify(HIERARCHY_ORDER));
        const apiData = {
            formData: this.values[ACTIVE_FORMID],
            formId: ACTIVE_FORMID,
            category: HIERARCHY_ORDER,
        };
        this.ticketViewService.createTicket(dataFormData).subscribe(({ response, message, code }) => {
            if (code !== 200) {
                return alert(message);
            }
            const { actionMailedList, actionMessageList, ticketId } = response;
            this.messageService.add({
                severity: "success",
                summary: `Ticket Created`,
                detail: `ID: ${ticketId}`,
                life: 10000,
            });
            if (Array.isArray(actionMailedList) && actionMailedList.length) {
                this.messageService.add({
                    severity: "info",
                    summary: `Email sent to`,
                    data: actionMailedList,
                    life: 10000,
                });
            }
            if (Array.isArray(actionMessageList) && actionMessageList.length) {
                this.messageService.add({
                    severity: "info",
                    summary: `Message sent to`,
                    data: actionMessageList,
                    life: 10000,
                });
            }
            this.restoreState();
        }, (err) => {
            console.error(err);
            alert("Some error occured!");
        });
    }
    restoreState() {
        this.isLoadComplete = false;
        this.isGoingBackPossible = false;
        this.currentlyActiveForm = null;
        this.previouslyActiveForm = null;
        this.nextPrevFormRecords = {};
        this.activeIndex = 0;
        this.values = {};
        this.items = [];
        setTimeout(() => {
            this.previouslyActiveForm = null;
            this.currentlyActiveForm = this.rootForm;
            this.nextPrevFormRecorder();
            this.setStepperLength();
            this.isLoadComplete = true;
        }, 0);
    }
    getFormById(nextFormId = null, previousFormValues = null) {
        this.isLoadComplete = false;
        if (!nextFormId) {
            return;
        }
        this._incidentHeirarchySercive
            .getFormById(nextFormId)
            .subscribe((responseServer) => {
            this.isLoadComplete = true;
            const { errorCode, errorMessage, response } = responseServer;
            if (errorCode !== 200) {
                alert(errorMessage);
            }
            else {
                const currentForm = this.currentlyActiveForm;
                const { formId } = currentForm;
                this.currentlyActiveForm = null;
                setTimeout(() => {
                    {
                        // record current form values
                        this.values[formId] = previousFormValues;
                    }
                    {
                        // setting the prev, next...
                        this.previouslyActiveForm = currentForm;
                        this.currentlyActiveForm = response;
                        this.nextPrevFormRecorder();
                    }
                    this.setStepperLength();
                    this.activeIndex++;
                }, 0);
            }
        });
    }
    nextPrevFormRecorder() {
        if (!this.currentlyActiveForm) {
            return;
        }
        {
            this.nextPrevFormRecords[this.currentlyActiveForm.formId] = {
                formId: this.currentlyActiveForm.formId,
                form: this.currentlyActiveForm,
                prev: this.previouslyActiveForm
                    ? this.previouslyActiveForm.formId
                    : null,
                next: null,
            };
        }
        {
            if (this.previouslyActiveForm) {
                this.isGoingBackPossible = true;
                if (this.nextPrevFormRecords[this.previouslyActiveForm.formId]) {
                    this.nextPrevFormRecords[this.previouslyActiveForm.formId].next =
                        this.currentlyActiveForm.formId;
                }
                else {
                    this.nextPrevFormRecords[this.previouslyActiveForm.formId] = {
                        formId: this.previouslyActiveForm.formId,
                        form: this.previouslyActiveForm,
                        prev: null,
                        next: this.currentlyActiveForm.formId,
                    };
                }
            }
        }
    }
    setStepperLength() {
        const { formId: currentFormId, name } = this.currentlyActiveForm;
        const items = [];
        let now = this.rootForm.formId;
        while (now && now !== currentFormId) {
            items.push({
                label: this.nextPrevFormRecords[now].form.name,
            });
            now = this.nextPrevFormRecords[now].next;
        }
        items.push({
            label: name,
        });
        this.items = items;
    }
    activeIndexChangeHandler(indexToStop) {
        var formActiveRecord = null;
        {
            let now = this.rootForm.formId;
            let i = 0;
            while (i < indexToStop && now) {
                now = this.nextPrevFormRecords[now].next;
                i++;
            }
            formActiveRecord = this.nextPrevFormRecords[now];
        }
        if (!formActiveRecord) {
            return;
        }
        this.previouslyActiveForm = formActiveRecord.prev
            ? this.nextPrevFormRecords[formActiveRecord.prev].form
            : null;
        const { form, formId } = formActiveRecord;
        const value = this.values[formId];
        if (value) {
            const { elements } = form;
            form.elements = elements.map((element) => {
                if (value[element.name]) {
                    element.value = value[element.name];
                }
                return element;
            });
        }
        this.currentlyActiveForm = null;
        setTimeout(() => {
            this.currentlyActiveForm = form;
            this.activeIndex = indexToStop;
            this.nextPrevFormRecorder();
            this.setStepperLength();
        }, 0);
    }
    submitCurrentForm({ values, fields, formData }) {
        const { category = "endPointElement" } = this.currentlyActiveForm;
        const formId = fields.formId;
        {
            // record current form values
            this.values[formId] = Object.assign({}, values, { dataFormData: formData });
        }
        if (category !== "hierarchyElement") {
            return this.submitHandler();
        }
        else {
            // If there was a hiearchy field and its value was provided
            delete fields.formId;
            const Entries = Object.entries(fields);
            for (const [name, formField] of Entries) {
                const { type, options } = formField;
                if (type === "select") {
                    const NextFormId = values[name];
                    if (options) {
                        const option = options.filter((op) => op.value === NextFormId)[0];
                        this.values[formId][name] =
                            option && option.label ? option.label : option.value;
                    }
                    return this.getFormById(NextFormId, values);
                }
            }
        }
    }
    goBackOneTime() {
        // if going back is not possible then do nothing
        if (!this.isGoingBackPossible || !this.previouslyActiveForm) {
            return;
        }
        if (!confirm("Going a step back will result in loosing all data for current & previous form")) {
            return;
        }
        return this.activeIndexChangeHandler(this.activeIndex - 1);
    }
    onReject(key) {
        this.messageService.clear(key);
    }
    onHide() {
        this.cookieService.set("selectedProject", this.selectedProject.value.projectName, null, "/", Urls.COOKIE_URL, null, "Lax");
    }
    checkPermission(permissionString) {
        const hasPermission = PermissionForUsers.checkPermission(permissionString);
        const isVisibleInLocale = this.localeSerice.isComponentVisible(permissionString.toString());
        return hasPermission && isVisibleInLocale;
    }
    getUserRole() {
        this.userService.getAuthorizedZonesWithRole(this.ssoId).subscribe((res) => {
            localStorage.setItem("roleName", res["response"]["role"]);
        });
    }
}
