import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import * as _ from "lodash";
export class ModifyTicketModalComponent {
    constructor(incidentService, ticketViewService, msgService, hubListState, cookieService) {
        this.incidentService = incidentService;
        this.ticketViewService = ticketViewService;
        this.msgService = msgService;
        this.hubListState = hubListState;
        this.cookieService = cookieService;
        this.showModal = false;
        this.ticket = {};
        this.selectedSubIssues = [];
        this.onClose = new EventEmitter();
        this.onSubmit = new EventEmitter();
        // ngModel values
        this.issueSubIssue = [{ issueId: "", subIssues: [] }];
        this.createNewTicket = false;
        // options to select
        this.issueOptions = [];
        this.subIssueOptions = {};
        // todo: get options from shashank
        this.reassignmentOptions = [];
    }
    ngOnInit() {
        this.getIssueOptions();
        this.populateReassignmentReasons();
        this.populateIssueAndSubIssues();
    }
    populateReassignmentReasons() {
        this.hubListState.constants$.subscribe(constants => {
            this.reassignmentOptions = constants['reassignmentOptions'];
        });
    }
    populateIssueAndSubIssues() {
        if (!this.selectedSubIssues.length)
            return;
        const issueIds = [];
        this.issueSubIssue = this.selectedSubIssues.map((issue) => {
            issueIds.push(issue.issueId);
            return {
                issueId: issue.issueId,
                subIssues: (issue.subIssues || []).map((subIssue) => subIssue.subIssueId),
            };
        });
        this.getSubIssueByIssueId(issueIds.join(","));
    }
    onIssueSelect(event) {
        const { value } = event;
        this.getSubIssueByIssueId(value);
    }
    filterSelectedOptions(options, index) {
        const selectedValues = this.issueSubIssue
            .filter((v, i) => i !== index)
            .map((value) => value.issueId);
        return options.filter((option) => !selectedValues.includes(option.value));
    }
    getIssueOptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const { response, errorMessage = "", errorCode = "", } = yield this.incidentService.getIssues().toPromise();
                if (errorCode !== 200)
                    throw new Error(errorMessage);
                this.issueOptions = (response || []).map((issue) => ({
                    label: issue.issueName,
                    value: issue.issueId,
                }));
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: "error",
                    summary: "Error while fetching issues",
                    detail: "Please try again later",
                });
            }
        });
    }
    clearSubIssueForIssues(issueIds) {
        this.issueSubIssue = this.issueSubIssue.map((value) => {
            if (issueIds.includes(value.issueId)) {
                value.subIssues = [];
            }
            return value;
        });
    }
    populateSubIssueOptions(subIssues, issueId) {
        this.subIssueOptions[issueId] = (subIssues || []).map((subIssue) => ({
            label: subIssue.subIssueName,
            value: subIssue.subIssueId,
        }));
    }
    getSubIssueByIssueId(issueId, clearSubIssue = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiple = issueId.includes(",");
                const zoneId = this.cookieService.get("selectedZone");
                if (!zoneId)
                    throw new Error("Cannot select zone, please logout and try again");
                const { response, errorMessage = "", errorCode = "", } = yield this.incidentService
                    .getSubIssusesList(zoneId, issueId)
                    .toPromise();
                if (errorCode !== 200)
                    throw new Error(errorMessage);
                const issues = issueId.split(",");
                if (clearSubIssue) {
                    this.clearSubIssueForIssues(issues);
                }
                if (!multiple) {
                    this.populateSubIssueOptions(response, issueId);
                    return;
                }
                issues.forEach((id) => {
                    const subIssues = (response || []).filter((subIssue) => subIssue.issueId === id);
                    this.populateSubIssueOptions(subIssues, id);
                });
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: "error",
                    summary: "Error while fetching subissues",
                    detail: "Please try again later",
                });
            }
        });
    }
    closeModal() {
        this.showModal = false;
        this.onClose.emit(false);
    }
    addIssueOption() {
        this.issueSubIssue.push({ issueId: "", subIssues: [] });
    }
    removeIssueOption(index) {
        this.issueSubIssue = this.issueSubIssue.filter((v, i) => i !== index);
    }
    disableSubmit() {
        const anyIssueInvalid = this.issueSubIssue.some((value) => !value.issueId || !value.subIssues.length);
        const isDescriptionMandatory = this.reassignmentReason === 'Others';
        const anyFieldEmpty = this.createNewTicket
            ? !this.reassignmentReason || (isDescriptionMandatory && !this.description)
            : false;
        return anyIssueInvalid || anyFieldEmpty;
    }
    disableCreateTicket() {
        const noOfAncestors = _.get(this.ticket, 'centerLocation.reassignmentDetails.parentTicketId', []);
        return noOfAncestors.length >= 2;
    }
    getIssueMappingFromIds(ids) {
        const mapping = {};
        this.issueOptions.forEach((option) => {
            if (ids.includes(option.value)) {
                mapping[option.value] = option;
            }
        });
        return mapping;
    }
    getSubIssueMapping(subIssueIds, issueId) {
        const subIsusses = this.subIssueOptions[issueId];
        return subIsusses
            .filter((subIssue) => subIssueIds.includes(subIssue.value))
            .map((subIssue) => ({
            subIssueId: subIssue.value,
            subIssueName: subIssue.label,
        }));
    }
    updateIssues(payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { response, errorMessage = "", errorCode = "", } = yield this.ticketViewService.updateTicketV2(payload).toPromise();
            if (errorCode !== 200)
                throw new Error(errorMessage);
            this.msgService.add({
                severity: "success",
                summary: "Success",
                detail: "Issues Updated",
            });
            return response;
        });
    }
    submit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.issueSubIssue.some((value) => !value.issueId)) {
                    throw new Error("Please select valid issue/subissue");
                }
                const selectedIssueIds = this.issueSubIssue.map((field) => field.issueId);
                const issueMapping = this.getIssueMappingFromIds(selectedIssueIds);
                const issueSelection = [];
                this.issueSubIssue.forEach((field) => {
                    const obj = {
                        issueId: field.issueId,
                        issueName: issueMapping[field.issueId].label,
                        subIssues: this.getSubIssueMapping(field.subIssues, field.issueId),
                    };
                    issueSelection.push(obj);
                });
                const payload = this.ticketViewService.formatIssueUpdatePayload(this.ticket, issueSelection, this.createNewTicket, this.reassignmentReason, this.description);
                const updatedTicket = yield this.updateIssues(payload);
                this.onSubmit.emit(this.createNewTicket ? updatedTicket.ticketId : null);
            }
            catch (error) {
                console.error(error);
                this.msgService.add({
                    severity: "error",
                    summary: "Error while submitting",
                    detail: error || "Please try again later",
                });
            }
        });
    }
}
