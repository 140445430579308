import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Urls } from "../models/api.constants";
import { Headers } from "../models/api.headers";
import {
  CraneType,
  GetWorkshopAndCranesByZoneResponseType,
  Workshop,
} from "../utils/types/sendByCrane.types";
import { ApiResponse } from "../utils/types/global";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class SendByCraneService {
  private ApiV1Header: string = Urls.APP_URL + "/api/v1";

  constructor(private http: HttpClient, private cookieService: CookieService) {}


  updateStage(
    ticket: { [key: string]: any },
    additionalStageData: { [key: string]: any } = { stageActionData: [] },
  ) {
    const username = localStorage.getItem("username");
    const ssoId = this.cookieService.get("ssoId");

    ticket.updatedBy = Number(ssoId);
    ticket.updatedByName = username;
    ticket.updatedAt = new Date();

    ticket.stageData = ticket.stageData.map((stage, index) => {
      if (index < ticket.stageData.length - 1) return stage;
      stage = {
        ...stage,
        actionTakenBy: Number(ssoId),
        actionTakenByName: username,
        ...additionalStageData
      };
      return stage;
    });

    return ticket;
  }
  
  addStage(
    ticket: { [key: string]: any },
    stageName: string,
    additionalStageData: { [key: string]: any } = { stageActionData: [] },
  ) {
    const username = localStorage.getItem("username");
    const ssoId = this.cookieService.get("ssoId");

    ticket.actionType = 'STAGE_CHANGE';
    ticket.updatedBy = Number(ssoId);
    ticket.updatedByName = username;
    ticket.updatedAt = new Date();
    ticket.currentStage = stageName;

    const stage = {
      actionTakenBy: Number(ssoId),
      actionTakenByName: username,
      createdTime: new Date(),
      stageName: stageName,
      ...additionalStageData,
    };

    ticket.stageData.push(stage);

    return ticket;
  }

  popStage(ticket: { [key: string]: any }) {
    const username = localStorage.getItem("username");
    const ssoId = this.cookieService.get("ssoId");

    ticket.updatedBy = Number(ssoId);
    ticket.updatedByName = username;
    ticket.updatedAt = new Date();

    ticket.stageData.pop();
    const lastStage = ticket.stageData[ticket.stageData.length - 1];

    ticket.currentStage = lastStage.stageName;
    return ticket;
  }

  getWorkshopAndCranesByZone(zoneId: string | number) {
    return this.http.get<ApiResponse<GetWorkshopAndCranesByZoneResponseType>>(
      this.ApiV1Header + `/workshop-crane/${zoneId}`,
      { headers: Headers.headers }
    );
  }
}
