import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class HubListState {
    constructor() {
        this.hubListSubject = new BehaviorSubject(null);
        this.hubList$ = this.hubListSubject.asObservable();
    }
    set(hubList) {
        this.hubListSubject.next(hubList);
    }
}
HubListState.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HubListState_Factory() { return new HubListState(); }, token: HubListState, providedIn: "root" });
