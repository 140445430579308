import { FormConfigModule } from './components/form-config-update/form-config.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, Inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $ from 'jquery';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import {FullCalendarModule} from 'primeng/fullcalendar';
import { AccordionModule } from 'primeng/accordion';
import { DataViewModule } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { LightboxModule } from 'primeng/lightbox';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import {CarouselModule} from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';
import { InputSwitchModule } from 'primeng/inputswitch';

import { CookieService } from 'ngx-cookie-service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketsComponent } from './components/tickets/tickets.component';
import { CreateTicketComponent } from './components/create-ticket/create-ticket.component';
import { TicketConfigFormComponent } from './components/ticket-config-form/ticket-config-form.component';
import { TicketConfigComponent } from './components/ticket-config/ticket-config.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { StepsModule } from 'primeng/steps';
import { FieldsetModule } from 'primeng/fieldset';
import {BreadcrumbModule} from 'primeng/breadcrumb';

// Form-Module
import { FormModule } from './components/form/form.module';
import { IncidentHeirarchyComponent } from './components/incident-heirarchy/incident-heirarchy.component';
import { CreateDynamicFormComponent } from './components/create-dynamic-form/create-dynamic-form.component';
import { InputElementComponent } from './components/create-dynamic-form/dynamic-form-elements/input-element/input-element.component';
import { CheckboxElementComponent } from './components/create-dynamic-form/dynamic-form-elements/checkbox-element/checkbox-element.component';
import { RadioElementComponent } from './components/create-dynamic-form/dynamic-form-elements/radio-element/radio-element.component';
import { DropdownElementComponent } from './components/create-dynamic-form//dynamic-form-elements/dropdown-element/dropdown-element.component';
import { MultiselectElementComponent } from './components/create-dynamic-form/dynamic-form-elements/multiselect-element/multiselect-element.component';
import { FileElementComponent } from './components/create-dynamic-form/dynamic-form-elements/file-element/file-element.component';
import { HeirarchyDropdownElementComponent } from './components/create-dynamic-form/dynamic-form-elements/heirarchy-dropdown-element/heirarchy-dropdown-element.component';
import { DynamicFormElementsComponent } from './components/create-dynamic-form/dynamic-form-elements/dynamic-form-elements.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './app-interceptor';
import { TicketsViewComponent } from './components/tickets-view/tickets-view.component';
import { DriverIssuesViewsComponent } from './components/driver-issues-views/driver-issues-views.component';
import { DetailTicketViewComponent } from './components/tickets-view/detail-ticket-view/detail-ticket-view.component';
import { DateTimeComponent } from './components/create-dynamic-form/dynamic-form-elements/date-time/date-time.component';
import { CreateTicketGlobalComponent } from './components/create-ticket-global/create-ticket-global.component';
import { ServiceTicketComponent } from './components/service-ticket/service-ticket.component';
import { TicketCalendarComponent } from './components/service-ticket/ticket-calendar/ticket-calendar.component';
import { TicketSettingsComponent } from './components/service-ticket/ticket-settings/ticket-settings.component';
import { DaywiseTicketsComponent } from './components/service-ticket/daywise-tickets/daywise-tickets.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { MomentModule } from 'ngx-moment';
import { LocalService } from './services/local.service';
import { IssuesTatComponent } from './components/issues-tat/issues-tat.component';
import { HistoricalTicketsComponent } from './components/issues-tat/historical-tickets/historical-tickets.component';
import { CurrentTicketsComponent } from './components/issues-tat/current-tickets/current-tickets.component';
import { RiderSupportComponent } from './components/rider-support/rider-support.component';
import { RemarksComponent } from './components/tickets-view/remarks/remarks.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InsuranceModalComponent } from './components/insurance-modal/insurance-modal.component';
import { SharedModule } from './components/shared.module';
import { InsuranceDetailsComponent } from './components/tickets-view/insurance-details/insurance-details.component';
import { CloseModalComponent } from './components/tickets-view/close-modal/close-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    TicketsComponent,
    CreateTicketComponent,
    TicketConfigFormComponent,
    TicketConfigComponent,
    IncidentHeirarchyComponent,
    CreateDynamicFormComponent,
    InputElementComponent,
    CheckboxElementComponent,
    RadioElementComponent,
    DropdownElementComponent,
    MultiselectElementComponent,
    FileElementComponent,
    DynamicFormElementsComponent,
    HeirarchyDropdownElementComponent,
    TicketsViewComponent,
    DriverIssuesViewsComponent,
    HeirarchyDropdownElementComponent,
    DetailTicketViewComponent,
    DateTimeComponent,
    CreateTicketGlobalComponent,
    ServiceTicketComponent,
    TicketCalendarComponent,
    TicketSettingsComponent,
    DaywiseTicketsComponent,
    IssuesTatComponent,
    HistoricalTicketsComponent,
    CurrentTicketsComponent,
    RiderSupportComponent,
    RemarksComponent,
    FileUploadComponent,
    InsuranceModalComponent,
    InsuranceDetailsComponent,
    CloseModalComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    RadioButtonModule,
    DialogModule,
    AutoCompleteModule,
    TooltipModule,
    MultiSelectModule,
    FullCalendarModule,
    AccordionModule,
    Ng2SearchPipeModule,
    DataViewModule,
    PanelModule,
    ProgressBarModule,
    ConfirmDialogModule,
    ToastModule,
    LightboxModule,
    FormModule,
    FormConfigModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    CheckboxModule,
    StepsModule,
    CardModule,
    ToolbarModule,
    FieldsetModule,
    BreadcrumbModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    CarouselModule,
    MomentModule,
    PaginatorModule,
    InputSwitchModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true

    },
    ConfirmationService,
    MessageService,
    CookieService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [FileUploadComponent]
})
export class AppModule {

  private TimeZoneMapping = Object.freeze({
    AE: 'Asia/Dubai',
    IN: 'Asia/Kolkata',
  });

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private localService: LocalService
  ) {
    // const country = localStorage.getItem('country');
    // const timezone = localStorage.getItem('timezone');

    const urlLocale = localService.getLocale();
    const countryCode = (urlLocale || '').split('-').pop() || locale || 'IN';

    localStorage.setItem('country', countryCode);
    localStorage.setItem('timezone', this.TimeZoneMapping[countryCode]);
  }
}
