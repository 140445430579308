<div class="upload">
  <div class="file-upload">
    <div class="label">{{ label }}</div>
  
    <div class="file-upload-wrapper">
      <label for="fileInput" class="custom-file-upload">
        <i class="fa fa-download"></i>
        Add Item
      </label>
      <input
        type="file"
        id="fileInput"
        multiple="multiple"
        [ngStyle]="{ display: 'none' }"
        accept="image/*,application/pdf"
        (input)="handleFileInput($event)"
      />
    </div>
  </div>
  
  <div *ngFor="let file of files; let i = index" class="preview">
    <div class="name">
      <i class="fa fa-image"></i>
      {{ file.name }}
    </div>
    <button
      class="remove-file"
      (click)="removeFile(i)"
      >
      <i class="fa fa-trash-o"></i>
      Delete
    </button>
  </div>
  
</div>