<p-dialog
  [(visible)]="showModal"
  modal="true"
  dismissableMask="true"
  (onHide)="closeModal()"
  class="modify-ticket-modal"
>
  <h1>Modify ticket</h1>
  <div>
    <div
      *ngFor="let field of issueSubIssue; let i = index"
      class="form-row input-row"
    >
      <div class="col-sm-6">
        <div class="label">
          Issue
          <span class="text-danger">&nbsp;*</span>
          <i
            *ngIf="issueSubIssue.length > 1"
            (click)="removeIssueOption(i)"
            class="fa fa-trash-o text-danger"
            aria-hidden="true"
          ></i>
        </div>
        <p-dropdown
          [options]="filterSelectedOptions(issueOptions, i)"
          [(ngModel)]="issueSubIssue[i].issueId"
          (onChange)="onIssueSelect($event)"
          placeholder="Select issue"
        ></p-dropdown>
      </div>
      <div class="col-sm-6">
        <div class="label">
          Sub Issue
          <span class="text-danger">&nbsp;*</span>
        </div>
        <p-multiSelect
          [options]="subIssueOptions[issueSubIssue[i].issueId]"
          [(ngModel)]="issueSubIssue[i].subIssues"
          placeholder="Select issue for subissue"
        >
        </p-multiSelect>
      </div>
    </div>
    <hr />
    <button class="btn-outline-white add-issue-cta" (click)="addIssueOption()">
      <i class="fa fa-plus mr-2" aria-hidden="true"></i>
      Add Issue
    </button>
  </div>

  <div class="mt-3 d-flex" style="gap: 12px">
    <label for="createNewTicket" class="label">Create new ticket:</label>
    <p-inputSwitch
      id="createNewTicket"
      [disabled]="disableCreateTicket()"
      [(ngModel)]="createNewTicket"
    ></p-inputSwitch>
  </div>

  <div *ngIf="createNewTicket">
    <label for="reassignmentReason" class="label mt-2"
      >Reassignment reason<span class="text-danger">&nbsp;*</span></label
    >
    <br />
    <p-dropdown
      id="reassignmentReason"
      appendTo="body"
      [options]="reassignmentOptions"
      [(ngModel)]="reassignmentReason"
      placeholder="Select reason"
    ></p-dropdown>

    <label
      *ngIf="reassignmentReason === 'Others'"
      for="description"
      class="label mt-2"
      >Description<span class="text-danger">&nbsp;*</span></label
    >
    <br />
    <textarea
      *ngIf="reassignmentReason === 'Others'"
      rows="6"
      [(ngModel)]="description"
      placeholder="Add description"
    ></textarea>
  </div>

  <p-button
    label="Submit"
    type="submit"
    class="submit-cta"
    [disabled]="disableSubmit()"
    (click)="submit()"
  ></p-button>
</p-dialog>
