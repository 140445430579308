import { DbUsersService } from 'src/app/services/db-users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TicketViewService } from 'src/app/services/ticket-view.service';
import { SelectItem } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Urls } from '../../models/api.constants';
import { HubService } from 'src/app/services/hub.service';
import { DynamicFormService } from 'src/app/services/dynamic-form.service';
import * as XLSX from 'xlsx';
import { Response } from "src/app/models/api.dto";
import { LocalService } from 'src/app/services/local.service';
import { HubListState } from 'src/app/utils/state/hublistState.service';


@Component({
  selector: "app-driver-issues-views",
  templateUrl: "./driver-issues-views.component.html",
  styleUrls: ["./driver-issues-views.component.css"],
})
export class DriverIssuesViewsComponent implements OnInit, OnDestroy {
  skip = 0;
  limit = 100;
  sortingOrder = "asc";
  start_date: any;
  end_date: any;
  ticketStats = {
    openTicketsLifetime: 0,
    reopenTicketsLifetime: 0,
    resolvedTicketsToday: 0,
    openTicketsToday: 0,
  };
  formsList: any;
  pDropdownStyleForHub: any = {};
  pDropdownStyleForSearch: any = {};
  pDropdownStyleForIssues: any = {};
  pDropdownStyleForStage: any = {};
  pDropdownStyleForTeam: any = {};
  pDropdownStyleForTypes: any = {};
  isLoadComplete = false;
  allTicketsBasicData = [];
  ticketId;
  selectedTab;
  totalRecords = 0;
  ticketType: SelectItem[];
  selectedHub;
  searchPlaceholder = "Search...";
  selectedSearchKey = 'ticketId';
  currentStage = "";
  currentTeam;
  selectedIssue;
  selectedType;
  selectedActiveTicketState;
  projection = "min";
  currentUserSsoId = this.cookieService.get("ssoId");
  currentProject = this.cookieService.get("selectedProject");
  query = null;
  selectedTicketId = null;
  selectedVehicleNumber = null;
  isButtonDisabled: boolean = true;
  isFilterDisabled: boolean = true;
  isFilterApplied: boolean = false;
  showFilter: boolean = true;
  stageData;
  activeLoader : boolean = false

  // active state

  // Action
  selectedAssignedForAction: any[] = [];
  assignedForAction: SelectItem[] = [{ label: "Abcd", value: "119" }];
  // View
  selectedAssignedForView: any[] = [];
  assignedForView: SelectItem[] = [];
  usersList: SelectItem[];
  allUsers:any[] = [];
  isActive = null;
  issuesList;
  typesList;
  newIssueList = [

    { label: "ALL", value: 0 },

    {
      label: "Payment Related Issues",
      value: "Payment Related Issues",
    },
    {
      label: "Bonus Related Issues",
      value: "Bonus Related Issues",
    },
    {
      label: "Slot Related Issues",
      value: "Slot Related Issues",
    },
    {
      label: "Penalty Related Issues",
      value: "Penalty Related Issues",
    },
    {
      label: "Ride Related Issues",
      value: "Ride Related Issues",
    },
    {
      label: "Vehicle Related Issues",
      value: "Vehicle Related Issues",
    },
    {
      label: "I want Training",
      value: "I want Training",
    },
    {
      label: "Feedback to Blusmart",
      value: "Feedback to Blusmart",
    },

  ];

  stagesList = [
    { label: "All Issues", value: "" },
    { label: "Open", value: "Ticket Created" },
    { label: "In-Progress", value: "In-Progress" },
    { label: "Resolved", value: "Resolved"},
    { label: "Close", value: "Closed" },
    { label: "Reopen", value: "Reopen" },
    { label: "Discard", value: "Discard" },
  ];

  teamsList = [
    { label: "All", value: "" },
    { label: "Hub Team", value: "Hub" },
    { label: "Onboarding Team", value: "Onboarding" },
    { label: "Dispatch Team", value: "Dispatch" },
    { label: "Maintenance Team", value: "Maintenance" },
    { label: "Challan Team", value: "Challan" },
    { label: "HSSE Team", value: "HSSE" },
  ];

  searchKeysList = [
    { label: "Ticket Id", value: "ticketId" },
    { label: "Driver Id", value: "driverId" },
    { label: "Driver Name", value: "driverName" },
  ];

  categoryList = [ 
    {label:"Diamond", value:"Diamond"},
    {label:"Gold", value:"Gold"},
    {label:"Silver", value:"Silver"},
    {label:"Amber", value:"Amber"},
    {label:"Bronze", value:"Bronze"}, 
  ]

  issueTypesMap = {
    "Payment Related Issues": [
      { label: "Incentive Issue", value: "Incentive Issue" },
      { label: "Trip earning Issue", value: "Trip Earning Issue" },
      { label: "Overtime earning Issue", value: "Overtime earning Issue" },
      {label: "Cancellation Charges Issue", value: "Cancellation Charges Issue"},
      { label: "MBG Issue", value: "MBG Issue" },
      { label: "TDS Related Issues", value: "TDS Related Issues" },
      { label: "Cash Submission", value: "Cash Submission" }

    ],
    "Slot Related Issues": [
      { label: "Slot Change Request", value: "Slot Change Request" },
      { label: "Hub Change Request", value: "Hub Change Request" },
    ],
    "Bonus Related Issues": [
      { label: "Referral Bonus", value: "Referral Bonus Issue" },
      { label: "Retention Bonus", value: "Retention Bonus Issue" },
      { label: "Excellence Bonus", value: "Excellence Bonus Issue" },
      { label: "Joining Bonus", value: "Joining Bonus Issue" },
    ],
    "Penalty Related Issues": [
      { label: "Absenteeism Penalty", value: "Absenteeism Penalty" },
      { label: "Duty Denied Penalty", value: "Duty Denied Penalty" },
      { label: "Overspeeding Penalty", value: "Overspeeding Penalty" },
      { label: "Challan Penalty", value: "Challan Penalty" },
      { label: "Early Duty End Penalty", value: "Early Duty End Penalty" },
      { label: "Late Duty End Penalty", value: "Late Duty End Penalty" },
      { label: "Late Duty Start Penalty", value: "Late Duty Start Penalty" },
      { label: "Extra Lease Cost", value: "Extra Lease Cost" },
      { label: "Loss of MBG", value: "Loss of MBG" },
    ],
    "Vehicle Related Issues": [
      { label: "Servicing", value: "Servicing" },
      { label: "Battery related", value: "Battery related" },
      { label: "Accessories", value: "Accessories" },
      { label: "Fast Tag related", value: "Fast Tag related" },
      { label: "Maintenance related", value: "Maintenance related" },
      { label: "OCOD/ Premium vehicle request", value: "OCOD/ Premium vehicle request" },

    ],
    "Ride Related Issues": [
      { label: "Payment not made", value: "Payment not made" },
      { label: "Issue With Rider", value: "Issue With Rider" },
    ],

    "I want Training": [
      { label: "Payout Related Training", value: "Payout Related Training" },
      { label: "Insurance and mediclaim", value: "Insurance and mediclaim" },
      { label: "Slot/hub change and vehicle related", value: "Slot/hub change and vehicle related" }
    ],

    "Feedback to Blusmart": [
      { label: "Ride allocation related", value: "Ride allocation related" },
      { label: "Hub team related", value: "Hub team related" },
      { label: "Dispatch team related", value: "Dispatch team related" },
      { label: "Onboarding related", value: "Onboarding related" },
      { label: "Policy and Payout related", value: "Policy and Payout related" },
      { label: "App Related", value: "App Related" },
      { label: "Vehicle related", value: "Vehicle related" },
      { label: "HSE team related", value: "HSE team related" },
    ],
 
  };
  search = "";
  hubList = [];
  hubMap = {};
  sortingType: string;
  display: boolean = false;
  filteredData: any;
  resolutionNotes: any;
  downloadTicketData: boolean = false;

  timezone;

  selectedCategory:any;

  filterStateObj = {};

  searchWithFilters(e = { first: 0, rows: this.limit }) {
    this.skip = e.first;
    this.limit = e.rows;
    this.isLoadComplete = false;

    this.filterStateObj['search'] = this.search;
    this.setFilterObj(this.filterStateObj);
    const query = this.makequery();
    return this.fetchSearchQuery(query);
  }

  onSelectType(event: any) {
    this.selectedCategory= Object.values(event)
    this.searchWithFilters();
    this.filterStateObj['selectedCategory'] = this.selectedCategory;
    this.setFilterObj(this.filterStateObj);
  }

  fetchSearchQuery(query = { skip: 0, limit: this.limit }) {
    this.ticketViewService
      .loadTickets(query)
      .subscribe(
        ({ code, response = { count: 0, results: [] }, message }: any) => {
          let ticketData = response.results;
          this.filteredData = response.results;

          if (this.downloadTicketData && code === 200) {
            this.isFilterApplied = true;
            this.showFilter = false;
            this.downloadCsv();
          }

          for (let ticket of ticketData) {
            const { assignedForAction } = ticket;
            if (assignedForAction != undefined)
              ticket.assignedForActionAlias = assignedForAction.filter(
                ({ ssoId }) => ssoId != "119"
              );
          }
          this.allTicketsBasicData = ticketData;
          this.totalRecords = response.count;
          if (code !== 200) {
            return alert(message);
          }
          this.isLoadComplete = true;
        }
      );
  }
  constructor(
    private ticketViewService: TicketViewService,
    private cookieService: CookieService,
    private router: Router,
    private userService: DbUsersService,
    private hubService: HubService,
    private _formService: DynamicFormService,
    private localeService: LocalService,
    private hubListState: HubListState
  ) {
    this.isActive = true;
    this.timezone = localeService.getTimeZone();
  }

  populateFilters() {
    const filters = this.getFilterObj();
    this.filterStateObj = filters;
    if (!Object.keys(filters).length) return;
    if (filters.selectedCategory) {
      this.selectedCategory = filters.selectedCategory;
      this.isFilterApplied = true;
    }
    if (filters.selectedIssue) {
      this.selectedIssue = filters.selectedIssue;
      this.isFilterApplied = true;
    }
    if (filters.selectedType) {
      this.selectedType = filters.selectedType;
      this.isFilterApplied = true;
    }
    if (filters.currentStage) {
      this.currentStage = filters.currentStage;
      this.isFilterApplied = true;
    }
    if (filters.currentTeam) {
      this.currentTeam = filters.currentTeam;
      this.isFilterApplied = true;
    }
    if (filters.selectedHub) {
      this.selectedHub = filters.selectedHub;
      this.isFilterApplied = true;
    }
    if (filters.start_date) {
      this.start_date = new Date(filters.start_date);
      this.isFilterApplied = true;
    }
    if (filters.end_date) {
      this.end_date = new Date(filters.end_date);
      this.isFilterApplied = true;
    }
    if (filters.sortingOrder) {
      this.sortingOrder = filters.sortingOrder;
    }
    if (filters.sortingType) {
      this.sortingType = filters.sortingType;
    }
    if ('search' in filters) {
      this.search = filters.search;
    }
    if (filters.selectedSearchKey) {
      this.selectedSearchKey = filters.selectedSearchKey;
    }
  }

  setFilterObj(obj: Object) {
    localStorage.setItem('driverFilters', JSON.stringify(obj));
  }

  getFilterObj() {
    const filterString = localStorage.getItem('driverFilters') || '{}';
    return JSON.parse(filterString);
  }

  makequery() {
    const query: any = {};
    let filters = {};
    query.project = "driverPartnerTMS";
    if (typeof this.isActive === "boolean") {
      query.isActive = this.isActive;
    }

    query.sortingOrder = this.sortingOrder == "asc" ? -1 : 1;
    query.sortBy = this.sortingType;
    // if(query.sortingOrder == -1 && query.sortBy == "punch"){
    //   delete query.sortBy;
    // }
    if (this.selectedHub) {
      query.hub = this.selectedHub;
    }

    if (this.selectedCategory) {
      query.performanceType = this.selectedCategory.map(item => item.value);
    }

    if (this.selectedTicketId) {
      query.ticketIds = this.selectedTicketId.split(",");
    }
    if (this.currentStage) {
      filters["currentStage"] = this.currentStage;
    }
    if (this.selectedIssue) {
      filters["category"] = this.selectedIssue;
    }

    if (this.start_date) {
      query.startDate = this.start_date.getTime();
    }

    if (this.end_date) {
      query.endDate = this.end_date.getTime();
    }

    if (this.selectedVehicleNumber) {
      query.formData = {
        ...query.formData,
        "formData.Vehicle Number": this.selectedVehicleNumber,
      };
    }

    if (this.currentTeam) {
      query.formData = {
        ...query.formData,
        "formData.team": this.currentTeam,
      };
    }

    if (this.selectedType && this.selectedIssue) {
      query.formData = {
        ...query.formData,
        "formData.type": this.selectedType,
      };
    }

    if (
      this.selectedAssignedForAction &&
      this.selectedAssignedForAction.length > 0
    ) {
      query.assignedForAction = this.selectedAssignedForAction;
    }
    if (
      this.selectedAssignedForView &&
      this.selectedAssignedForView.length > 0
    ) {
      query.assignedForView = this.selectedAssignedForView;
    }
    let search = this.search && this.search.trim();
    if (search) {
      if (this.selectedSearchKey == "driverId") {
        query.formData = {
          "formData.driverId": search,
        };
      }
      if (this.selectedSearchKey == "driverName") {
        query.formData = {
          "formData.driverName": search,
        };
      }
      if (this.selectedSearchKey == "ticketId") {
        search = !search.includes("TMS") ? "TMS/DP/" + search : search;
        query.ticketIds = [search];
      }
    }

    // if (search) {
    //   query.filters = {
    //     search,
    //   };
    // }else{
    query.filters = filters;
    // }
    if (this.projection) {
      query.projection = this.projection || "min";
    }
    query.skip = this.skip;
    query.limit = this.limit;

    return query;
  }

  ticketQuery() {
    const query: any = {};
    let filters = {};
    query.project = "driverPartnerTMS";
    if (typeof this.isActive === "boolean") {
      query.isActive = this.isActive;
    }

    if (this.selectedHub) {
      query.hub = this.selectedHub;
    }
    if (this.selectedTicketId) {
      query.ticketIds = this.selectedTicketId.split(",");
    }
    if (this.currentStage) {
      filters["currentStage"] = this.currentStage;
    }
    if (this.selectedIssue) {
      filters["category"] = this.selectedIssue;
    }

    if (this.start_date) {
      query.startDate = this.start_date.getTime();
    }

    if (this.end_date) {
      query.endDate = this.end_date.getTime();
    }

    if (this.selectedVehicleNumber) {
      query.formData = {
        ...query.formData,
        "formData.Vehicle Number": this.selectedVehicleNumber,
      };
    }

    if (this.currentTeam) {
      query.formData = {
        ...query.formData,
        "formData.team": this.currentTeam,
      };
    }

    if (this.selectedType && this.selectedIssue) {
      query.formData = {
        ...query.formData,
        "formData.type": this.selectedType,
      };
    }
    query.filters = filters;
    // }
    if (this.projection) {
      query.projection = this.projection || "min";
    }
    return query;
  }



  ngOnInit() {
    this.populateFilters();
    this.getTicketStats();
    this.selectedTab = "Issues";
    // this.selectedSearchKey = "ticketId";
    this.setSearchKeys();
    this.getIssuesList();

    let currentURL = this.router.url;
    this.cookieService.set(
      "routeLocation",
      currentURL,
      null,
      "/",
      Urls.COOKIE_URL,
      null,
      "Lax"
    );

    this.hubListState.filterHublist$.subscribe(res => {
      const pathName = location.pathname;
      if (pathName.includes('/driver/issues')) {
        const query = this.makequery();
        return this.fetchSearchQuery(query);
      }
    });

    this.userService
      .getAllUsers()
      .subscribe(({ code, response = [], message }: any) => {
        
        this.allUsers = response
        if (code !== 200) {
          return alert(message);
        }
        this.usersList = response.map(({ name: label, ssoId: value }) => ({
          label,
          value,
        }));
       
        
      });
    this.hubService.getAllHubs().subscribe(({ response = [] }: any) => {
      this.hubList = [];
      this.hubMap = {};
      this.hubList.push({ label: "ALL", value: 0 });
      Object.keys(response).forEach((hub) => {
        if (hub != "0")
          this.hubList.push({ label: response[hub].name, value: hub });
        this.hubMap[hub] = response[hub].name;
      });
    });
    localStorage.setItem("calendarFor", "driverTMS");
  }

  ngOnDestroy() {
    this.cookieService.delete("routeLocation", "/", Urls.COOKIE_URL);
    localStorage.removeItem("calendarFor");
  }

  getIssuesList() {
    this._formService
      .fetchAllActiveFormsFromProjectAndCategory(
        "driverPartnerTMS",
        "endPointElement"
      )
      .subscribe(({ code, message, response }: any) => {
        if (code !== 200) {
          return alert(message);
        } else {
          this.issuesList = [];
          this.formsList = [];
          this.issuesList.push({ label: "ALL", value: 0 });

          Object.keys(response).forEach((issue) => {
            for (let i = 0; i < this.newIssueList.length; i++) {
              if (response[issue].name == this.newIssueList[i].value) {
                this.issuesList.push({
                  label: response[issue].name,
                  value: (Number(issue) + 1).toString(),
                });
                let form = {
                  name: response[issue].name,
                  formId: response[issue].formId,
                  types: response[issue].types,
                };
                this.formsList.push(form);
              }
            }
          });
        }
      });
  }

  getDetailViewForTicket(ticketId) {
    this.ticketId = ticketId;
    const param = String(this.ticketId).split('/').join('-');
    this.router.navigate([`/ticket/view/${param}`]);
  }

  isTabSelected(key) {
    return key == this.selectedTab;
  }

  changeTab(key) {
    this.selectedTab = key;
  }

  sortTickets(type) {
    this.sortingOrder = this.sortingOrder == "asc" ? "desc" : "asc";
    this.sortingType = type;

    this.filterStateObj['sortingOrder'] = this.sortingOrder;
    this.filterStateObj['sortingType'] = this.sortingType;
    this.setFilterObj(this.filterStateObj);
    this.searchWithFilters();
  }

  setSearchKeys() {
    if (!this.filterStateObj['selectedSearchKey'] || this.selectedSearchKey !== this.filterStateObj['selectedSearchKey']) {
      this.search = null;
    }
    this.filterStateObj['selectedSearchKey'] = this.selectedSearchKey;
    this.setFilterObj(this.filterStateObj);
    switch (this.selectedSearchKey) {
      case "ticketId":
        this.searchPlaceholder = "Search By Ticket Id";
        break;
      case "driverId":
        this.searchPlaceholder = "Search By Driver Id";
        break;
      case "driverName":
        this.searchPlaceholder = "Search By Driver Name";
        break;
    }
  }

  getTicketStats() {
    let query = {
      isActive: true,
      project: "driverPartnerTMS",
      projection: "min",
    };
    this.ticketViewService.getTicketStats(query).subscribe((res) => {
      this.ticketStats = res["response"]["statsData"];
    });
  }

  populateIssueTypes() {
    if (this.isFilterDisabled) {
      this.isFilterDisabled = false;
    }
    this.selectedType = null;
    this.typesList = this.issueTypesMap[this.selectedIssue];
  }

  // downloadCSV(){

  //   if(!this.start_date || !this.end_date){
  //     return alert('Please select both start and end date');
  //   }
  //   let start = this.start_date.getTime();
  //   let end = this.end_date.getTime();

  //   const diffTime = Math.abs(end - start);
  //   let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

  //   if (diffDays > 31){
  //     this.start_date = null;
  //     this.end_date = null;
  //     return alert('Can only view data for 31 days');
  //   }
  //   if(start > end) {
  //     this.start_date = null;
  //     this.end_date = null;
  //     return alert('End date should be greater than Start date');
  //   }

  //   this.start_date = new Date(this.start_date);
  //   this.end_date = new Date(this.end_date);

  //   let new_start_date = this.start_date.getTime()
  //   let new_end_date = this.end_date.getTime()
  //   this.isLoadComplete = false;

  //   this.ticketViewService.loadTicketsCSV(new_start_date, new_end_date,'driverPartnerTMS')
  //     .subscribe((res) => {
  //       saveAs(res, `tickets.csv`);
  //       this.isLoadComplete = true;
  //       this.display = false;
  //       this.start_date = null;
  //       this.end_date = null;
  //     });
  // }

  filterCsvData() {
    if (this.isButtonDisabled) {
      return;
    }
    if (!this.start_date || !this.end_date) {
      return alert("Please select both start and end date");
    }
    let start = this.start_date.getTime();
    let end = this.end_date.getTime();

    const diffTime = Math.abs(end - start);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

    if (diffDays > 31) {
      this.start_date = null;
      this.end_date = null;
      return alert("Can only view data for 31 days");
    }
    if (start > end) {
      this.start_date = null;
      this.end_date = null;
      return alert("End date should be greater than Start date");
    }
    // this.searchWithFilters()
    const query = this.ticketQuery()
    this.activeLoader = true

    this.ticketViewService.downloadTmsTickets(query).subscribe(
      ({ code, response = { results: [] }, message }: any) => {        
        this.filteredData = response.results;
        if (this.filteredData.length > 0) {
          this.activeLoader = false
        }
          this.downloadCsv();
        if (code !== 200) {
          return alert(message);
        }
        this.isLoadComplete = true;
      }
    );
  }

  openCSVDialog() {
    this.currentTeam = null;
    this.currentStage = null;
    this.selectedIssue = null;
    this.selectedType = null;
    this.selectedHub = null;
    this.start_date = null;
    this.end_date = null;
    this.isButtonDisabled = true;
    this.isFilterDisabled = true;
    this.display = true;
  }

  hideDialogBox() {
    this.start_date = null;
    this.end_date = null;
  }

  applyFilters() {
    if (this.isFilterDisabled) {
      return;
    }
    if (this.start_date && this.end_date) {
      let start = this.start_date.getTime();
      let end = this.end_date.getTime();
      if (start > end) {
        this.start_date = null;
        this.end_date = null;
        return alert("End date should be greater than Start date");
      }
    }

    this.filterStateObj['selectedIssue'] = this.selectedIssue;
    this.filterStateObj['selectedType'] = this.selectedType;
    this.filterStateObj['currentStage'] = this.currentStage;
    this.filterStateObj['currentTeam'] = this.currentTeam;
    this.filterStateObj['selectedHub'] = this.selectedHub;
    this.filterStateObj['selectedCategory'] = this.selectedCategory;
    this.filterStateObj['start_date'] = this.start_date;
    this.filterStateObj['end_date'] = this.end_date;
    this.setFilterObj(this.filterStateObj);

    this.searchWithFilters();
    this.isFilterApplied = true;
    this.showFilter = false;
    this.display = false;
  }

  onValueChange() {
    if (this.isFilterDisabled) {
      this.isFilterDisabled = false;
    }
  }

  dateSelectClickHandler() {
    if (this.start_date && this.end_date) {
      this.isButtonDisabled = false;
    }
  }

  clearFilter() {
    this.currentTeam = null;
    this.currentStage = null;
    this.selectedIssue = null;
    this.selectedType = null;
    this.selectedHub = null;
    this.downloadTicketData = false;
    this.isFilterApplied = false;
    this.showFilter = true;
    this.filterStateObj = {};
    this.setFilterObj(this.filterStateObj);
    window.location.reload();
  }

  downloadCsv() {
    let headings = [
      [
        "Ticket Id",
        "Current Status",
        "Driver Id",
        "Driver Name",
        "Category",
        "Sub Category",
        "New Category",
        "New Issue type",
        "Date of Incident",
        "Ride Id",
        "Vehicle Number",
        "Description",
        "Hub Id",
        "Created at",
        "Inprogress Date",
        "Resolved Date",
        "Closed Date",
        "Updated by",
        "Resolution Marks",
        "Reason Code",
        "Team",
        "Rating",
        "Driver feedback",
      ],
    ];
    if(this.selectedIssue === 'Penalty Related Issues'){
      headings[0] = headings[0].concat("Incident Slot", "Reversal Date","Reversed By");
     }
    const wb = XLSX.utils.book_new();
    const ws: any = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, headings, { origin: "A1" });
    const csvData = this.filteredData.map((item) => {
      this.stageData = item.stageData;
      let stageName;
      const stage = this.stageData.find(stage => stage.stageName === "CLOSED" || stage.stageName === 'Closed')
      if (stage) {  
        stageName = stage.stageName 
      }
    
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      let csvResults =  {
        "Ticket Id": item["ticketId"] || null,
        "Current Status": item["currentStage"] || null,
        "Driver Id": item.formData ? item.formData["driverId"] || null : null,
        "Driver Name": item.formData ? item.formData["driverName"] || null : null,
        "Category": item["category"] ? item["category"][0] || null : null,
        "Sub Category": item.formData ? item.formData["type"] || null : null,
        "New Category": item.formData && item.formData.fieldsData ? item.formData.fieldsData["newCategory"] || null : null,
        "New Issue type": item.formData && item.formData.fieldsData ? item.formData.fieldsData["newIssueType"] || null : null,
        "Date of Incident": item.formData && item.formData.fieldsData ? 
            (item.formData.fieldsData["incidentTimestamp"] ? 
                this.localeService.getDate(item.formData.fieldsData["incidentTimestamp"]).toLocaleString(undefined, options) :
                null) :  null,
        "Ride Id": item.formData && item.formData.fieldsData ? item.formData.fieldsData["rideId"] || null : null,
        "Vehicle Number": item.formData && item.formData.fieldsData ? item.formData.fieldsData["vehicleNumber"] || null : null,
        "Description": item.formData && item.formData.fieldsData ? item.formData.fieldsData["description"] || null : null,
        "Hub Id": item["hubId"] || null,
        "Created at": item["createdAt"] ? this.localeService.getDate(item["createdAt"]).toLocaleString(undefined, options) : null,
        "Inprogress Date": this.getTicketStage("In-Progress") || null,
        "Resolved Date": this.getTicketStage("Resolved") || null,
        "Closed Date": this.getTicketStage(stageName) || null,
        "Updated by": item["updatedBy"] ? this.getUserById(item["updatedBy"]) || null : null,
        "Resolution Marks": item["resolutionMarks"] || null,
        "Reason Code": item.formData ? item.formData["reasonCode"] || null : null,
        "Team": item.formData ? item.formData["team"] || null : null,
        "Rating": item.formData ? item.formData["rating"] || null : null,
        "Driver feedback": item.formData ? item.formData["comment"] || null : null,
      };
    if(item.leaseDailyStatsId !== null && this.selectedIssue === 'Penalty Related Issues'){
      let selectSlot = (item.formData  && item.formData.fieldsData) ? item.formData.fieldsData.selectSlot : null;
      csvResults["Incident Slot"] = selectSlot ? selectSlot.split("-")[1] : null;
      csvResults["Reversal Date"] = item["reversalDate"] ? this.localeService.getDate(item["reversalDate"]).toLocaleString(undefined, options) : null,
      csvResults["Reversed By"] = item["reversedBy"];
    }
     return csvResults;
    });

    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: true,
      origin: -1,
    });

    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, `Tickets.csv`);
    this.display = false;
    this.downloadTicketData = false
  }


  getUserById(ssoId: number): string | null {
    const user = this.allUsers.find(user => user.ssoId === ssoId);
    return user ? user.name : null;
  }

  getTicketStage(stageName): string | null {
    const stage = this.stageData .find(stage => stage.stageName  === stageName);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return stage ?  this.localeService.getDate(stage.createdTime).toLocaleString(undefined, options) : null
  }
}
