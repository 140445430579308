import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class HubListState {
    constructor() {
        this.hubListSubject = new BehaviorSubject(null);
        this.hubList$ = this.hubListSubject.asObservable();
        this.constants = new BehaviorSubject(null);
        this.constants$ = this.constants.asObservable();
        this.filterHublist = new Subject();
        this.filterHublist$ = this.filterHublist.asObservable();
    }
    setHubList(hubList) {
        this.hubListSubject.next(hubList);
    }
    setConstants(value) {
        this.constants.next(value);
    }
    setFilterHublist(value) {
        this.filterHublist.next(value);
    }
}
HubListState.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HubListState_Factory() { return new HubListState(); }, token: HubListState, providedIn: "root" });
