/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./file-upload.component";
import * as i4 from "primeng/components/common/messageservice";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "remove-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-trash-o"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "file-upload-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [["class", "custom-file-upload"], ["for", "fileInput"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Item "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "input", [["accept", "image/*,application/pdf"], ["id", "fileInput"], ["multiple", "multiple"], ["type", "file"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.handleFileInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(11, { display: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 11, 0, "none"); _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.files; _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); }); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileUploadComponent, [i4.MessageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-file-upload", i3.FileUploadComponent, View_FileUploadComponent_Host_0, { label: "label", maxLimit: "maxLimit" }, { uploadedFiles: "uploadedFiles" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
