<p-dialog [(visible)]="showModal" [modal]="true" class="remarksModal" [dismissableMask]="true" (onHide)="closeModal()">
  <form [formGroup]="remarkForm" class="remark-form">

    <img src="assets/blue-car.svg" class="car-img" />
    <h1>Add Remarks</h1>

    <label class="label" for="reasonDropdown">
      Reason
      <span>*</span>
    </label>
    <p-dropdown
      id="reasonDropdown"
      [filter]="true" 
      filterBy="label"
      formControlName="reason"
      required="true"
      class="reason-dropdown"
      [options]="reasonOptions"
      optionLabel="label"
      placeholder="Select Reason"
      [style]="{ minWidth: '100%' }"
    ></p-dropdown>

    <label class="label" for="remarks">
      Remarks
      <span>*</span>
    </label>
    <textarea
      id="remarks"
      rows="5"
      required
      cols="70"
      formControlName="remarks"
      placeholder="Enter remarks here"
      class="textarea"
    ></textarea>

    <app-file-upload [label]="'Files'" (uploadedFiles)="getUploadedFiles($event)" [maxLimit]="4"></app-file-upload> 

    <div class="button-tray">
      <button
        pButton
        pRipple
        label="Submit"
        style="width: 100%; display: flex; justify-content: center;"
        (click)="saveRemark()"
        [disabled]="remarkForm.status === 'INVALID' || updationInProgress"
      ></button>
    </div>
  </form>
</p-dialog>
