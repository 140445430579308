import { IncidentHeirarchyComponent } from './components/incident-heirarchy/incident-heirarchy.component';
import { TicketConfigFormComponent } from './components/ticket-config-form/ticket-config-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketsComponent } from './components/tickets/tickets.component';
import { CreateTicketComponent } from './components/create-ticket/create-ticket.component';
import { TicketConfigComponent } from './components/ticket-config/ticket-config.component';
import { DynamicFormComponent } from './components/form/dynamic-forms/container/dynamic-form.component';
import { CreateDynamicFormComponent } from './components/create-dynamic-form/create-dynamic-form.component';
import { TicketsViewComponent } from './components/tickets-view/tickets-view.component';
import { DriverIssuesViewsComponent } from './components/driver-issues-views/driver-issues-views.component';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { ServiceTicketComponent } from './components/service-ticket/service-ticket.component';
import { IssuesTatComponent } from './components/issues-tat/issues-tat.component';
import { RiderSupportComponent } from './components/rider-support/rider-support.component';
import { DetailTicketViewComponent } from './components/tickets-view/detail-ticket-view/detail-ticket-view.component';


const routes: Routes = [
  { path: '', redirectTo: 'ticket/view', pathMatch: 'full'},
  { path: 'tickets', component:  TicketsComponent,canActivate:[CanActivateRouteGuard]},
  { path: 'ticket/create', component:  CreateTicketComponent,canActivate:[CanActivateRouteGuard]},
  { path: 'dynamic/form/create', component:  CreateDynamicFormComponent,canActivate:[CanActivateRouteGuard]},
  // { path: 'ticket/config-create', component:  TicketConfigComponent},
  // { path: 'ticket/config-active', component: TicketConfigFormComponent },
  { path: 'ticket/heirarchy', component: IncidentHeirarchyComponent,canActivate:[CanActivateRouteGuard] },
  { path: 'ticket/view', component: TicketsViewComponent,canActivate:[CanActivateRouteGuard] },
  { path: 'ticket/view/:id', component: DetailTicketViewComponent, canActivate: [CanActivateRouteGuard] },
  { path: 'issues/tat', component: IssuesTatComponent,canActivate:[CanActivateRouteGuard] },
  { path: 'rider', component: RiderSupportComponent,canActivate:[CanActivateRouteGuard] },
  { path: 'driver/issues/view', component: DriverIssuesViewsComponent,canActivate:[CanActivateRouteGuard] },
  {   path: 'ticket/serviceticket',
  component: ServiceTicketComponent,
  children: [
    { path: '', redirectTo: 'CALENDAR', pathMatch: 'full' },
    { path: 'CALENDAR', component: ServiceTicketComponent },
    { path: 'TICKET_SETTINGS', component: ServiceTicketComponent}
  ],
   canActivate:[]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
