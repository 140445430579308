import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Input()
  label: string = 'Upload';

  @Output()
  uploadedFiles = new EventEmitter();

  @Input()
  maxLimit: Number = -1;

  files: File[] = [];

  constructor(public msg: MessageService) { }

  ngOnInit() {
  }

  resetFiles() {
    this.files = [];
  }

  handleFileInput(event) {
    if (!event || !event.target || !event.target.files) return;

    const files = [...event.target.files];
    const maxSizeInBytes = 3 * 1024 * 1024;

    if (this.maxLimit !== -1 && (this.files.length + files.length)  > 4) {
      this.msg.add({
        severity: 'error',
        summary: 'error',
        detail: 'Cannot upload more than 4 files.',
      });
      return;
    }

    if (files.some(file => file.size >= maxSizeInBytes)) {
      this.msg.add({
        severity: "error",
        summary: "Error",
        detail: `Cannot upload files larger than 3 MB.`,
      });

      return;
    }

    this.files.push(...files);
    this.uploadedFiles.emit(this.files);
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.uploadedFiles.emit(this.files);
  }

}
