import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { FileUploadComponent } from "../../file-upload/file-upload.component";
export class RemarksComponent {
    constructor(fb, ticketViewService, localeService, msg) {
        this.fb = fb;
        this.ticketViewService = ticketViewService;
        this.localeService = localeService;
        this.msg = msg;
        this.showModal = false;
        this.ticket = null;
        this.getTicketDetails = () => { };
        this.onClose = new EventEmitter();
        this.reasonOptions = [];
        this.uploadedFiles = [];
        this.updationInProgress = false;
        this.remarkForm = this.fb.group({
            reason: ["", Validators.required],
            remarks: ["", Validators.required],
            file: [[]],
        });
        this.populateRemarks();
    }
    populateRemarks() {
        const roleName = localStorage.getItem("roleName");
        this.ticketViewService.getRemarks({ roleName }).subscribe(({ response, code, errorMessage }) => {
            if (code !== 200) {
                console.error(errorMessage);
                this.msg.add({
                    severity: "error",
                    summary: "Error",
                    detail: errorMessage,
                });
                return;
            }
            this.reasonOptions = response.filter((reason) => reason.role.includes(roleName));
        }, (err) => {
            console.error(err);
            this.msg.add({
                severity: "error",
                summary: "Error",
                detail: 'Please try again after sometime.',
            });
        });
    }
    ngOnInit() { }
    closeModal(event = "CLOSE") {
        this.showModal = false;
        this.remarkForm.reset();
        this.uploadedFiles = [];
        this.fileUploadComp.resetFiles();
        this.onClose.emit({ closekey: this.showModal, event });
    }
    populateTicket() {
        const ssoId = localStorage.getItem("ssoId");
        const roleName = localStorage.getItem("roleName");
        const { value: reason } = this.remarkForm.get("reason").value;
        const remarks = this.remarkForm.get("remarks").value;
        const files = this.remarkForm.get("file").value;
        const remarkStage = {
            stageName: "Remark",
            stageActionData: [
                {
                    reason,
                    remark: remarks || "",
                    actionStage: this.ticket.currentStage,
                    platform: "Incident",
                    role: roleName,
                },
            ],
            createdTime: this.localeService.getDate(new Date()).getTime(),
            actionTakenBy: Number(ssoId),
            files,
        };
        const payload = Object.assign({}, this.ticket);
        payload.stageData.push(remarkStage);
        payload.currentStage = 'Remark';
        return payload;
    }
    saveRemark() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.updationInProgress = true;
                if (this.uploadedFiles.length) {
                    const { data, errorMessage, code } = yield this.ticketViewService
                        .uploadRemarkDocuments(this.uploadedFiles)
                        .toPromise();
                    if (code !== 200)
                        throw new Error(errorMessage);
                    this.remarkForm.patchValue({ file: data });
                }
                const payload = this.populateTicket();
                this.ticketViewService.updateTicket(payload).subscribe(({ code, errorMessage }) => {
                    this.getTicketDetails();
                    this.updationInProgress = false;
                    if (code !== 200) {
                        console.error(errorMessage);
                        this.msg.add({
                            severity: "error",
                            summary: "Error",
                            detail: errorMessage,
                        });
                        return;
                    }
                    ;
                    this.closeModal("SUCCESS");
                }, (error) => {
                    console.error(error);
                    this.updationInProgress = false;
                });
            }
            catch (error) {
                this.updationInProgress = false;
                console.error(error);
                this.msg.add({
                    severity: "error",
                    summary: "Error",
                    detail: 'Please try again after sometime.',
                });
            }
        });
    }
    getUploadedFiles(files) {
        if (!files.length)
            return;
        this.uploadedFiles = files;
    }
}
