import { HttpParams } from "@angular/common/http";
import { Headers } from "../models/api.headers";
import { Urls } from "../models/api.constants";
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
import * as i3 from "./local.service";
export class IncidentHeirarchyService {
    constructor(_http, cookieService, localeService) {
        this._http = _http;
        this.cookieService = cookieService;
        this.localeService = localeService;
    }
    getHeirarchyRootForm(project, name) {
        return this._http.post(Urls.APP_URL + "/api/v1/form/get-active-form", { name, project }, { headers: Headers.headers });
    }
    getFormById(formId) {
        const url = "/api/v1/form/get-active-form-by-id?formId=";
        return this._http.get(Urls.APP_URL + url + formId, {
            headers: Headers.headers,
        });
    }
    submitTheForm(formData) {
        return this._http.post(Urls.APP_URL + "/api/v1/form/get-active-form", { name, project: "tms" }, { headers: Headers.headers });
        // return this._http.get(Urls.APP_URL + `/api/v1/heirarchy/fetch-form?formId=${formData}`);
    }
    getPaginatedDrivers(searchKey) {
        return this._http.get(Urls.HUB_URL +
            "/api/v1/drivers/from-all-hubs?page=0&size=100&searchTerm=" +
            searchKey, { headers: Headers.headers });
    }
    getIssues() {
        return this._http.get(Urls.HUB_URL + "/api/v1/issues", { headers: Headers.headers });
    }
    getSubIssusesList(zoneId, issueId) {
        const params = new HttpParams()
            .set("zoneId", zoneId.toString())
            .set("issueId", issueId.toString());
        return this._http.get(Urls.APP_URL + "/api/v1/sub-issue-by-id", {
            headers: Headers.headers,
            params,
        });
    }
    manipulateTicketPayload(payload) {
        const formData = JSON.parse(payload.get('formData') || '{}');
        const accidentalDetails = {
            place: payload.get('place'),
            incidentStory: payload.get('incidentStory'),
            hasInjuries: _.get(formData, 'hasInjuries'),
            injuryTo: _.get(formData, 'injuryTo'),
            files: formData['Incident Photo/Video'],
            maintenanceDescription: payload.get('maintenanceDescription'),
        };
        const vehicleDetails = {
            vehicleRc: formData.vehicleRC,
            vehicleNumber: payload.get('Vehicle Number'),
            isInsuranceApplicable: payload.get('isInsuranceApplicable'),
            insurance: formData.insuranceDocument,
            fitness: formData.fitness,
            fitnessCertificate: formData.fitnessDocument,
        };
        const driverDetails = {};
        if (formData['Driver']) {
            driverDetails['name'] = formData['Driver'].split(',')[0];
            driverDetails['punchId'] = formData['Driver'].split(',')[1];
            driverDetails['driverLicense'] = formData.driverLicense;
        }
        const legalDetails = {
            firDoc: formData.firDocument,
            driverMedicalProof: _.get(formData, 'medicalProofs.driver.document', ''),
            riderMedicalProof: _.get(formData, 'medicalProofs.rider.document', ''),
            thirdPartyMedicalProof: _.get(formData, 'medicalProofs["third party"].document', ''),
        };
        if (this.localeService.isLocaleUAE()) {
            legalDetails['otherDocument'] = formData.otherDocument;
            legalDetails['liabilityFlagging'] = formData.liabilityFlagging;
        }
        delete formData.hasInjuries;
        delete formData.injuryTo,
            delete formData['Incident Photo/Video'];
        delete formData['fitness'];
        delete formData.fitnessDocument;
        delete formData.firDocument;
        delete formData.medicalProofs;
        delete formData.driverLicense;
        delete formData.insuranceDocument;
        delete formData.vehicleRC;
        delete formData.otherDocument;
        delete formData.liabilityFlagging;
        payload.delete('place');
        payload.delete('incidentStory');
        payload.delete('formData');
        payload.delete('isInsuranceApplicable');
        payload.delete('maintenanceDescription');
        formData.accidentalDetails = accidentalDetails;
        formData.vehicleDetails = vehicleDetails;
        formData.driverDetails = driverDetails;
        formData.legalDetails = legalDetails;
        payload.append('formData', JSON.stringify(formData));
        return payload;
    }
}
IncidentHeirarchyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IncidentHeirarchyService_Factory() { return new IncidentHeirarchyService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService), i0.ɵɵinject(i3.LocalService)); }, token: IncidentHeirarchyService, providedIn: "root" });
