import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Paginator } from 'primeng/paginator';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocalService } from 'src/app/services/local.service';
import { RiderSupportService } from 'src/app/services/rider-support.service';

@Component({
  selector: 'app-rider-support',
  templateUrl: './rider-support.component.html',
  styleUrls: ['./rider-support.component.css']
})
export class RiderSupportComponent implements OnInit {
  riderSupportData = [];
  showCsvText: boolean = true;
  showCsvLoader: boolean = false;
  showCsvSuccess: boolean = false;
  showCsvFailed: boolean = false;
  search = '';
  searchDropdown = [
    { name: 'Rider Id', value: 'riderId' },
    { name: 'Ride Id', value: 'rideId' },
    { name: 'Ticket ID', value: 'ticketId' },
  ]
  ticketTypeDropdown =
    [
      { name: 'Open', value: 'open' },
      { name: 'Close', value: 'close' },
    ]
  ticketType = { name: 'Open', value: 'open' };
  searchPlaceHolder = "Search by Rider Id";
  date;
  today: Date;
  // minDate = moment().subtract(30, 'days').toDate()
  refundTypeList = [
    { name: 'Ticket Created', value: 'Ticket Created' },
    { name: 'Assign To me', value: 'Assign To Me' },
  ]
  refundStatusList = [
    { name: 'Accepted', value: 'Accepted' },
    { name: 'Rejected', value: 'Rejected' }
  ]
  selectedRefund = [];
  selectedStatus = [];
  showRefundStatus: boolean = true;
  selectedSearchType = { name: 'Rider Id', value: 'riderId' };
  limit = 10;
  totalRecords;
  page = 1;
  first;
  ticketId;
  queryCount;
  resetActive: boolean = false;
  @ViewChild('paginator', { static: false }) paginator: Paginator;
  searchString = new Subject<string>()
  loading: boolean = false;

  timezone;

  filterStateObj = {};

  constructor(
    private router: Router,
    private riderService: RiderSupportService,
    private localeService: LocalService,
  ) { 
    this.loading = true; 
    this.timezone = localeService.getTimeZone();
    this.today = this.localeService.getDate();
  }
  ngOnInit() {
    this.populateFilters();
    this.fetchRiderTickets();

    this.searchString.pipe(debounceTime(1000)).subscribe((searchValue) => {
      this.changePage();
    })

  }

  populateFilters() {
    const filters = this.getFilterObj();
    this.filterStateObj = filters;
    if (!Object.keys(filters).length) return;
    if (filters.ticketType) {
      this.resetActive = true;
      this.ticketType = filters.ticketType;
    }
    if (filters.date && filters.date.length) {
      this.resetActive = true;
      this.date = filters.date.map(d => new Date(d));
    }
  }

  setFilterObj(obj: Object) {
    localStorage.setItem('riderFilters', JSON.stringify(obj));
  }

  getFilterObj() {
    const filterString = localStorage.getItem('riderFilters') || '{}';
    return JSON.parse(filterString);
  }

  resetData() {
    this.date = undefined;
    this.selectedSearchType = { name: 'Rider Id', value: 'riderId' };
    this.showRefundStatus = true;
    this.selectedRefund = [];
    this.selectedStatus = [];
    this.page = 1;
    this.search = '';
    this.ticketType = { name: 'Open', value: 'open' };
    this.resetActive = false;
    if (this.paginator) {
      this.paginator.changePage(0);
    }
    this.fetchRiderTickets();
    this.filterStateObj = {};
    this.setFilterObj(this.filterStateObj);
  }
  fetchRiderTickets() {
    this.loading = true;
    const body = {
      "filters": {
        "startDate": this.date ? this.date[0] ? (this.date[0]).getTime() : '' : '',
        "endDate": this.date ? this.date[1] ? (this.date[1]).getTime() : '' : '',
        "isActive": this.showRefundStatus,
        "ticketId": this.selectedSearchType.value == 'ticketId' ? this.search.trim() : '',
        "riderId": this.selectedSearchType.value == 'riderId' ? this.search.trim() : '',
        "rideId": this.selectedSearchType.value == 'rideId' ? this.search.trim() : '',
        "ticketStatus": this.selectedRefund.length ? this.selectedRefund.map(status => status.value) : '',
        "refundStatus": this.selectedStatus.length ? this.selectedStatus.map(status => status.value) : '',
      },
      "project": "riderSupportTMS",
      "page": this.page,
      "limit": 10
    };
    this.riderService.getAllTickets(body).toPromise()
      .then((res: any) => {
        this.riderSupportData = res.response ? res.response.results : [];
        this.totalRecords = res.response ? res.response.count : 0;
        this.queryCount = res.response ? res.response.queryCount : 0;
        this.loading = false;
      })
      .catch(error => { console.log(error); this.loading = false; });
  }

  sendCSV() {
    this.showCsvLoader = true;
    this.showCsvText = false;
    this.showCsvFailed = false;
    this.showCsvSuccess = false;
    const body = {
      "filters": {
        "startDate": this.date ? this.date[0] ? (this.date[0]).getTime() : '' : '',
        "endDate": this.date ? this.date[1] ? (this.date[1]).getTime() : '' : '',
        "isActive": this.showRefundStatus,
        "ticketId": this.selectedSearchType.value == 'ticketId' ? this.search.trim() : '',
        "riderId": this.selectedSearchType.value == 'riderId' ? this.search.trim() : '',
        "rideId": this.selectedSearchType.value == 'rideId' ? this.search.trim() : '',
      },
      "project": "riderSupportTMS",
    };
    this.riderService.sendCSV(body).toPromise()
      .then((res: any) => {
        this.showCsvLoader = false;
        this.showCsvText = false;
        this.showCsvSuccess = true;
      })
      .catch(error => {
        this.showCsvLoader = false;
        this.showCsvText = false;
        this.showCsvFailed = true;
      });
    setTimeout(() => {
      this.showCsvLoader = false;
      this.showCsvText = true;
      this.showCsvSuccess = false;
      this.showCsvFailed = false;
    }, 5000);
  }

  onSelectSearchType(event) {
    this.selectedSearchType = event;
    const placeholders = {
      riderId: "Search by Rider Id",
      rideId: "Search by Ride Id",
      ticketId: "Search by Ticket Id"
    };
    this.searchPlaceHolder = placeholders[event.value] || "";
    this.changePage();
  }

  onSelectTicketType(event) {
    this.showRefundStatus = event.value === 'open';
    this.filterStateObj['ticketType'] = this.ticketType;
    this.setFilterObj(this.filterStateObj);
    this.changePage();
  }
  dateSelectClickHandler() {
    if (this.date[0] && this.date[1]) {
      this.resetActive = true;
      const date1 = this.localeService.getDate(this.date[0]);
      const date2 = this.localeService.getDate(this.date[1]);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDays > 30) {
        this.date = [moment().subtract(30, 'days').toDate(), moment().toDate()];
        alert('Date range should not be more than 30 days');
        return;
      }

      this.filterStateObj['date'] = this.date;
      this.setFilterObj(this.filterStateObj);
      this.changePage();
    }
  }

  onPageChange(event) {
    this.page = event.page + 1;
    this.fetchRiderTickets();
  }
  changePage() {
    this.resetActive = true;
    this.page = 1;
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }
  calculateRefundAmount(d) {
    if (!d.stageData) return ('-');
    const isApproved = (d.stageData).find(stage => stage.stageName === 'Approve Request');
    const amount = isApproved ? isApproved.stageActionData.find(el => el.Refund).Refund : '';
    return amount ? amount : '-';
  }
  getStageName(stages) {
    if (stages && stages.length > 0) {
      return stages[stages.length - 1].stageName;
    }
    return "-";
  }

  searchInput() {
    this.searchString.next(this.search)
  }
  gototicketDetails(ticketId) {
    this.ticketId = ticketId;
    const param = String(this.ticketId).split('/').join('-');
    this.router.navigate([`/ticket/view/${param}`]);
  }
}
