<div *ngIf="isInsuranceApplicable && isInsuranceApplicable !== 'No'">
  <div *ngIf="showVehicleDetails() && !isUAE" class="d-flex mb-3 vehicle-details">
    <h2>Vehicle Documents:</h2>
    <a *ngIf="getVehicleRC()" [href]="getVehicleRC()">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Vehicle RC
    </a>
    <a *ngIf="vehicleHasInsurance()" [href]="getKey('insuranceDetails.vehicleDetails.insurance')">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Insurance
    </a>
    <a *ngIf="vehicleHasFitness()" [href]="getKey('insuranceDetails.vehicleDetails.fitnessCertificate')">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Fitness Certificate
    </a>
  </div>

  <div *ngIf="showDriverDocuments() && !isUAE" class="d-flex mb-3">
    <h2>Driver Documents:</h2>
    <a *ngIf="driverHasDL()" [href]="driverHasDL()">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Driver DL
    </a>
  </div>

  <div *ngIf="showAccidentDetails()" class="d-flex mb-3">
    <h2>Accident Details:</h2>
    <div>
      <div>
        <b>Accidental Story:&nbsp;</b>
        <span style="word-break: break-all;">{{ getKey('insuranceDetails.accidentalDetails.incidentStory') }}</span>
      </div>
      <div class="mt-2">
        <b>Date & Time:&nbsp;</b>
        <span class="pr-3 border-right">{{ getKey('formData["Date Of Issue"]') | date:'MMM d, yyyy, h:mm:ss a' }}</span>
        <b class="pl-3">Place:&nbsp;</b>
        <span class="pr-3 border-right">{{ getKey('insuranceDetails.accidentalDetails.place') }}</span>
        <b *ngIf="getLiabilityFlagging() && isUAE" class="pl-3">Liability:&nbsp;</b>
        <span>
          <span [ngClass]="getLiabilityFlagging() === 'red' ? 'text-danger text-capitalize' : 'text-success text-capitalize'">{{ getLiabilityFlagging() }}</span>&nbsp;
          <span *ngIf="getLiabilityFlagging() === 'red'" class="text-muted">(Blusmart is liable)</span>
          <span *ngIf="getLiabilityFlagging() === 'green'" class="text-muted">(Third party is liable)</span>
        </span>
      </div>
      <div class="mt-2">
        <b>Incident Photo / Video:&nbsp;</b>
        <button type="button" class="btn-link" *ngIf="getAccidentFiles().length" (click)="toggleViewModal(true)">
          <i class="fa fa-picture-o mr-2" aria-hidden="true"></i>
          View Photo/Video
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="showLegalDocuments()" class="d-flex legal-documents">
    <h2>Legal Documents:</h2>
    <a *ngIf="getFir()" [href]="getFir()">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Vehicle FIR
    </a>
    <a *ngIf="getOtherFirDocument() && isUAE" [href]="getOtherFirDocument()">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Other FIR Document
    </a>
    <a *ngIf="getMedicalProof('driverMedicalProof')" [href]="getMedicalProof('driverMedicalProof')">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Driver Medical Proof
    </a>
    <a *ngIf="getMedicalProof('riderMedicalProof')" [href]="getMedicalProof('riderMedicalProof')">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Rider Medical Proof
    </a>
    <a *ngIf="getMedicalProof('thirdPartyMedicalProof')" [href]="getMedicalProof('thirdPartyMedicalProof')">
      <i class="fa fa-file-o mr-2" aria-hidden="true"></i>
      Third Party Medical Proof
    </a>
  </div>

  <div *ngIf="doesRoleHasAccess && checkForMSE() && !isInsuranceAcceptedOrRejected()" class="cta-tray mt-4">
    <button class="btn-outline-white" (click)="toggleInsuranceModal(true)">
      <i class="fa fa-pencil" aria-hidden="true"></i>
      Edit Details
    </button>

    <button class="btn-outline-white" (click)="sendInsuranceDocument()">
      <i class="fa fa-paper-plane" aria-hidden="true"></i>
      Send Doc to Mail
    </button>
  </div>
</div>

<div *ngIf="isInsuranceApplicable === 'No' && !isInsuranceAcceptedOrRejected()">
  <button class="btn-outline-white" (click)="toggleInsuranceModal(true)" [disabled]="isAddInsuranceCtaDisabled()">
    <i class="fa fa-plus" aria-hidden="true"></i>
    Add Insurance Details
  </button>
</div>

<app-upload-view-modal [visible]="openViewModal" [viewOnly]="true" [uploadContainers]="getAccidentFiles()"
  (handleOnHide)="toggleViewModal(false)"></app-upload-view-modal>

<app-insurance-modal *ngIf="openEditModal" [visible]="openEditModal" (handleOnHide)="hideInsuranceModal()"
  (handleSubmit)="handleInsuranceSubmit($event)"></app-insurance-modal>