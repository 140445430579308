import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HubListState {

  private hubListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  hubList$: Observable<any> = this.hubListSubject.asObservable();

  constructor() { }

  set(hubList: any) {
    this.hubListSubject.next(hubList);
  }
}
